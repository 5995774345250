import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Authorized } from 'processes/Authorized';
import { ModalType, selectUser, useAppDispatch } from 'processes/store';
import { Button, Input, MainTemplate, SidebarSettings } from 'shared/ui';
import { CircleArrowIcon, CoverDefault, UserIcon } from 'shared/lib/Icons';
import { useModal } from 'shared/hooks';
import { countriesData, updateUserAction } from './model';
import {
  Containter,
  Content,
  ContentWrapper,
  EditBlock,
  EditEmail,
  EditItem,
  EditItems,
  EditLogo,
  EditSection,
  Header,
  Label,
  LoadLogo,
  LoadLogoLabel,
  LogoLabel,
  LogoPreview,
  SelectCustom,
  Title,
  WorkPlaceEdit,
  WorkPlaceFields,
  WorkPlaceTop,
} from './styled';

const utc = [
  {
    value: 'UTC-3',
    label: 'UTC-3',
  },
];

export const PersonalDataPage: React.FC = () => {
  const [file, setFile] = useState<Blob>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [src, setSrc] = useState('');
  const [email, setEmail] = useState('');

  const { user } = useSelector(selectUser);

  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (user.fullName) {
      const [firstName, lastName] = user.fullName?.split(' ');
      setFirstName(firstName);
      setLastName(lastName);
    }
    if (user.email) {
      setEmail(user.email);
    }
    if (file) {
      setSrc(URL.createObjectURL(file));
    }
    if (src) {
      showModal(ModalType.AvatarModal, { defaultAvatar: src, file, id: user.id });
    }
  }, [file, showModal, src, user.email, user.fullName, user.id]);

  const onBlur = useCallback(() => {
    const fullName = `${firstName} ${lastName}`;
    dispatch(
      updateUserAction({
        user: {
          fullName,
        },
      }),
    );
  }, [dispatch, firstName, lastName]);

  const handleClick = () => {
    if (user.imageSrc) {
      showModal(ModalType.AvatarModal, { defaultAvatar: user.imageSrc });
    } else {
      // @ts-ignore
      hiddenFileInput.current.click();
    }
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>, type: 'first' | 'last') => {
    type === 'first' ? setFirstName(e.target.value) : setLastName(e.target.value);
  };

  const handleChangeEmail = () => {
    showModal(ModalType.ChangeEmail, { email });
  };

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  const countries = countriesData.map(el => ({
    value: el.country,
    label: el.country,
  }));

  return (
    <Authorized>
      <MainTemplate customSideBar={<SidebarSettings />}>
        <Containter>
          <Header>
            <UserIcon />
            <Title>Персональные данные</Title>
          </Header>

          <ContentWrapper>
            <Content>
              <WorkPlaceEdit>
                <EditSection>
                  <Label>Профиль</Label>
                  <EditBlock>
                    <WorkPlaceFields>
                      <WorkPlaceTop>
                        <EditLogo>
                          <LogoPreview>
                            <CoverDefault />
                          </LogoPreview>
                          <LogoLabel>Аватар профиля</LogoLabel>
                        </EditLogo>
                        <LoadLogo>
                          <LoadLogoLabel>Мы поддерживаем PNG, JPEG и GIF до 10 МБ</LoadLogoLabel>
                          <Button label='Загрузить' onClick={handleClick} />
                          <input ref={hiddenFileInput} onChange={handleFile} type='file' style={{ display: 'none' }} />
                        </LoadLogo>
                      </WorkPlaceTop>
                    </WorkPlaceFields>
                  </EditBlock>
                </EditSection>
                <EditSection>
                  <EditItems>
                    <EditItem>
                      <label>Имя</label>
                      <Input
                        name='firstName'
                        placeholder='Имя'
                        onChange={e => handleChangeName(e, 'first')}
                        onBlur={onBlur}
                        value={firstName}
                      />
                    </EditItem>
                    <EditItem>
                      <label>Фамилия</label>
                      <Input
                        name='lastName'
                        placeholder='Фамилия'
                        onChange={e => handleChangeName(e, 'last')}
                        onBlur={onBlur}
                        value={lastName}
                      />
                    </EditItem>
                  </EditItems>
                  <EditItems>
                    <EditItem>
                      <label>Емаил</label>
                      <Input name='email' placeholder='Емаил' onChange={() => {}} value={email} />
                    </EditItem>
                    <EditEmail onClick={handleChangeEmail}>
                      <div>
                        <CircleArrowIcon />
                      </div>
                      <div>Сменить емаил</div>
                    </EditEmail>
                  </EditItems>
                  <EditItems>
                    <EditItem>
                      <label>Часовой пояс</label>
                      <SelectCustom
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder='Выберите часовой пояс'
                        defaultValue={utc ? utc[0] : 'Выберите часовой пояс'}
                        options={utc}
                      />
                    </EditItem>
                    <EditItem>
                      <label>Страна</label>
                      <SelectCustom
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        placeholder='Выберите страну'
                        defaultValue={countries ? countries[0] : 'Выберите страну'}
                        options={countries}
                      />
                    </EditItem>
                  </EditItems>
                </EditSection>
              </WorkPlaceEdit>
            </Content>
          </ContentWrapper>
        </Containter>
      </MainTemplate>
    </Authorized>
  );
};
