import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectWs } from 'processes/store';
import { ROUTE_WS } from 'shared/lib/routes';
import { generateAvatarLink } from 'shared/lib/generateAvatarLink';
import { copyText } from 'shared/lib/copyText';
import { Avatar, IconButton, SettingsDropdown } from 'shared/ui';
import { CopyIcon, DeleteBasket, PutAwayIcon, ThreePointsIcon } from 'shared/lib/Icons';
import { AccessText, AvatarBlock, ContactInfo, DeleteButton, DividerCustom, DropdownList, Text } from './styled';

type Props = {
  id: string;
  avatar: string;
  fullName: string;
  email?: string;
  link?: string;
  deleteContact: () => void;
  removeGroup?: () => void;
};

export const UserBlock: React.FC<Props> = ({ id, avatar, fullName, email, link, deleteContact, removeGroup }) => {
  const [open, setOpen] = useState(false);

  const { ws } = useSelector(selectWs);

  const navigate = useNavigate();

  const goLink = () => {
    navigate(`${ROUTE_WS}/${ws._id}/contacts/${id}`);
  };

  return (
    <ContactInfo>
      <AvatarBlock onClick={goLink}>
        <Avatar
          size={22}
          src={avatar ? generateAvatarLink({ src: avatar, wsId: ws._id, id, size: '50x50' }) : avatar}
        />
        <div>{fullName}</div>
      </AvatarBlock>
      <SettingsDropdown
        open={open}
        setOpen={setOpen}
        label={
          <AccessText onClick={() => setOpen(true)}>
            <IconButton active={open} icon={<ThreePointsIcon />} />
          </AccessText>
        }
      >
        <DropdownList>
          <Text onClick={() => copyText(fullName)} border>
            <CopyIcon />
            Скопировать имя
          </Text>
          <Text onClick={() => copyText(email || '')} border>
            <CopyIcon />
            Скопировать емаил
          </Text>
          <Text onClick={() => copyText(link || '')} border>
            <CopyIcon />
            Скопировать ссылку
          </Text>
          <Text border onClick={removeGroup}>
            <PutAwayIcon />
            Убрать из группы
          </Text>
          <DividerCustom margin='0' />
          <Text onClick={deleteContact} color='#FF0000'>
            <DeleteButton icon={<DeleteBasket />} />
            Удалить контакт
          </Text>
        </DropdownList>
      </SettingsDropdown>
    </ContactInfo>
  );
};
