import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'processes/store';
import { PageTitle } from '../ui/PageTitle';
import { RegistrationRoutes, RegistrationRoutesRenderers } from './model/types';
import { Content, Wrapper } from './styled';

export const RegistrationPage: React.FC = () => {
  const [step, setStep] = useState(RegistrationRoutes.greeting);
  const [email, setEmail] = useState('');

  const { user } = useSelector(selectUser);

  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#space') setStep(RegistrationRoutes.space);
    if (location.hash === '#customSpace') setStep(RegistrationRoutes.customizeSpace);
  }, [location]);

  useEffect(() => {
    if (user.finishRegistration) {
      setEmail(user.lastEmail);
      setStep(RegistrationRoutes.checkEmail);
    }
    // if (user.needWs) {
    //   setStep(RegistrationRoutes.space);
    // }
  }, [user.finishRegistration, user.lastEmail, user.needWs]);

  const handleSaveEmail = useCallback((email: string) => setEmail(email), []);
  const handleRedirect = useCallback((route: RegistrationRoutes) => setStep(route), []);

  return (
    <Wrapper>
      <Content>
        <PageTitle />
        {React.createElement(RegistrationRoutesRenderers[step], {
          stateEmail: email,
          handleRedirect,
          handleSaveEmail,
        })}
      </Content>
    </Wrapper>
  );
};
