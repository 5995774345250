import React, { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from 'processes/store';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { AuthButton } from 'pages/Auth/ui/AuthButton';
import { RestorePasswordRoutes } from 'pages/Auth/RestorePasswordPage/model/types';
import { Label, Wrapper } from '../styled';
import { newPasswordAction } from './model';
import { InputWrapper } from 'features/Auth/LoginForm/styled';
import { generateTextError } from 'features/Auth/LoginForm/lib';

type Inputs = {
  newPassword: string;
  newPasswordCheck: string;
};

type Props = {
  stateCode?: string;
  handleRedirect: (route: RestorePasswordRoutes) => void;
};

export const NewPassword: React.FC<Props> = ({ stateCode, handleRedirect }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      newPassword: '',
      newPasswordCheck: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        newPasswordAction({
          token: stateCode,
          newPassword: data.newPassword,
          successCallback: () => {
            setIsLoading(false);
            handleRedirect(RestorePasswordRoutes[RestorePasswordRoutes.finish]);
          },
          errorCallback: () => {
            return setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, handleRedirect, stateCode],
  );

  return (
    <Wrapper>
      <Label>
        Укажите
        <br /> новый пароль
      </Label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <AuthInput
            label='Новый пароль'
            name='newPassword'
            register={register}
            rules={{ required: true, maxLength: 250 }}
            hasError={!!errors.newPassword}
            textError={generateTextError({
              name: 'password',
              type: errors.newPassword?.type,
              message: errors.newPassword?.message,
            })}
            placeholder='Пароль'
            isFocus={!!watch('newPassword')}
            isPassword
          />
        </InputWrapper>
        <InputWrapper>
          <AuthInput
            label='Повторите новый пароль'
            name='newPasswordCheck'
            register={register}
            rules={{ required: true, maxLength: 250 }}
            hasError={!!errors.newPasswordCheck}
            textError={generateTextError({
              name: 'password',
              type: errors.newPasswordCheck?.type,
              message: errors.newPasswordCheck?.message,
            })}
            placeholder='Пароль'
            isFocus={!!watch('newPasswordCheck')}
            isPassword
          />
        </InputWrapper>
        <AuthButton label='Сменить пароль' isLoading={isLoading} />
      </form>
    </Wrapper>
  );
};
