import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';

type Request = {
  file?: Blob;
  x: number;
  y: number;
  width: number;
  height: number;
  preview_width: number;
  preview_height: number;
  id: string;
  wsId: string;
};

type Response = {
  result: { imageSrc: string };
  errors: IError[];
};

export const avatarApi = (params: Request): AxiosPromise<Response> => {
  if (params.file) {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('x', `${params.x}`);
    formData.append('y', `${params.y}`);
    formData.append('width', `${params.width}`);
    formData.append('height', `${params.height}`);
    formData.append('preview_width', `${params.preview_width}`);
    formData.append('preview_height', `${params.preview_height}`);

    return apiInstance.post(`avatar/ws/${params.wsId}/employees/${params.id}`, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return apiInstance.post(`avatar/ws/${params.wsId}/employees/${params.id}/crop`, {
    ...params,
  });
};
