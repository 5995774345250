import { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppDispatch } from 'processes/store';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { AuthButton } from 'pages/Auth/ui/AuthButton';
import { AuthLink } from 'pages/Auth/ui/AuthLink';
import { RestorePasswordRoutes } from 'pages/Auth/RestorePasswordPage/model/types';
import { ROUTE_LOGIN } from 'shared/lib/routes';
import { Label, InputWrapper, Wrapper } from '../styled';
import { generateTextError } from './lib';
import { requestCodeAction } from './model';

type Inputs = {
  email: string;
};

type Props = {
  handleRedirect: (route: RestorePasswordRoutes) => void;
  handleSaveEmail?: (email: string) => void;
};

export const RequestCode: React.FC<Props> = ({ handleRedirect, handleSaveEmail }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        requestCodeAction({
          login: data.email,
          successCallback: () => {
            setIsLoading(false);
            handleSaveEmail && handleSaveEmail(data.email);
            handleRedirect(RestorePasswordRoutes[RestorePasswordRoutes.checkCode]);
          },
          errorCallback: (err: { name: string; message: string }) => {
            if (err.name === 'email') {
              setError(err.name, { type: 'custom', message: err.message });
            }
            return setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, handleRedirect, setError, handleSaveEmail],
  );

  return (
    <Wrapper>
      <Label>Забыли пароль?</Label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <AuthInput
            label='Емаил'
            name='email'
            register={register}
            rules={{
              pattern: /[a-zA-Z0-9+._%-+]{1,256}@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+/,
              required: true,
              maxLength: 250,
            }}
            hasError={!!errors.email}
            textError={generateTextError({ name: 'email', type: errors.email?.type, message: errors.email?.message })}
            placeholder='Ваша электронная почта'
            isFocus={!!watch('email')}
          />
        </InputWrapper>
        <AuthButton label='Восстановить пароль' isLoading={isLoading} />
        <AuthLink to={ROUTE_LOGIN} text='Авторизоваться' />
      </form>
    </Wrapper>
  );
};
