import { AxiosPromise } from 'axios';
import { ITouches } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  page: number;
  limit?: number;
  search?: string;
  sort?: 'asc' | 'desc' | null;
  order_by?: string | null;
  _groupId?: string;
  contactId?: string;
  filters?: any;
};

type Response = {
  result: { list: ITouches[]; total: number };
  errors: IError[];
};

export const getTouchesApi = ({
  wsId,
  page,
  limit,
  search,
  sort,
  order_by,
  _groupId,
  contactId,
  filters,
}: Request): AxiosPromise<Response> => {
  const size = limit || 10;
  const address = search ? `ws/${wsId}/interactions/search` : `ws/${wsId}/interactions`;

  return apiInstance.get(_groupId ? `${address}?options[_groupId]=${_groupId}` : address, {
    params: {
      q: search ? search : null,
      'pagination[page]': page ? page : 1,
      'pagination[limit]': limit || 10,
      'pagination[size]': size,
      'pagination[sort]': sort ? sort : null,
      'pagination[order_by]': order_by ? order_by : '_createdOn',
      _relatedObject: contactId
        ? {
            _id: contactId,
          }
        : undefined,
      ...filters,
    },
  });
};
