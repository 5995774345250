import axios from 'axios';

export const generateTextError = ({ name, type, message }: { name: string; type?: string; message?: string }) => {
  switch (type) {
    case 'maxLength': {
      return 'Превышен лимит символов';
    }
    case 'required': {
      return 'Укажите емаил';
    }
    case 'pattern': {
      return 'Введите корректный email';
    }
    case 'custom': {
      return message;
    }
    default: {
      return '';
    }
  }
};
