import { IEmail } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
  id: string;
};

type Response = {
  result: IEmail;
  errors: IError[];
};
export const getEmailApi = ({ wsId, id }: Request): AxiosPromise<Response> => {
  return apiInstance.get(`ws/${wsId}/emails/${id}`);
};
