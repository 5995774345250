import { createAsyncThunk } from '@reduxjs/toolkit';
import { avatarApi } from 'shared/api';

type PayloadType = {
  values: {
    file?: Blob;
    x: number;
    y: number;
    width: number;
    height: number;
    preview_width: number;
    preview_height: number;
    id: string;
    wsId: string;
  };
  successCallback?: () => void;
  errorCallback?: (err: unknown) => void;
};

export const avatarAction = createAsyncThunk<object, PayloadType>('avatar', async (payload, { dispatch }) => {
  const { values, successCallback, errorCallback } = payload;
  try {
    const response = await avatarApi(values);
    if (response?.status === 200) {
      successCallback?.();
    }
  } catch (err) {
    errorCallback?.(err);
  }
});
