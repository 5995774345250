import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ModalType, selectContact, selectWs, useAppDispatch } from 'processes/store';
import { Authorized } from 'processes/Authorized';
import { TableContacts } from 'features/Contacts';
import { MainTemplate, Pagination, Spinner, IconButton } from 'shared/ui';
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon, ContactsIcon, PlusIcon } from 'shared/lib/Icons';
import { RequestState } from 'shared/lib/types';
import { useDebounce, useModal } from 'shared/hooks';
import { getContactsAction } from './model/getContactsAction';
import {
  AddButton,
  Containter,
  DivElement,
  EmptyData,
  FooterBlock,
  Header,
  SearchBlock,
  SearchField,
  SearchInput,
  Title,
} from './styled';

export const ContactsPage: React.FC = () => {
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<'asc' | 'desc' | null | undefined>(null);
  const [order_by, setOrder_by] = useState<string | null | undefined>(null);
  const [isLoading, setIsLoading] = useState(RequestState.PENDING);
  const [page, setPage] = useState(1);

  const dispatch = useAppDispatch();
  const debounceSearch = useDebounce(search, 500);

  const { ws } = useSelector(selectWs);
  const { contactListTable, needUpdateContact, total } = useSelector(selectContact);

  const { showModal } = useModal();

  useEffect(() => {
    setIsLoading(RequestState.LOADING);
    ws._id &&
      dispatch(
        getContactsAction({
          wsId: ws._id,
          page,
          limit: 10,
          search: debounceSearch,
          sort,
          order_by: order_by,
          successCallback: () => {
            setIsLoading(RequestState.SUCCESS);
          },
        }),
      );
  }, [ws._id, needUpdateContact, debounceSearch, page, sort, order_by, dispatch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const addContact = () => {
    showModal(ModalType.AddContact, {});
  };

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const handleClose = () => {
    setSearch('');
  };

  return (
    <Authorized>
      <MainTemplate>
        <Containter>
          <Header>
            <Title>
              <div>
                <ContactsIcon />
              </div>
              <div>Контакты</div>
            </Title>
          </Header>
          <SearchBlock>
            <SearchField>
              <SearchInput onChange={handleChange} name='search' value={search} placeholder='Поиск' />
              {!!search.length && <IconButton onClick={handleClose} icon={<CloseIcon />} />}
            </SearchField>
          </SearchBlock>
          {isLoading !== 'success' ? (
            <Spinner />
          ) : (
            <DivElement>
              {contactListTable.length ? (
                <TableContacts
                  list={contactListTable}
                  sort={sort}
                  setSort={setSort}
                  order_by={order_by}
                  setOrder_by={setOrder_by}
                />
              ) : (
                <EmptyData>
                  {search.length ? 'Не найдено ни одного контакта' : 'Вы не добавили ни одного контакта'}
                </EmptyData>
              )}
            </DivElement>
          )}

          <FooterBlock>
            <AddButton
              onClick={addContact}
              label={
                <>
                  <PlusIcon />
                  <div>Добавить контакт</div>
                </>
              }
            />
            {!!contactListTable.length && (
              <Pagination
                pageCount={Math.ceil(total / 10)}
                previousLabel={<ArrowLeftIcon />}
                nextLabel={<ArrowRightIcon />}
                onChange={handlePagination}
              />
            )}
          </FooterBlock>
        </Containter>
      </MainTemplate>
    </Authorized>
  );
};
