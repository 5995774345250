import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Row } from 'react-table';
import { useModal } from 'shared/hooks';
import { IContact, IStatus, ITags, ModalType, selectWs, useAppDispatch } from 'processes/store';
import { UserBlock, FieldDropdown } from './ui';
import {
  updateContactAction,
  deleteContactAction,
  addStatusAction,
  deleteStatusAction,
  changeStatusAction,
  addTagsAction,
  deleteTagsAction,
  changeTagsAction,
} from '../model';
import { TrBodyTable } from '../styled';
import { Chip, EmptyBlock, FieldElement, Pin, TdBody } from './styled';
import { TagsFieldDropdown } from './ui/TagsFieldDropdown';

const excludeCell = (ids: string[]) => {
  return ids.every(el => el !== 'user' && el !== 'email' && el !== 'phone' && el !== 'role');
};

// type Inputs = {
//   value: string;
//   isFavorites: boolean;
// };

type Props = {
  row: Row<{
    user: {
      title: string;
      avatar: string;
      id: string;
    };
    companies: string;
    email: string;
    status: string;
    tags: string;
    phone: string;
    role: string;
    groups: IContact;
  }>;
  contact: IContact;
  statuses?: IStatus[];
  tags?: ITags[];
  zIndex?: number;
  setIsNeedUpdate?: (e: boolean) => void;
};

export const GroupContactsUser: FC<Props> = ({ row, contact, statuses, tags, zIndex, setIsNeedUpdate }) => {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();

  const location = useLocation();

  const groupId = location.pathname.split('/').at(-1) || '';

  const { ws } = useSelector(selectWs);

  // const onBlur = (list: Inputs[], key: 'emails' | 'phones' | 'roles' | 'companies' | 'statuses') => {
  //   dispatch(
  //     updateContactAction({
  //       wsId: ws._id,
  //       params: {
  //         _id: contact._id,
  //         [key]: list,
  //       },
  //       successCallback: () => {},
  //     }),
  //   );
  // };

  const removeGroup = useCallback(
    (id: string, title: string) => {
      showModal(ModalType.DeleteElement, {
        id,
        title,
        onClick: () =>
          dispatch(
            updateContactAction({
              wsId: ws._id,
              params: {
                _id: contact._id,
                _groupId: contact._groupId.filter(el => !el.includes(groupId)),
              },
            }),
          ),
      });
    },
    [showModal, ws._id, contact, groupId, dispatch],
  );

  const deleteContact = (id: string, title: string) => {
    showModal(ModalType.DeleteElement, {
      id,
      title,
      onClick: () => {
        dispatch(deleteContactAction({ wsId: ws._id, id }));
      },
    });
  };

  // const handleShowModal = () => {
  //   showModal(ModalType.AddManuallyContact, { tab: '2' });
  // };

  const handleAddStatus = useCallback(
    (e: { color: string; title: string }) => {
      dispatch(
        addStatusAction({
          wsId: ws._id,
          color: e.color,
          title: e.title,
          successCallback: () => {
            setIsNeedUpdate && setIsNeedUpdate(true);
          },
        }),
      );
    },
    [ws, dispatch, setIsNeedUpdate],
  );

  const handleAddTags = useCallback(
    (e: { color: string; title: string }) => {
      dispatch(
        addTagsAction({
          wsId: ws._id,
          color: e.color,
          title: e.title,
          successCallback: () => {
            setIsNeedUpdate && setIsNeedUpdate(true);
          },
        }),
      );
    },
    [ws, dispatch, setIsNeedUpdate],
  );

  const handleSelectStatus = (id: string) => {
    id &&
      dispatch(
        updateContactAction({
          wsId: ws._id,
          params: {
            _id: contact._id,
            statuses: [{ value: id }],
          },
          successCallback: () => {},
        }),
      );
  };

  const handleSelectTags = (id: string) => {
    id &&
      dispatch(
        updateContactAction({
          wsId: ws._id,
          params: {
            _id: contact._id,
            tag: [...contact.tag, id],
          },
          successCallback: () => {},
        }),
      );
  };

  const handleRemoveTag = (id: string) => {
    id &&
      dispatch(
        updateContactAction({
          wsId: ws._id,
          params: {
            _id: contact._id,
            tag: [...contact.tag.filter(tag => tag !== id)],
          },
          successCallback: () => {},
        }),
      );
  };

  const handleDeleteStatus = useCallback(
    (id: string) => {
      dispatch(
        deleteStatusAction({
          wsId: ws._id,
          id,
          successCallback: () => {
            setIsNeedUpdate && setIsNeedUpdate(true);
          },
        }),
      );
    },
    [ws, dispatch, setIsNeedUpdate],
  );

  const handleDeleteTags = useCallback(
    (id: string) => {
      dispatch(
        deleteTagsAction({
          wsId: ws._id,
          id,
          successCallback: () => {
            setIsNeedUpdate && setIsNeedUpdate(true);
          },
        }),
      );
    },
    [ws, dispatch, setIsNeedUpdate],
  );

  const handleChangeStatus = useCallback(
    ({ id, color, title }: { id: string; color?: string; title?: string }) => {
      dispatch(
        changeStatusAction({
          wsId: ws._id,
          id,
          color,
          title,
          successCallback: () => {
            setIsNeedUpdate && setIsNeedUpdate(true);
          },
        }),
      );
    },
    [ws, dispatch, setIsNeedUpdate],
  );

  const handleChangeTags = useCallback(
    ({ id, color, title }: { id: string; color?: string; title?: string }) => {
      dispatch(
        changeTagsAction({
          wsId: ws._id,
          id,
          color,
          title,
          successCallback: () => {
            setIsNeedUpdate && setIsNeedUpdate(true);
          },
        }),
      );
    },
    [ws, dispatch, setIsNeedUpdate],
  );

  return (
    <TrBodyTable {...row.getRowProps()} style={{ zIndex: zIndex }}>
      {row.cells.map(cell => (
        <TdBody {...cell.getCellProps()}>
          {cell.column.id === 'user' && (
            <UserBlock
              id={cell.value.id}
              avatar={cell.value.avatar}
              fullName={cell.value.title}
              email={contact.emails.filter(({ isFavorites }) => isFavorites)[0]?.value}
              link={`http://localhost:3000/ws/${ws._id}/contacts/${cell.value.id}`}
              deleteContact={() => deleteContact(cell.value.id, cell.value.title)}
              removeGroup={() => removeGroup(cell.value.id, cell.value.title)}
            />
          )}
          {cell.column.id === 'companies' && (
            <FieldElement>
              {!!contact.companies.length ? (
                <Chip>
                  <Pin>
                    {contact.companies.filter(({ isFavorites }) => isFavorites).length
                      ? contact.companies.filter(({ isFavorites }) => isFavorites)[0]?.value[0]
                      : contact.companies[0]?.value[0]}
                  </Pin>
                  {contact.companies.filter(({ isFavorites }) => isFavorites).length
                    ? contact.companies.filter(({ isFavorites }) => isFavorites)[0]?.value
                    : contact.companies[0]?.value}
                </Chip>
              ) : (
                <EmptyBlock>—</EmptyBlock>
              )}
            </FieldElement>
          )}
          {cell.column.id === 'email' && (
            // <InputBlock
            //   placeholder='Укажите емаил'
            //   defaultValue={cell.value}
            //   onBlur={e => onBlur(e, 'emails')}
            //   name='email'
            //   list={contact?.emails}
            // />
            <FieldElement>{cell.value ? cell.value : <EmptyBlock>—</EmptyBlock>}</FieldElement>
          )}
          {cell.column.id === 'phone' && (
            // <InputBlock
            //   placeholder='Укажите телефон'
            //   defaultValue={cell.value}
            //   onBlur={e => onBlur(e, 'phones')}
            //   name='phone'
            //   list={contact?.phones}
            // />
            <FieldElement>{cell.value ? cell.value : <EmptyBlock>—</EmptyBlock>}</FieldElement>
          )}
          {cell.column.id === 'role' && (
            // <InputBlock
            //   placeholder='Укажите должность'
            //   defaultValue={cell.value}
            //   onBlur={e => onBlur(e, 'roles')}
            //   name='role'
            //   isBtn={false}
            //   list={contact?.roles}
            // />
            <FieldElement>{cell.value ? cell.value : <EmptyBlock>—</EmptyBlock>}</FieldElement>
          )}
          {cell.column.id === 'status' && (
            <FieldDropdown
              list={statuses}
              addBtn={e => handleAddStatus(e)}
              onSelect={handleSelectStatus}
              addBtnText='Новый'
              initValue={statuses?.filter(el => el._id.includes(contact.statuses[0]?.value))[0]}
              onDelete={handleDeleteStatus}
              onBlur={handleChangeStatus}
            />
          )}
          {cell.column.id === 'tags' && (
            // <FieldElement>{cell.value ? cell.value : <EmptyBlock>—</EmptyBlock>}</FieldElement>
            <TagsFieldDropdown
              list={tags}
              addBtn={e => handleAddTags(e)}
              onSelect={handleSelectTags}
              addBtnText='Новый'
              initValues={
                contact.tag.length > 0 ? tags?.filter(el => contact.tag.some(e => el._id.includes(e))) : undefined
              }
              onRemove={handleRemoveTag}
              onDelete={handleDeleteTags}
              onBlur={handleChangeTags}
            />
          )}
          {excludeCell(['user', 'email', 'phone', 'role', 'status']) && cell.render('Cell')}
        </TdBody>
      ))}
    </TrBodyTable>
  );
};
