import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Authorized } from 'processes/Authorized';
import {
  IStatus,
  ITags,
  ModalType,
  selectContact,
  selectGroup,
  selectTouch,
  selectWs,
  useAppDispatch,
} from 'processes/store';
import { GroupCanban, GroupContacts, GroupFilterBlock } from 'features/Group';
import { Dropdown, MainTemplate, Pagination, SettingsDropdown, Spinner, TouchesTable } from 'shared/ui';
import { getPluralText } from 'shared/lib/getPluralText';
import { ROUTE_WS } from 'shared/lib/routes';
import {
  AddCommentIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  BackArrow,
  CanbanIcon,
  ContactIcon,
  DeleteBasket,
  DropArrow,
  PlusIcon,
  ThreePointsIcon,
  TouchesNav,
} from 'shared/lib/Icons';
import { useDebounce, useModal } from 'shared/hooks';
import { RequestState } from 'shared/lib/types';
import {
  FieldsType,
  VariantVisible,
  createEmailAction,
  deleteGroupAction,
  getContactsAction,
  getContactsStatusesAction,
  getContactsTagsAction,
  getGroupAction,
  getTouchesAction,
  normalizeFilters,
} from './model';
import { SearchBlock } from './ui';
import {
  AccessText,
  ActionButtons,
  AddContactButton,
  BackButton,
  ButtonsBlock,
  Containter,
  DeleteButton,
  DeleteText,
  DropdownList,
  EmptyData,
  FooterBlock,
  Header,
  LeftButtons,
  MessageButton,
  RightButtons,
  SettingsButton,
  Text,
  Title,
} from './styled';

const getIcon = (access: string) => {
  switch (access) {
    case 'Список':
      return <ContactIcon />;
    case 'Канбан':
      return <CanbanIcon />;
    case 'Касания':
      return <TouchesNav />;
  }
};

export const GroupPage: FC = () => {
  const [access, setAccess] = useState<'list' | 'canban' | 'touches'>('list');
  const [open, setOpen] = useState(false);
  const [searchContact, setSearchContact] = useState('');
  const [searchTouch, setSearchTouch] = useState('');
  const [sort, setSort] = useState<'asc' | 'desc' | null | undefined>(null);
  const [order_by, setOrder_by] = useState<string | null | undefined>(null);
  const [statuses, setStatuses] = useState<IStatus[]>([]);
  const [tags, setTags] = useState<ITags[]>([]);
  const [isLoading, setIsLoading] = useState(RequestState.PENDING);
  const [fields, setFields] = useState<FieldsType[]>([]);
  const [page, setPage] = useState(1);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const { ws } = useSelector(selectWs);
  const { group } = useSelector(selectGroup);
  const { touchesList } = useSelector(selectTouch);
  const { contactList, total, needUpdateContact } = useSelector(selectContact);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const debounceSearchContact = useDebounce(searchContact, 500);
  const debounceSearchTouch = useDebounce(searchTouch, 500);

  const id = location.pathname.split('/').at(-1);

  useEffect(() => {
    //@ts-ignore
    setAccess(location?.hash?.split('#').at(-1) || 'list');
  }, [location]);

  useEffect(() => {
    setIsLoading(RequestState.LOADING);
    ws._id &&
      id &&
      dispatch(
        getGroupAction({
          wsId: ws._id,
          id,
          successCallback: () => {
            setIsLoading(RequestState.SUCCESS);
          },
        }),
      );
  }, [dispatch, ws, id]);

  useEffect(() => {
    ws._id &&
      id &&
      dispatch(
        getContactsStatusesAction({
          wsId: ws._id,
          _groupId: id,
          page: 1,
          successCallback: list => {
            list && setStatuses(list);
            setIsNeedUpdate(false);
          },
        }),
      );
  }, [dispatch, ws, id, isNeedUpdate]);

  useEffect(() => {
    ws._id &&
      id &&
      dispatch(
        getContactsTagsAction({
          wsId: ws._id,
          _groupId: id,
          page: 1,
          successCallback: list => {
            list && setTags(list);
            setIsNeedUpdate(false);
          },
        }),
      );
  }, [dispatch, ws, id, isNeedUpdate]);

  useEffect(() => {
    setIsLoading(RequestState.LOADING);
    ws._id &&
      id &&
      dispatch(
        getContactsAction({
          wsId: ws._id,
          page,
          _groupId: id,
          sort,
          search: debounceSearchContact,
          order_by: order_by,
          filters: normalizeFilters(fields),
          successCallback: () => {
            setIsLoading(RequestState.SUCCESS);
          },
        }),
      );
  }, [dispatch, ws, id, page, sort, debounceSearchContact, order_by, fields, needUpdateContact]);

  useEffect(() => {
    ws._id &&
      dispatch(
        getTouchesAction({
          wsId: ws._id,
          page: 1,
          _groupId: id,
          search: debounceSearchTouch,
          successCallback: () => {},
          errorCallback: () => {},
        }),
      );
  }, [ws._id, id, debounceSearchTouch, dispatch]);

  const handleClick = (e: 'list' | 'canban' | 'touches') => {
    setAccess(e);
    navigate(`#${e}`);
    setOpen(false);
  };

  const addContact = () => {
    showModal(ModalType.AddContact, {});
  };

  const handleSearch = useCallback(
    (e: string) => {
      if (access === 'list') {
        setSearchContact(e);
      } else {
        setSearchTouch(e);
      }
    },
    [access],
  );

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const handleDeleteGroup = useCallback(
    (id: string, title: string) => {
      showModal(ModalType.DeleteElement, {
        id,
        title,
        onClick: () =>
          dispatch(
            deleteGroupAction({
              wsId: ws._id,
              id,
              successCallback: () => {
                navigate(`${ROUTE_WS}/${ws._id}`);
              },
            }),
          ),
      });
    },
    [ws._id, showModal, dispatch, navigate],
  );

  const writeEmail = () => {
    dispatch(
      createEmailAction({
        wsId: ws._id,
        successCallback: (id?: string) => navigate(`${ROUTE_WS}/${ws._id}/emails/${id}?groupId=${group._id}`),
        errorCallback: () => {},
      }),
    );
  };

  return (
    <Authorized>
      <MainTemplate>
        <Containter>
          <Header>
            <Title>
              <BackButton onClick={() => window.history.back()}>
                <BackArrow />
              </BackButton>
              {group.title}
            </Title>
            <ButtonsBlock>
              {/* <ShareButton>
                <ShareIcon />
                Поделиться
              </ShareButton> */}

              <Dropdown
                offset={10}
                placement='bottom-end'
                delay={300}
                onChange={setOpen}
                label={
                  <SettingsButton>
                    <ThreePointsIcon />
                  </SettingsButton>
                }
              >
                <DropdownList>
                  <DeleteText onClick={() => handleDeleteGroup(group._id, group.title)} color='#FF0000'>
                    <DeleteButton icon={<DeleteBasket />} />
                    Удалить группу
                  </DeleteText>
                </DropdownList>
              </Dropdown>
            </ButtonsBlock>
          </Header>
          <ActionButtons>
            <LeftButtons>
              <SettingsDropdown
                open={open}
                setOpen={setOpen}
                label={
                  <AccessText onClick={() => setOpen(true)}>
                    {getIcon(access)} {VariantVisible[access]}
                    <span>
                      {access === 'list' ? contactList.length : touchesList.length}{' '}
                      {getPluralText(
                        access === 'list' ? contactList.length : touchesList.length,
                        access === 'list' ? ['контакт', 'контакта', 'контактов'] : ['письмо', 'письма', 'писем'],
                      )}{' '}
                    </span>
                    <DropArrow />
                  </AccessText>
                }
              >
                <DropdownList>
                  <Text color='#6A7B9B'>Варианты</Text>
                  <Text active={access === 'list'} border onClick={() => handleClick('list')}>
                    <div>{getIcon('Список')} Список</div>
                    <div>
                      {contactList.length} {getPluralText(contactList.length, ['контакт', 'контакта', 'контактов'])}
                    </div>
                  </Text>
                  <Text disabled border>
                    <div>{getIcon('Канбан')} Канбан</div>
                    <div>
                      {contactList.length} {getPluralText(contactList.length, ['контакт', 'контакта', 'контактов'])}
                    </div>
                  </Text>
                  <Text active={access === 'touches'} border onClick={() => handleClick('touches')}>
                    <div>{getIcon('Касания')} Касания</div>
                    <div>
                      {touchesList.length} {getPluralText(touchesList.length, ['письмо', 'письма', 'писем'])}
                    </div>
                  </Text>
                </DropdownList>
              </SettingsDropdown>
              {/* <IconButton icon={<ShareIcon />} /> */}
            </LeftButtons>
            <RightButtons>
              {/* <IconButton icon={<EyeIcon />} />
              <IconButton icon={<ChartIcon />} /> */}
              <GroupFilterBlock isFiltered={!!fields[0]?.condition} setFields={setFields} />
              <SearchBlock setSearch={handleSearch} />
              <MessageButton onClick={writeEmail}>
                <AddCommentIcon />
                Сообщение всем
              </MessageButton>
              {/* <CallButton>
                <CallIcon />
                Звонок всем
              </CallButton> */}
            </RightButtons>
          </ActionButtons>
          {isLoading !== 'success' ? (
            <Spinner />
          ) : (
            <>
              {access === 'list' && (
                <>
                  {contactList.length ? (
                    <GroupContacts
                      contactList={contactList}
                      statuses={statuses}
                      tags={tags}
                      sort={sort}
                      order_by={order_by}
                      setSort={setSort}
                      setOrder_by={setOrder_by}
                      setIsNeedUpdate={setIsNeedUpdate}
                    />
                  ) : (
                    <EmptyData>Вы не добавили ни одного контакта</EmptyData>
                  )}
                </>
              )}
              {access === 'canban' && <GroupCanban />}
              {access === 'touches' && (
                <>
                  {touchesList.length ? (
                    <TouchesTable touchesList={touchesList} />
                  ) : (
                    <EmptyData>У вас пока нет касаний в данной группе</EmptyData>
                  )}
                </>
              )}
            </>
          )}
          <FooterBlock justify={access !== 'list' ? 'end' : undefined}>
            {access === 'list' && (
              <AddContactButton
                onClick={addContact}
                label={
                  <>
                    <PlusIcon />
                    <div>Добавить контакт</div>
                  </>
                }
              />
            )}

            {!!contactList.length && (
              <Pagination
                pageCount={Math.ceil(total / 10)}
                previousLabel={<ArrowLeftIcon />}
                nextLabel={<ArrowRightIcon />}
                onChange={handlePagination}
              />
            )}
          </FooterBlock>
        </Containter>
      </MainTemplate>
    </Authorized>
  );
};
