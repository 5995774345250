import styled from 'styled-components';
import { alignMiddle, flexRow } from 'shared/lib/mixins';

export const Wrapper = styled.div`
  width: 260px;
  background: var(--color-bg-background);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
`;

export const Item = styled.div`
  padding: 20px 24px 20px 20px;
  display: flex;
  justify-content: space-between;
`;

export const ItemInfo = styled.div`
  display: flex;
  cursor: pointer;
`;

export const AvatarDefault = styled.div`
  ${flexRow};
  ${alignMiddle};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--color-gray-dark);
  color: var(--color-white);
  font-family: var(--font-bold);
  font-size: 20px;
  svg {
    fill: var(--color-gray-text);
  }
`;

export const ItemDescription = styled.div`
  margin-left: 10px;
`;

export const ItemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 17px;
`;

export const ItemAccess = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: right;
  color: #6a7b9b;
  margin-top: 5px;
`;

export const ItemIcon = styled.div`
  margin: auto 0;
  cursor: pointer;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: var(--color-mybase-line-gray);
`;

export const Divider = styled.div`
  width: 100%;
  height: 3px;
  background: var(--color-mybase-line-gray);
`;

export const NewWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 21px;
`;

export const Text = styled.div`
  margin-left: 11px;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  color: var(--color-black);
`;
