import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import excelFile from 'assets/Шаблон Excel.xlsx';
import {
  IContact,
  ModalType,
  selectWs,
  setNeedUpdateContact,
  setNeedUpdateGroup,
  useAppDispatch,
} from 'processes/store';
import { useModal } from 'shared/hooks';
import { BigPlusIcon, CSVDocIcon, CloseIcon, ContactsListIcon } from 'shared/lib/Icons';
import { importContactsExcelAction, importContactsGoogleAction } from './model';
import { Card, CloseButton, ContentBlock, HelpText, ModalWrapper, TitleBlock } from './styled';
import { createGroupAction } from '../CreateGroupModal/model/createGroupAction';
import { addContactsInGroupAction } from './model/addContactsInGroupAction';

type Props = {
  onCloseModal: () => void;
};

export const AddContactModal: React.FC<Props> = ({ onCloseModal }) => {
  const [file, setFile] = useState<File>();

  const { ws } = useSelector(selectWs);

  const dispatch = useAppDispatch();

  const { showModal } = useModal();

  const hiddenFileInput = useRef(null);

  useEffect(() => {
    file &&
      dispatch(
        importContactsExcelAction({
          wsId: ws._id,
          file,
          successCallback: (contacts?: IContact[]) => {
            dispatch(
              createGroupAction({
                wsId: ws._id,
                title: file.name,
                collectionName: 'contacts',
                successCallback: (groupId?: string) => {
                  const contactsIds = contacts && contacts.map(el => el._id);
                  dispatch(
                    addContactsInGroupAction({
                      wsId: ws._id,
                      ids: contactsIds,
                      groupId: groupId,
                      successCallback: () => {
                        setNeedUpdateContact(true);
                        setNeedUpdateGroup(true);
                      },
                    }),
                  );
                },
                errorCallback: () => {},
              }),
            );
            onCloseModal();
          },
          errorCallback: () => onCloseModal(),
        }),
      );
  }, [file, ws, dispatch, onCloseModal]);

  const handleClick = (id: number) => {
    if (id === 1) {
      // @ts-ignore
      hiddenFileInput.current.click();
    }
    if (id === 2) {
      dispatch(
        importContactsGoogleAction({
          wsId: ws._id,
          successCallback: () => onCloseModal(),
          errorCallback: () => onCloseModal(),
        }),
      );
    }
    if (id === 3) {
      showModal(ModalType.AddManuallyContact);
    }
  };

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  return (
    <ModalWrapper>
      <TitleBlock>
        <div>Добавить контакт</div>
        <CloseButton onClick={onCloseModal}>
          <CloseIcon />
        </CloseButton>
      </TitleBlock>
      <ContentBlock>
        <>
          <div>
            <Card onClick={() => handleClick(1)}>
              <div>
                <CSVDocIcon />
              </div>
              <div>Импорт из эксель</div>
            </Card>
            <HelpText>
              для импорта из эксель, скачайте и заполните файл эксель,{' '}
              <a href={excelFile} download>
                скачать
              </a>
            </HelpText>
            <input
              ref={hiddenFileInput}
              onChange={handleFile}
              type='file'
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              style={{ display: 'none' }}
            />
          </div>
          <Card onClick={() => handleClick(2)}>
            <div>
              <ContactsListIcon />
            </div>
            <div>Импорт из Google</div>
          </Card>
          <Card onClick={() => handleClick(3)}>
            <div>
              <BigPlusIcon />
            </div>
            <div>Добавить в ручную</div>
          </Card>
        </>
      </ContentBlock>
    </ModalWrapper>
  );
};
