import { Divider, IconButton } from 'shared/ui';
import styled from 'styled-components';

export const TableWrapper = styled.table`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  width: 100%;

  thead {
    color: var(--color-mybase-gray-text);
  }

  th {
    text-align: start;
    padding: 20px;
  }
  th:first-child {
    border-right: 1px solid var(--color-gray-dark);
  }

  th:first-child,
  td:first-child {
    max-width: 277px;
    width: 277px;
  }
  th:nth-child(2),
  td:nth-child(2) {
    max-width: 153px;
    width: 153px;
  }
  th:nth-child(3),
  td:nth-child(3) {
    max-width: 180px;
    width: 180px;
  }
  th:nth-child(4),
  td:nth-child(4) {
    max-width: 162px;
    width: 162px;
  }

  td {
    padding: 20px;
  }
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    border-right: 1px solid var(--color-gray-dark);
  }
  td:nth-child(2) {
  }
  td:nth-child(3),
  td:nth-child(6),
  td:nth-child(7) {
  }
  td:nth-child(4) {
  }
  td:nth-child(5) {
  }
`;

export const TrHeadTable = styled.tr`
  height: 60px;
  border: 1px solid var(--color-gray-dark);
  border-left: none;
`;

export const TrBodyTable = styled(TrHeadTable)`
  position: relative;
  height: 66px;
`;

export const ContactInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;
`;

export const AccessText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DropdownList = styled.div`
  background: var(--color-white);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  min-width: 200px;
`;

export const Text = styled.div<{ color?: string; border?: boolean; active?: boolean }>`
  display: flex;
  font-style: normal;
  column-gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
  color: ${({ color }) => (color ? color : 'var(--color-black)')};
  border-bottom: ${({ border }) => (!border ? 'none' : '1px solid var(--color-gray-dark)')};
  align-items: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
    
    `}

  &:hover {
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const DeleteButton = styled(IconButton)`
  max-width: max-content;
  max-height: max-content;
  margin-right: 10px;
  svg {
    fill: #ff0000;
  }
`;

export const GroupsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const GroupsList = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 10px;
  width: 100%;
`;

export const GroupCard = styled.div`
  font-weight: 400;
  font-size: 14px;
  word-break: break-all;
  line-height: 22px;
  padding: 5px 12px;
  max-height: 32px;
  max-width: 250px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-line-clamp: 1;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
`;

export const ShowMoreGroups = styled(GroupCard)`
  padding: 5px 12px;
  width: 53px;
  min-width: 44px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    border: 1px solid black;
    color: black;
  }
`;

export const AddButton = styled(ShowMoreGroups)`
  color: #adb8cd;
  width: 35px;
  min-width: 35px;
  justify-content: center;
`;

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    padding: 5px 10px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &:hover {
      background-color: var(--color-gray-btn-hv);
      svg {
        fill: var(--color-gray-icon-btn-hv);
      }
    }
  }
`;

export const DividerCustom = styled(Divider)`
  border: none;
  background-color: var(--color-gray-dark);
  height: 3px;
`;
