import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import bg from 'shared/img/Lazy 1.png';
import { BackArrow } from 'shared/lib/Icons';
import { Divider, Step } from 'shared/ui';
import { RegistrationRoutes } from '../model';
import { TagUsage } from './ui/TagUsage';
import { CustomBlueBlock, CustomButton, CustomWhiteBlock, DivBlock, GoBack, TagsBlock, Text, Title } from './styled';

type Props = {
  handleRedirect: (route: RegistrationRoutes) => void;
};

const mockData = [
  'Продажи',
  'Рекрутинг',
  'Маркетинг',
  'Инвестирование',
  'Успех клиентов',
  'Сбор средств',
  'Пиар',
  'Другое',
];

export const RegistrationCustomizeSpace: FC<Props> = ({ handleRedirect }) => {
  const location = useLocation();

  return (
    <DivBlock>
      {location.hash !== '#space' && (
        <GoBack onClick={() => handleRedirect(RegistrationRoutes[RegistrationRoutes.synchronization])}>
          <BackArrow />
        </GoBack>
      )}
      <CustomWhiteBlock>
        <Step count={4} finishStep={5} />
        <Title>
          Помогите настроить <br /> ваше пространство
        </Title>
        <Text>MyBase дает вам возможность создать именно ту CRM, которая вам нужна, какой бы сложной она ни была</Text>
        <Text fontSize={12}>
          Вы можете легко создавать рабочие процессы практически для любого варианта использования. У нас есть десятки
          шаблонов, чтобы вы могли начать работу, или вы можете начать с чистого листа.
        </Text>
        <Divider />
        <Text fontSize={15}>Для чего вы будете использовать?</Text>
        <TagsBlock>
          {mockData.map(el => (
            <TagUsage key={el} tag={el} />
          ))}
        </TagsBlock>
        <CustomButton
          onClick={() => handleRedirect(RegistrationRoutes[RegistrationRoutes.collaborateTeam])}
          label='Продолжить'
        />
      </CustomWhiteBlock>
      <CustomBlueBlock>
        <img src={bg} alt='' />
      </CustomBlueBlock>
    </DivBlock>
  );
};
