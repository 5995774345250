import React, { useCallback } from 'react';
import { Control, FieldValues, UseFormRegister, useFieldArray } from 'react-hook-form';
import { PlusIcon } from 'shared/lib/Icons';
import { InputField } from '../ui/InputField';
import { AddInputBtn, FieldWrapper, Label } from './styled';

type Props = {
  label: string;
  name: string;
  isPin?: boolean;
  $isEmpty?: boolean;
  isCopy?: boolean;
  isOnly?: boolean;
  icon?: JSX.Element;
  isActionButtons?: boolean;
  register: UseFormRegister<any>;
  control?: Control<FieldValues> | undefined;
  placeholder?: string;
  onBlur?: () => void;
  onFavorite?: (name: string, value: string) => void;
  delCode?: (e: any) => void;
  handleTelephone?: (e: string, index: number) => void;
};

export const FieldInputs: React.FC<Props> = ({
  label,
  isPin,
  $isEmpty,
  isCopy,
  isOnly,
  icon,
  name,
  placeholder = '',
  isActionButtons = true,
  register,
  control,
  onBlur,
  delCode,
  onFavorite,
  handleTelephone,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const handleRemove = (index: number) => {
    remove(index);
    onBlur && onBlur();
  };

  const handleBlur = useCallback(() => {
    onBlur && onBlur();
  }, [onBlur]);

  return (
    <FieldWrapper>
      <Label onClick={e => e.preventDefault()}>
        <div>
          {icon} {label}
        </div>
        {!isOnly && (
          <AddInputBtn type='button' onClick={() => append('')}>
            <PlusIcon />
          </AddInputBtn>
        )}
      </Label>
      {isOnly ? (
        <InputField
          $isEmpty={$isEmpty}
          name={name}
          isCopy={isCopy}
          isPin={isPin}
          placeholder={placeholder}
          isActionButtons={isActionButtons}
          register={register}
          onBlur={handleBlur}
        />
      ) : (
        fields.map((field, index) => (
          <InputField
            key={field.id}
            // @ts-ignore
            field={field}
            index={index}
            $isEmpty={$isEmpty}
            name={`${name}.${index}.value`}
            isCopy={isCopy}
            isPin={isPin}
            placeholder={placeholder}
            isActionButtons={isActionButtons}
            register={register}
            onBlur={handleBlur} //@ts-ignore
            onFavorite={onFavorite ? () => onFavorite(name, field.value) : undefined}
            onDelete={() => handleRemove(index)}
            handleTelephone={handleTelephone}
            delCode={delCode}
          />
        ))
      )}
    </FieldWrapper>
  );
};
