import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_REGISTRATION, ROUTE_WS } from 'shared/lib/routes';
import { selectWs, useAppDispatch } from 'processes/store';
import { authMainAction } from 'processes/Authorized/model';
import { addGoogleAccountAction } from './model';

export const LoginSocialPage: React.FC = () => {
  const { ws } = useSelector(selectWs);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const isAttach = Boolean(localStorage.getItem('attach'));

  useEffect(() => {
    if (isAttach) {
      dispatch(
        addGoogleAccountAction({
          successCallback: () => {
            navigate(`${ROUTE_REGISTRATION}${location.search}#customSpace`);
            localStorage.removeItem('attach');
          },
          errorCallback: () => navigate('/logout'),
        }),
      );
    } else {
      dispatch(
        authMainAction({
          successCallback: () => {
            ws._id && navigate(`${ROUTE_WS}/${ws._id}`);
          },
          errorCallback: () => navigate(`${ROUTE_REGISTRATION}${location.search}#space`),
        }),
      );
    }
  }, [dispatch, navigate, ws, location, isAttach]);

  return (
    <></>
    // <DivElement>
    //   <LogoWrapper>
    //     <img src={logoSrc} alt='' />
    //     <span>mybase</span>
    //   </LogoWrapper>
    //   <Wrapper>
    //     <Title>Новый аккаунт</Title>
    //     <Email>{email}</Email>
    //     <Button disabled label='Добавить к существующему аккаунту в MyBase' />
    //     <CreateAccountButton onClick={goConfirmPhone} label='Создать новый аккаунт в MyBase' />
    //     <CancelButton label='Отменить' onClick={() => navigate(ROUTE_LOGIN)} />
    //   </Wrapper>
    // </DivElement>
  );
};
