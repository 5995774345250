import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import bg from 'shared/img/Lazy 1.png';
import { Divider, Step, Switcher } from 'shared/ui';
import { AvatarIcon } from 'shared/lib/Icons';
import { useAppDispatch } from 'processes/store';
import { RegistrationRoutes } from '../model';
import { Button } from '../styled';
import { changeUserInfoAction } from './model/changeUserInfoAction';
import {
  Avatar,
  AvatarBlock,
  AvatarEmpty,
  BlockInfo,
  CustomBlueBlock,
  CustomWhiteBlock,
  DivBlock,
  FirstNameInput,
  LastNameInput,
  NameDiv,
  Title,
  UserInfoBlock,
} from './styled';

type Inputs = {
  firstName: string;
  lastName: string;
};

type Props = {
  handleRedirect: (route: RegistrationRoutes) => void;
};

export const RegistrationMeet: FC<Props> = ({ handleRedirect }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { register, handleSubmit } = useForm<{ firstName: string; lastName: string }>({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      const { firstName, lastName } = data;

      const user = {
        fullName: `${firstName} ${lastName}`,
      };
      setIsLoading(true);
      dispatch(
        changeUserInfoAction({
          params: user,
          successCallback: () => {
            setIsLoading(false);
            handleRedirect(RegistrationRoutes[RegistrationRoutes.space]);
          },
          // errorCallback: (err: { name: string; message: string }) => {
          //   if (err.name === 'email') {
          //     setError(err.name, { type: 'custom', message: err.message });
          //   }
          //   return setIsLoading(false);
          // },
        }),
      );
    },
    [dispatch, handleRedirect],
  );

  return (
    <DivBlock>
      <CustomWhiteBlock>
        <Step count={1} finishStep={5} />
        <Title>
          Давайте <br /> знакомиться
        </Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AvatarBlock>
            <Avatar>
              <AvatarIcon />
            </Avatar>
            <BlockInfo>
              <div>Картинка профиля</div>
              <div>jpg или png, не меньше 400px на 400px</div>
            </BlockInfo>
          </AvatarBlock>
          <Divider />
          <FirstNameInput
            register={register}
            name='firstName'
            label='Ваше имя'
            placeholder='Как вас зовут'
            rules={{
              required: true,
              maxLength: 250,
            }}
          />
          <LastNameInput
            register={register}
            name='lastName'
            label='Ваша фамилия'
            placeholder='Фамилия'
            rules={{
              maxLength: 250,
            }}
          />
          <Switcher text='Подписаться на новости' />
          <Button label='Продолжить' />
        </form>
      </CustomWhiteBlock>
      <CustomBlueBlock>
        <img src={bg} alt='' />
        <UserInfoBlock>
          <AvatarEmpty />
          <NameDiv>Имя</NameDiv>
          <NameDiv>Фамилия</NameDiv>
        </UserInfoBlock>
      </CustomBlueBlock>
    </DivBlock>
  );
};
