import React, { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useAppDispatch } from 'processes/store';
import { ROUTE_LOGIN } from 'shared/lib/routes';
import { logoutAction } from './model';

export const LogoutPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return <Navigate to={ROUTE_LOGIN} />;
};
