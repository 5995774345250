/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
// eslint-disable-next-line import/no-named-as-default
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Wrapper } from './styled';

type Props = {
  src: string;
  onCropper: (item: { height: number; left: number; top: number; width: number }) => void;
};

export const Crop: React.FC<Props> = ({ src, onCropper }) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    onCropper(cropper.getCropBoxData());
  };

  return (
    <Wrapper>
      <Cropper
        src={src}
        style={{ height: 300, width: 300 }}
        initialAspectRatio={16 / 9}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
        aspectRatio={1}
        responsive={false}
        restore={false}
        checkCrossOrigin={false}
        checkOrientation={false}
        center={false}
        background={false}
        movable={false}
        rotatable={false}
        zoomable={false}
      />
    </Wrapper>
  );
};
