import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser } from 'processes/store';
import { updateUserApi } from 'shared/api';

type PayloadAction = {
  user: Partial<IUser>;
  successCallback?: () => void;
  errorCallback?: (err?: unknown) => void;
};

export const updateUserAction = createAsyncThunk<object, PayloadAction>('change/user', async payload => {
  const { user, successCallback, errorCallback } = payload;
  try {
    const response = await updateUserApi({ ...user });
    if (response.status === 200) {
      successCallback?.();
    }
  } catch (err) {
    errorCallback?.(err);
  }
});
