import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeContactsTagsApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  color?: string;
  title?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const changeTagsAction = createAsyncThunk<object, PayloadType>(
  'change/contactTags',
  async (payload, { dispatch }) => {
    const { wsId, id, color, title, errorCallback, successCallback } = payload;
    try {
      const response = await changeContactsTagsApi({ wsId, id, color, title });
      if (response.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
