import React from 'react';
import { Button } from './styled';

type Props = {
  icon: JSX.Element;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  active?: boolean;
};

export const IconButton: React.FC<Props> = ({ icon, active, className, onClick }) => (
  <Button active={active} className={className} onClick={onClick}>
    {icon}
  </Button>
);
