import { FC, ReactNode, useEffect, useState } from 'react';
import { SwitcherBlock, SwitcherContainer, SwitcherKnob } from './styled';

type Props = {
  defaultValue?: boolean;
  onChange?: (e: boolean) => void;
  text?: string;
  className?: string;
  children?: ReactNode;
};

export const Switcher: FC<Props> = ({ defaultValue, onChange, text, className, children }) => {
  const [on, setOn] = useState(defaultValue || false);

  useEffect(() => {
    defaultValue !== undefined && setOn(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setOn(!on);
    onChange && onChange(!on);
  };

  return (
    <SwitcherBlock onClick={handleClick} className={className}>
      <div>{children || text}</div>
      <SwitcherContainer $on={on}>
        <SwitcherKnob $on={on} />
      </SwitcherContainer>
    </SwitcherBlock>
  );
};
