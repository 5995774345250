import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendCodeApi, signupUserApi } from 'shared/api';
import { getMessageFromError } from '../lib';

type PayloadType = {
  email: string;
  password: string;
  fullName: string;
  successCallback?: () => void;
  errorCallback?: (msg: { name: string; message: string }) => void;
};

export const signUpAction = createAsyncThunk<object, PayloadType>('user/signup', async (payload, { dispatch }) => {
  const { email, password, fullName, errorCallback, successCallback } = payload;
  try {
    const response = await signupUserApi({ email, password, fullName });

    if (response.status === 200) {
      const response2 = await sendCodeApi({ email });
      if (response2.status === 200) {
        successCallback && successCallback();
      }
    }
  } catch (err) {
    const msg = getMessageFromError(err);
    errorCallback && errorCallback(msg);
  }
});
