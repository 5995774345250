import styled from 'styled-components';
import { DefaultButton } from 'shared/ui';

export const AddUserButton = styled(DefaultButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-gray-dark);
  width: 33px;
  height: 33px;
  border-radius: 4px;
  cursor: pointer;
`;

export const DropdownList = styled.div`
  font-family: Geometria;
  background: var(--color-white);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  min-width: 343px;
`;

export const Label = styled.div`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-gray-label);
  height: 41px;
  padding: 5px 20px;
  display: flex;
  align-items: end;
`;

export const InputBlock = styled.div`
  position: relative;
  height: 50px;
  display: flex;
  border-bottom: 1px solid var(--color-gray-dark);

  svg {
    position: absolute;
    top: 18px;
    left: 20px;
  }
`;

export const Input = styled.input`
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 46px;

  &::placeholder {
    color: var(--color-gray-text);
  }
`;

export const ListWrapper = styled.div`
  height: 240px;
  overflow-y: scroll;
`;

export const Contact = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 14px 20px;
  border-bottom: 1px solid var(--color-gray-dark);
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
    /* &:first-child {
      border-radius: 6px 6px 0 0;
    } */
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
`;

export const InfoBlock = styled.div`
  font-family: Geometria;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
`;

export const Email = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--color-gray-label);
`;
