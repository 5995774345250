import { IEmailReceivers } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
  emailId: string;
  page: number;
  limit?: number;
};

type Response = {
  result: IEmailReceivers;
  errors: IError[];
};
export const getEmailReceiversApi = ({ wsId, emailId, page, limit }: Request): AxiosPromise<Response> => {
  const size = limit || 10;

  return apiInstance.get(`ws/${wsId}/emails/${emailId}/email_receivers`, {
    params: {
      'pagination[page]': page ? page : 1,
      'pagination[limit]': limit || 10,
      'pagination[size]': size,
      // 'pagination[sort]': sort ? sort : null,
      // 'pagination[order_by]': order_by ? order_by : '_createdOn',
    },
  });
};
