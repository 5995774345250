import { AxiosPromise } from 'axios';
import { IStatus } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  id: string;
  color?: string;
  title?: string;
};

type Response = {
  result: { list: IStatus[]; total: number };
  errors: IError[];
};

export const changeContactsStatusApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.patch(`ws/${params.wsId}/contacts/statuses/${params.id}`, {
    color: params.color,
    title: params.title,
  });
};
