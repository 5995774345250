import React from 'react';
import logoSrc from 'shared/img/logo.svg';
import { Wrapper, Text } from './styled';

export const PageTitle: React.FC = () => {
  return (
    <Wrapper>
      <img src={logoSrc} alt='Лого mybase' />
      <Text>mybase</Text>
    </Wrapper>
  );
};
