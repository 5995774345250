import { createAsyncThunk } from '@reduxjs/toolkit';
import { IContact } from 'processes/store';
import { importContactsExcelApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  file: File;
  successCallback?: (contacts?: IContact[]) => void;
  errorCallback?: (err: unknown) => void;
};
export const importContactsExcelAction = createAsyncThunk<object, PayloadType>(
  'add/contacts',
  async (payload, { dispatch }) => {
    const { wsId, file, successCallback, errorCallback } = payload;
    try {
      const response = await importContactsExcelApi({ wsId, file });
      if (response.status === 200) {
        successCallback && successCallback(response.data.result);
      }
    } catch (err) {
      errorCallback?.(err);
    }
  },
);
