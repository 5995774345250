import { IContact } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
  emailId: string;
};

type Response = {
  result: IContact;
  errors: IError[];
};
export const sendEmailApi = ({ wsId, emailId }: Request): AxiosPromise<Response> => {
  return apiInstance.get(`ws/${wsId}/emails/${emailId}/send`);
};
