import React, { PropsWithChildren } from 'react';
import { ArrowIcon } from 'shared/lib/Icons';
import googleicon from 'shared/img/google.png';
import { Spinner } from 'shared/ui';
import { Button, Text, Img } from './styled';

type Props = {
  label: string | JSX.Element;
  isGoogle?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
};

export const AuthButton: React.FC<PropsWithChildren<Props>> = ({
  label,
  className,
  isGoogle,
  isLoading,
  onClick,
  ...rest
}) => (
  <Button className={className} onClick={onClick} isGoogle={isGoogle} {...rest}>
    {isLoading ? (
      <Spinner />
    ) : (
      <>
        {' '}
        <Text isGoogle={isGoogle}>{label}</Text>
        {isGoogle ? <Img src={googleicon} alt='google icon' /> : <ArrowIcon />}
      </>
    )}
  </Button>
);
