import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWs, setWs } from 'processes/store';
import { deleteWsApi } from 'shared/api';

type PayloadType = {
  ws: Partial<IWs>;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const deleteWsAction = createAsyncThunk<object, PayloadType>('ws/update', async (payload, { dispatch }) => {
  const { ws, errorCallback, successCallback } = payload;
  try {
    const response = await deleteWsApi(ws);

    if (response.status === 200) {
      //@ts-ignore
      dispatch(setWs({ ws: {}, isDelete: true }));
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
