import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RestorePasswordPage } from 'pages/Auth/RestorePasswordPage';
import { RegistrationPage } from 'pages/Auth/RegistrationPage';
import { LoginSocialPage } from 'pages/Auth/LoginSocialPage';
import { SettingsWorkPlace } from 'pages/SettingsWorkPlace';
import { SettingsUsersPage } from 'pages/SettingsUsersPage';
import { PersonalDataPage } from 'pages/PersonalDataPage';
import { LogoutPage } from 'pages/Auth/LogoutPage';
import { SettingsMain } from 'pages/SettingsMain';
import { ContactsPage } from 'pages/ContactsPage';
import { LoginPage } from 'pages/Auth/LoginPage';
import { ContactPage } from 'pages/ContactPage';
import { GroupPage } from 'pages/GroupPage';
import { MainPage } from 'pages/MainPage';
import { EmailsPage } from 'pages/emails';
import { NotFoundPage } from 'pages/404';
import { EmailPage } from 'pages/email';
import { Page403 } from 'pages/403';
import { useView, useWs } from 'shared/hooks';
import {
  ROUTE_LOGIN,
  ROUTE_REGISTRATION,
  ROUTE_RESTORE_PASSWORD,
  ROUTE_ROOT,
  ROUTE_WS_SETTINGS,
  ROUTE_PERSONAL_DATA_SETTINGS,
  ROUTE_USERS_SETTINGS,
  ROUTE_403,
  ROUTE_SETTINGS,
  LOGOUT,
  ROUTE_CONTACTS,
  ROUTE_CONTACT,
  ROUTE_GROUP,
  ROUTE_EMAILS,
  ROUTE_EMAIL,
  ROUTE_LOGIN_SOCIAL,
  ROUTE_TOUCHES,
} from 'shared/lib/routes';
import './styles.scss';
import { TouchesPage } from 'pages/touches';

const App: React.FC = () => {
  useView();
  useWs();

  return (
    <Routes>
      <Route path={ROUTE_LOGIN} element={<LoginPage />} />
      <Route path={ROUTE_REGISTRATION} element={<RegistrationPage />} />
      <Route path={ROUTE_RESTORE_PASSWORD} element={<RestorePasswordPage />} />
      <Route path={ROUTE_ROOT} element={<MainPage />} />
      <Route path={ROUTE_TOUCHES} element={<TouchesPage />} />
      <Route path={ROUTE_CONTACTS} element={<ContactsPage />} />
      <Route path={ROUTE_GROUP} element={<GroupPage />} />
      <Route path={ROUTE_EMAILS} element={<EmailsPage />} />
      <Route path={ROUTE_EMAIL} element={<EmailPage />} />
      <Route path={ROUTE_CONTACT} element={<ContactPage />} />
      <Route path={ROUTE_LOGIN_SOCIAL} element={<LoginSocialPage />} />
      <Route path={ROUTE_SETTINGS} element={<SettingsMain />} />
      <Route path={ROUTE_WS_SETTINGS} element={<SettingsWorkPlace />} />
      <Route path={ROUTE_PERSONAL_DATA_SETTINGS} element={<PersonalDataPage />} />
      <Route path={ROUTE_USERS_SETTINGS} element={<SettingsUsersPage />} />
      <Route path={LOGOUT} element={<LogoutPage />} />
      <Route path={ROUTE_403} element={<Page403 />} />
      <Route path='/' element={<Navigate replace to={ROUTE_ROOT} />} />
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
