import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--color-auth-form);
  padding: 30px 65px;
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px;
`;

export const Text = styled.div`
  margin: 25px 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: var(--color-mybase-gray-text);
`;

export const LinkWrapper = styled.div`
  margin: 25px 0;
`;

export const InputWrapper = styled.div`
  margin-bottom: 35px;
`;
