import React from 'react';
import { RequestCode } from 'features/Auth/RestorePass/RequestCode';
import { CheckCode } from 'features/Auth/RestorePass/CheckCode';
import { Finish } from 'features/Auth/RestorePass/Finish';
import { NewPassword } from 'features/Auth/RestorePass/NewPassword';

export enum RestorePasswordRoutes {
  'requestCode' = 'requestCode',
  'checkCode' = 'checkCode',
  'newPassword' = 'newPassword',
  'finish' = 'finish',
}

export const RestorePasswordRoutesRenderers: Record<
  RestorePasswordRoutes,
  React.FC<{
    stateCode?: string;
    stateEmail?: string;
    handleRedirect: (route: RestorePasswordRoutes) => void;
    handleSaveCode?: (code: string) => void;
    handleSaveEmail?: (email: string) => void;
  }>
> = {
  [RestorePasswordRoutes.requestCode]: RequestCode,
  [RestorePasswordRoutes.checkCode]: CheckCode,
  [RestorePasswordRoutes.finish]: Finish,
  [RestorePasswordRoutes.newPassword]: NewPassword,
};
