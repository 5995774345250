import { FieldsType } from './types';

export const normalizeFilters = (data: FieldsType[]) => {
  return data.reduce((res, el) => {
    if (el.condition === 'contains' && !!el.value) {
      if (res[`filter[${el.field}][value][$re]`]) {
        res[`filter[${el.field}][value][$re]`]?.push(el.value);
      } else {
        res[`filter[${el.field}][value][$re]`] = [el.value];
      }
    }
    if (el.condition === 'notContain' && !!el.value) {
      if (res[`filter[${el.field}][value][$notRe]`]) {
        res[`filter[${el.field}][value][$notRe]`].push(el.value);
      } else {
        res[`filter[${el.field}][value][$notRe]`] = [el.value];
      }
    }
    if (el.condition === 'empty') {
      //@ts-ignore
      res[`filter[${el.field}][$exists]`] = false;
    } else if (el.condition === 'notEmpty') {
      //@ts-ignore
      res[`filter[${el.field}][$exists]`] = true;
    }
    return res;
  }, {} as { [key: string]: string[] });
};
