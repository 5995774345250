import { IEmail } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
  subject?: string;
  content?: string;
};

type Response = {
  result: IEmail;
  errors: IError[];
};
export const createEmailApi = ({ wsId, subject = '', content = '' }: Request): AxiosPromise<Response> => {
  return apiInstance.post(`ws/${wsId}/emails`, {
    subject,
    content,
  });
};
