import { IContact } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
  params: Partial<IContact> & { file?: File };
};

type Response = {
  result: IContact;
  errors: IError[];
};
export const addContactApi = ({ wsId, params }: Request): AxiosPromise<Response> => {
  return apiInstance.post(`ws/${wsId}/contacts`, {
    ...params,
  });
};
