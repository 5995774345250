import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  input {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: var(--color-classic-black);
    flex-grow: 1;
  }

  input::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: var(--color-mybase-gray-icon);
  }
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: var(--color-mybase-gray-text);
  margin-bottom: 10px;
`;

export const Line = styled.div<{ $isActive?: boolean; hasError?: boolean }>`
  background: ${props =>
    props.hasError
      ? 'var(--color-error)'
      : props.$isActive
      ? 'var(--color-mybase-blue)'
      : 'var(--color-mybase-gray-icon)'};
  height: 1px;
  margin-top: 10px;
`;

export const Error = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-error);
  margin-top: 20px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-left: 8px;

    &:hover {
      cursor: pointer;
    }
  }
`;
