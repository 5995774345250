import React, { useCallback, useState } from 'react';
import { IGroup } from 'processes/store';
import { CloseIcon, PlusIcon } from 'shared/lib/Icons';
import { ActionButton, ActionButtons, AddInputBtn, FieldWrapper, Group, GroupsList, Label } from './styled';

type Props = {
  label: string;
  name: string;
  icon?: JSX.Element;
  addBtn?: boolean;
  list?: IGroup[];
  isActionButtons?: boolean;
  onClick?: () => void;
  deleteGroup?: (id: string, title: string) => void;
};

export const FieldGroups: React.FC<Props> = ({
  label,
  icon,
  list = [],
  addBtn = true,
  isActionButtons = true,
  onClick = () => {},
  deleteGroup = () => {},
}) => {
  const [showActionButtons, setShowActionButtons] = useState(false);

  const onMove = useCallback((move: boolean) => {
    setShowActionButtons(move);
  }, []);

  return (
    <FieldWrapper>
      <Label onClick={e => e.preventDefault()}>
        <div>
          {icon} {label}
        </div>
        {addBtn && (
          <AddInputBtn onClick={onClick}>
            <PlusIcon />
          </AddInputBtn>
        )}
      </Label>
      <GroupsList>
        {list.map(el => (
          <Group key={el._id} onMouseEnter={() => onMove(true)} onMouseLeave={() => onMove(false)}>
            {el.title}
            {showActionButtons && isActionButtons && (
              <ActionButtons>
                <ActionButton
                  onClick={e => {
                    e.preventDefault();
                    deleteGroup(el._id, el.title);
                  }}
                >
                  <CloseIcon />
                </ActionButton>
              </ActionButtons>
            )}
          </Group>
        ))}
      </GroupsList>
    </FieldWrapper>
  );
};
