import { DefaultButton } from 'shared/ui';
import styled from 'styled-components';

export const Wrapper = styled.div<{ width?: string }>`
  position: relative;
  width: ${({ width }) => (width ? width : 'max-content')};
`;

export const DropdownContent = styled.div`
  background: var(--color-white);
  position: absolute;
  z-index: 99;
  width: max-content;
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  min-width: 200px;
`;

export const ButtonCustom = styled(DefaultButton)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  &:hover {
    background: #e0e3eb;
    border-radius: 4px;
  }
`;

export const Label = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 5px;
`;
