import React from 'react';
import { format } from 'date-fns';
import { IContact, ITouches, IUser } from 'processes/store';
import { EyeIcon, SmsIcon, ThreePointsIcon } from 'shared/lib/Icons';
import {
  Button,
  Content,
  DivElement,
  IconBlock,
  InfoBlock,
  Item,
  LeftBlock,
  RightBlock,
  SettingsButton,
  Title,
} from './styled';

type Props = {
  touch: ITouches;
  user: IUser;
  contact: IContact;
};

export const Touches: React.FC<Props> = ({ touch, user, contact }) => {
  const touchId = decodeURIComponent(new URL(window.location.href).searchParams.get('touch') || '');

  switch (touch.action) {
    case 'emailSent':
      return (
        <Item id={touch._id} active={touchId === touch._id}>
          <DivElement>
            <LeftBlock>
              <IconBlock>
                <SmsIcon />
              </IconBlock>
              <InfoBlock>
                <Title>
                  Отправлено письмо <span>{format(touch._createdOn, 'dd.MM.yyyy HH:mm')}</span>
                </Title>
                <Content>
                  <span>{user.fullName}</span> отправил(а) письмо <span>{contact.title}</span>
                </Content>
              </InfoBlock>
            </LeftBlock>

            <RightBlock>
              <Button icon={<EyeIcon />} />
              <SettingsButton icon={<ThreePointsIcon />} />
            </RightBlock>
          </DivElement>
        </Item>
      );
    case 'emailReceived':
      return (
        <Item>
          <DivElement>
            <LeftBlock>
              <IconBlock>
                <SmsIcon />
              </IconBlock>
              <InfoBlock>
                <Title>
                  Получено письмо <span>{format(touch._createdOn, 'dd.MM.yyyy HH:mm')}</span>
                </Title>
                <Content>
                  <span>{touch.options?._targetCompanies[0] || contact.title}</span> отправил(а) письмо{' '}
                  <span>{user.fullName}</span>
                </Content>
              </InfoBlock>
            </LeftBlock>

            <RightBlock>
              <Button icon={<EyeIcon />} />
              <SettingsButton icon={<ThreePointsIcon />} />
            </RightBlock>
          </DivElement>
        </Item>
      );
    default:
      return <></>;
  }
};
