import { FC, ReactNode, PropsWithChildren, useEffect } from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { Placement } from '@popperjs/core';
import { PopperUpdater } from '../PopperUpdater';
import { useFocusEvent } from 'shared/hooks/useFocusEvent';
import { usePopperDropdown } from 'shared/hooks/usePopperDropDwon';
import { Wrapper } from './styled';

type Props = {
  label: ReactNode;
  offset?: number;
  placement?: Placement;
  deps?: any[];
  delay?: number;
  open?: boolean;
  onChange?: (e: boolean) => void;
};

const Dropdown: FC<PropsWithChildren<Props>> = ({
  label,
  placement,
  offset = 0,
  children,
  deps,
  delay,
  open,
  onChange,
}) => {
  const { onFocus, focused, onBlur } = useFocusEvent();
  const modifiers = usePopperDropdown(0, offset);

  useEffect(() => {
    onChange && onChange(focused);
  }, [focused, onChange]);

  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div ref={ref} onFocus={onFocus} onBlur={onBlur}>
            {label}
          </div>
        )}
      </Reference>
      <Popper modifiers={modifiers} placement={placement}>
        {({ ref, style, update }) => (
          <PopperUpdater update={update} deps={deps} delay={delay}>
            <Wrapper
              className='dropdown-wrapper'
              ref={ref}
              style={style}
              isHidden={open !== undefined ? !open : !focused}
            >
              {children}
            </Wrapper>
          </PopperUpdater>
        )}
      </Popper>
    </Manager>
  );
};

export { Dropdown };
