import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';
import { IWs } from 'processes/store';

type Request = {
  sort?: 'asc' | 'desc';
  page?: number;
  order_by?: string;
  search?: string;
  inArchive?: boolean;
  created?: string;
  limit?: number;
  anchor?: number;
};

type Response = {
  result: {
    list: IWs[];
    total: number | { value: number };
  };
  errors: IError[];
};

export const getWsListApi = ({
  sort,
  page = 1,
  order_by,
  search,
  inArchive,
  limit,
  anchor,
}: Request): AxiosPromise<Response> => {
  const size = limit ? limit : 30;
  const from = page === 1 ? 0 : (page - 1) * 30;

  if (search && search.length > 0) {
    return apiInstance.get(`/ws/search`, {
      params: {
        q: search,
        'pagination[anchor]': anchor ? anchor : 0,
        'pagination[limit]': limit || 30,
        'pagination[size]': size,
        'pagination[from]': from,
        'pagination[order_by]': order_by ? order_by : '_createdOn',
        'pagination[page]': page ? page : 1,
        'pagination[sort]': sort ? sort : 'desc',
        'pagination[anchor_direction]': 'desc',
        'pagination[skip]': '0',
        //isEmptyObject: false,
        //inArchive: inArchive ? inArchive : false,
      },
    });
  }

  return apiInstance.get('/ws', {
    params: {
      'pagination[page]': page ? page : 1,
      'pagination[anchor]': anchor ? anchor : 0,
      'pagination[limit]': limit || 30,
      'pagination[size]': size,
      'pagination[from]': from,
      'pagination[order_by]': order_by ? order_by : '_createdOn',
      'pagination[sort]': sort ? sort : 'desc',
      'pagination[anchor_direction]': 'desc',
      'pagination[skip]': '0',
      //isEmptyObject: false,
      //inArchive: inArchive ? inArchive : false,
    },
  });
};
