import styled from 'styled-components';

export const ModalWrapper = styled.div`
  border-radius: 13px 13px 0 0;
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  min-height: 70px;
  border-bottom: 1px solid #d9dfeb;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
`;

export const ContentBlock = styled.div`
  padding: 35px;
  display: flex;
  column-gap: 25px;
`;

export const Card = styled.div`
  padding: 25px;
  width: 160px;
  height: 172px;
  border: 1px solid #d9dfeb;
  border-radius: 12px;
  cursor: pointer;
  text-align: center;
  div:first-child {
    margin-bottom: 22px;
  }

  &:hover {
    border: 1px solid black;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const HelpText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  max-width: 160px;
  width: max-content;
  margin-top: 5px;
  text-align: center;
`;
