import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AddFileButton, FileBlock, FileCancelButton, FileTitle, FilesList, PercentageBlock } from './styled';
import { CloseIcon, FileIcon, PlusIcon } from 'shared/lib/Icons';
import { Progress } from 'shared/ui';

type Props = {
  percentage?: number;
  list?: File[];
};

export const EmailFilesList: React.FC<Props> = ({ list }) => {
  const [files, setFiles] = useState<File[]>(list || []);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (percentage < 100 && files.length) {
      setTimeout(() => {
        setPercentage(percentage + 1);
      }, 50);
    }
  }, [percentage, files]);

  const hiddenFileInput = useRef(null);

  const handleFile = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        //@ts-ignore
        setFiles([...files, ...e.target.files]);
      }
    },
    [files],
  );

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleDeleteFile = (name: string) => {
    setFiles(prev => prev.filter(el => el.name !== name));
  };

  return (
    <FilesList>
      {!!files?.length &&
        files.map(el => (
          <FileBlock key={el.name}>
            <FileIcon />
            <div>
              <FileTitle>{el.name}</FileTitle>
              <PercentageBlock>
                {percentage < 100 ? (
                  <Progress percentage={percentage} />
                ) : el.size / 1000 < 1024 ? (
                  `${(el.size / 1000).toFixed(2)} кб.`
                ) : (
                  `${(el.size / 1024000).toFixed(2)} мб.`
                )}
              </PercentageBlock>
            </div>
            <FileCancelButton type='button' onClick={() => handleDeleteFile(el.name)}>
              <CloseIcon />
            </FileCancelButton>
          </FileBlock>
        ))}
      {files.length < 2 && (
        <AddFileButton type='button' onClick={handleClick}>
          <PlusIcon />
        </AddFileButton>
      )}
      <input ref={hiddenFileInput} onChange={handleFile} type='file' style={{ display: 'none' }} />
    </FilesList>
  );
};
