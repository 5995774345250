import React from 'react';
import { Link } from './styled';

type Props = {
  text: string;
  to: string;
};

export const AuthLink: React.FC<Props> = ({ text, to }) => {
  return <Link to={to}>{text}</Link>;
};
