import { AxiosPromise } from 'axios';
import { ITags } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  color: string;
  title: string;
  description?: string;
};

type Response = {
  result: { list: ITags[]; total: number };
  errors: IError[];
};

export const addContactsTagsApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.post(`ws/${params.wsId}/contacts/tags`, {
    color: params.color,
    title: params.title,
  });
};
