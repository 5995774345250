import { alignCenter, alignMiddle, flexCol, flexFull, flexRow, spaceBetween } from 'shared/lib/mixins';
import { Select } from 'shared/ui';
import styled from 'styled-components';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
`;

export const Header = styled.header`
  padding: 0 35px;
  column-gap: 10px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const Title = styled.h1`
  font-size: 19px;
  line-height: 20px;
  font-family: var(--font-medium);
`;

export const ContentWrapper = styled.div`
  ${flexCol};
  ${flexFull};
  ${alignCenter};
  padding: 50px 0 0;
`;

export const Content = styled.div`
  width: 800px;
`;

export const WorkPlaceEdit = styled.div`
  ${flexCol};
  row-gap: 30px;
`;

export const EditSection = styled.div`
  ${flexCol};
  row-gap: 25px;
`;

export const Label = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-label);
`;

export const EditBlock = styled.div`
  padding: 25px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 10px;
`;

export const WorkPlaceFields = styled.div`
  ${flexCol};
  row-gap: 25px;
`;

export const WorkPlaceTop = styled.div`
  ${flexRow};
  ${spaceBetween};
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const EditLogo = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 20px;
`;

export const LogoPreview = styled.div`
  width: 58px;
  height: 58px;
  flex: 0 0 58px;
  ${flexRow};
  ${alignMiddle};
  border: 1px solid var(--color-gray-dark);
  border-radius: 50%;
  svg {
    fill: var(--color-gray-text);
  }
`;

export const LogoLabel = styled.span`
  font-size: 19px;
  font-family: var(--font-medium);
`;

export const LoadLogo = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 15px;
`;

export const LoadLogoLabel = styled.span`
  font-size: 12px;
  line-height: 19px;
  text-align: right;
  color: var(--color-gray-label);
  width: 150px;
`;

export const EditItems = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  column-gap: 25px;
`;

export const EditItem = styled.div`
  width: 100%;
  color: var(--color-classic-black);

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  input {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    margin-top: 10px;
    height: 52px;
  }
`;

export const EditEmail = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 28px 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;

  div:first-child {
    margin-top: 6px;
  }

  div:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-blue);
  }
`;

export const SelectCustom = styled(Select)`
  margin-top: 10px;
  max-height: 52px;

  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;

  input {
    height: auto;
  }

  .css-13cymwt-control {
    height: 52px;
    border: 1px solid var(--color-gray-dark);
    border-radius: 6px;
  }
`;
