import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNeedUpdateContact } from 'processes/store';
import { addContactsInGroupApi } from 'shared/api/contacts/addContactsInGroupApi';

type PayloadType = {
  wsId: string;
  ids?: string[];
  groupId?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const addContactsInGroupAction = createAsyncThunk<object, PayloadType>(
  'addcontactsin/group',
  async (payload, { dispatch }) => {
    const { wsId, ids, groupId, errorCallback, successCallback } = payload;
    try {
      const response = await addContactsInGroupApi({ wsId, ids, groupId });

      if (response.status === 200) {
        dispatch(setNeedUpdateContact(true));
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
