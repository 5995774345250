import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { IContact, IStatus, ITags } from 'processes/store';
import { GroupContactsUser } from './GroupContactsUser';
import { SortBlock } from '../ui/SortBlock';
import { DivWrapper, HeaderBlock, TableWrapper, TrHeadTable } from './styled';

type Props = {
  contactList: IContact[];
  statuses?: IStatus[];
  tags?: ITags[];
  order_by?: string | null;
  sort?: 'asc' | 'desc' | null;
  setSort?: (e?: 'asc' | 'desc' | null) => void;
  setOrder_by?: (e?: string | null) => void;
  setIsNeedUpdate?: (e: boolean) => void;
};

export const GroupContacts: React.FC<Props> = ({
  contactList,
  statuses,
  tags,
  sort,
  order_by,
  setSort,
  setOrder_by,
  setIsNeedUpdate,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Пользователь',
        accessor: 'user' as const,
        sortType: 'string',
      },
      {
        Header: 'Компания',
        accessor: 'companies' as const,
      },
      {
        Header: 'Емаил',
        accessor: 'email' as const,
      },
      {
        Header: 'Cтатус',
        accessor: 'status' as const,
      },
      {
        Header: 'Тег',
        accessor: 'tags' as const,
      },
      {
        Header: 'Телефон',
        accessor: 'phone' as const,
      },
      {
        Header: 'Должность',
        accessor: 'role' as const,
      },
    ],
    [],
  );

  const tableData = contactList?.map(el => ({
    user: { title: el.title || el.organizationTitle || '', avatar: el.imageSrc, id: el._id },
    companies: el.companies[0]?.value || '',
    email: el.emails[0]?.value || '',
    status: '',
    tags: '',
    phone: el.phones[0]?.value || '',
    role: el.roles[0]?.value,
    groups: el,
  }));

  const tableInstance = useTable({ columns, data: tableData });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const handleClickSort = (sort?: 'asc' | 'desc', order_by?: string) => {
    setSort && setSort(sort);
    setOrder_by && setOrder_by(order_by);
  };

  return (
    <DivWrapper>
      <TableWrapper {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <TrHeadTable {...headerGroup.getHeaderGroupProps()} style={{ zIndex: contactList.length + 1 }}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.id === 'user' && (
                    <HeaderBlock>
                      <div>{column.render('Header')}</div>
                      <SortBlock sort={order_by === 'title' ? sort : null} order_by='title' onClick={handleClickSort} />
                    </HeaderBlock>
                  )}
                  {column.id === 'companies' && (
                    <HeaderBlock>
                      <div>{column.render('Header')}</div>
                      <SortBlock
                        sort={order_by === 'companies' ? sort : null}
                        order_by='companies'
                        onClick={handleClickSort}
                      />
                    </HeaderBlock>
                  )}
                  {column.id === 'email' && (
                    <HeaderBlock>
                      <div>{column.render('Header')}</div>
                      <SortBlock
                        sort={order_by === 'emails' ? sort : null}
                        order_by='emails'
                        onClick={handleClickSort}
                      />
                    </HeaderBlock>
                  )}
                  {column.id === 'role' && (
                    <HeaderBlock>
                      <div>{column.render('Header')}</div>
                      <SortBlock sort={order_by === 'roles' ? sort : null} order_by='roles' onClick={handleClickSort} />
                    </HeaderBlock>
                  )}
                  {column.id !== 'user' &&
                    column.id !== 'companies' &&
                    column.id !== 'email' &&
                    column.id !== 'role' &&
                    column.render('Header')}
                </th>
              ))}
            </TrHeadTable>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <GroupContactsUser
                row={row}
                key={index}
                zIndex={rows.length - index}
                contact={contactList.filter(({ _id }) => _id.includes(row.values.user.id))[0]}
                statuses={statuses}
                tags={tags}
                setIsNeedUpdate={setIsNeedUpdate}
              />
            );
          })}
        </tbody>
      </TableWrapper>
    </DivWrapper>
  );
};
