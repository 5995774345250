import { useEffect, useState } from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { PasswordIce } from 'shared/lib/Icons';
import { Wrapper, Label, Line, Error, InputWrapper } from './styled';

type InputProps = {
  label: string;
  name: string;
  placeholder?: string;
  hasError?: boolean;
  textError?: string;
  rules?: RegisterOptions<any, string> | undefined;
  isFocus?: boolean;
  isPassword?: boolean;
  className?: string;
  register: UseFormRegister<any>;
};

export const AuthInput: React.FC<InputProps> = ({
  label,
  name,
  hasError,
  placeholder,
  className,
  isFocus,
  textError,
  rules,
  isPassword,
  register,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('text');

  useEffect(() => {
    if (isOpen && isPassword) {
      setType('text');
    } else if (isPassword && !isOpen) {
      setType('password');
    }
  }, [isOpen, isPassword]);

  return (
    <Wrapper className={className}>
      <Label>{label}</Label>
      <InputWrapper>
        <input {...register(name, { ...rules })} placeholder={placeholder} type={type} />
        {isPassword && (
          <div onClick={() => setIsOpen(state => !state)}>
            <PasswordIce isActive={isOpen} />
          </div>
        )}
      </InputWrapper>
      <Line hasError={hasError} $isActive={isFocus} />
      {textError && <Error>{textError}</Error>}
    </Wrapper>
  );
};
