import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: auto;
  width: 100vw;
  height: 100vh;
  background: var(--color-auth-background);
`;

export const Content = styled.div`
  margin: 80px auto;
  max-width: 900px;
`;
