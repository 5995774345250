import React from 'react';

export const NotConnectionPage: React.FC = () => {
  // const { onCloseModal } = useModal();

  /*useEffect(() => {
    onCloseModal();
  }, [onCloseModal]);*/

  return (
    <div>
      <div>Не удалось подключится к MyBase</div>
      <span>Проверьте интернет соединение и повторите попытку</span>
      <button>Повторить</button>
    </div>
  );
};
