import { DefaultButton, InputLight, Select } from 'shared/ui';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  max-height: 100vh;
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;

  min-height: 70px;
  border-bottom: 1px solid var(--color-gray-dark);
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const SelectGroups = styled(Select)`
  margin: 0 33px 0 20px;
  width: 360px;
`;

export const TabsWrapper = styled.div`
  padding: 24px 35px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 58px;
  height: 58px;
  margin-right: 10px;
  padding: 19px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-dark);
  border-radius: 29px;
`;

export const DivWrapper = styled.div<{ flex?: boolean; column?: boolean }>`
  padding: 25px 45px;
  display: ${({ flex }) => (flex ? 'flex' : 'block')};
  border-bottom: 1px solid var(--color-gray-dark);

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-mybase-gray-text);
    display: flex;
    align-items: center;
    column-gap: 10px;
    position: relative;

    button:last-child {
      background: none;
      border: none;
      padding: 5px;
      margin: 0;
      position: absolute;
      right: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--color-gray-text);
      cursor: pointer;
    }
  }

  input {
    border: none;
    font-weight: 500;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* color: var(--color-classic-black); */
    padding: 0 10px;
    height: 44px;
  }
  input::placeholder {
    color: var(--color-mybase-gray-icon);
    border: none;
    font-weight: 500;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    padding: 0 10px;
  }
`;

export const Input = styled(InputLight)`
  width: 100%;
  input {
    border: none;
    font-weight: 500;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-classic-black);
    padding: 0 10px;
  }
  input {
    padding: 0;
  }
  input[name='fullName'] {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
  input[name='fullName']::placeholder {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
`;

export const ScrollWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 265px);
`;

export const BottomBlock = styled.div`
  border-top: 1px solid var(--color-gray-dark);
  padding: 25px 35px;
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;

  button:first-child {
    padding: 15px 20px 13px;
    border: 1px solid var(--color-gray-dark);
    border-radius: 6px;
    div {
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: var(--color-gray-text);
    }
    background: none;

    &:hover {
      border: 1px solid #7b8393;
      div {
        color: #7b8393;
      }
    }
  }
  button:last-child {
    padding: 15px 20px 13px;
    width: 197px;
  }
`;

export const SelectGender = styled(Select)`
  width: 100%;

  .css-13cymwt-control {
    border: none;
    margin-right: -8px;
  }
`;

export const AddInputBtn = styled(DefaultButton)`
  cursor: pointer;
  max-height: 16px;
`;

export const Error = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-error);
  margin-top: 20px;
`;
