import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { hideModal, InitialModalState, ModalType, selectModal, setModal, useAppDispatch } from 'processes/store';

export const useModal = () => {
  const dispatch = useAppDispatch();
  const { active, current, params } = useSelector(selectModal);

  const onCloseModal = useCallback(() => dispatch(hideModal()), [dispatch]);
  const showModal = useCallback(
    (modal: ModalType, params?: InitialModalState['params']) => {
      dispatch(setModal({ active: true, current: modal, params }));
    },
    [dispatch],
  );

  return {
    current,
    active,
    params,
    onCloseModal,
    showModal,
  };
};
