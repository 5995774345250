import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCountByGroupsApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  successCallback?: (e: { [key: string]: number }) => void;
  errorCallback?: (err?: unknown) => void;
};

export const getCountByGroupsAction = createAsyncThunk<object, PayloadType>('get/countGroups', async payload => {
  const { wsId, successCallback, errorCallback } = payload;

  try {
    const response = await getCountByGroupsApi({ wsId });
    if (response.status === 200) {
      successCallback && successCallback(response.data.result);
    }
  } catch (err) {
    errorCallback && errorCallback(err);
  }
});
