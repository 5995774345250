import { createAsyncThunk } from '@reduxjs/toolkit';
import { IStatus, IWs } from 'processes/store';
import { getContactsStatusesApi } from 'shared/api/contacts';

type PayloadType = {
  wsId: IWs['_id'];
  limit?: number;
  sort?: 'asc' | 'desc' | null;
  order_by?: string | null;
  page: number;
  _groupId?: string;
  successCallback?: (list?: IStatus[]) => void;
  errorCallback?: () => void;
};

export const getContactsStatusesAction = createAsyncThunk<object, PayloadType>(
  'get/contactsStatuses',
  async (payload, { dispatch }) => {
    const { wsId, page, limit, sort, order_by, _groupId, errorCallback, successCallback } = payload;
    try {
      const response = await getContactsStatusesApi({ wsId, page, limit, sort, order_by, _groupId });

      if (response.status === 200) {
        // const { list, total } = response.data.result;
        successCallback && successCallback(response.data.result.list);
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
