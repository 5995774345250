import styled from 'styled-components';
import { flexRow, alignMiddle } from 'shared/lib/mixins';

export const Block = styled.div`
  ${flexRow};
  ${alignMiddle};
  color: var(--color-white);
  font-family: var(--font-bold);
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  border-radius: 50%;
  font-size: 14px;
  text-transform: uppercase;
  background: linear-gradient(135deg, #002fa4 0%, #295dff 45.83%, #babff8 100%);
`;
