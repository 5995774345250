import React, { useRef, useState, useCallback, ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Authorized } from 'processes/Authorized';
import { SettingsSpace, CoverDefault } from 'shared/lib/Icons';
import { Button, MainTemplate, SidebarSettings } from 'shared/ui';
import { useDebounce } from 'shared/hooks/useDebounce';
import { ROUTE_ROOT } from 'shared/lib/routes';
import { useModal } from 'shared/hooks/useModal';
import { IWs, ModalType, selectWs, useAppDispatch } from 'processes/store';
import { deleteWsAction, updateWsAction } from './model';
import {
  Header,
  Title,
  ContentWrapper,
  Content,
  WorkPlaceEdit,
  Label,
  EditSection,
  EditBlock,
  WorkPlaceTop,
  WorkPlaceFields,
  WorkPlaceTitle,
  WorkPlaceTitleLabel,
  EditLogo,
  LogoLabel,
  LoadLogo,
  LoadLogoLabel,
  FreeRates,
  WorkPlaceDelete,
  DeleteLabel,
  LogoPreview,
  Containter,
} from './styled';

export const SettingsWorkPlace: React.FC = () => {
  const [file, setFile] = useState<File>();
  const [name, setName] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const hiddenFileInput = useRef(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showModal, onCloseModal } = useModal();

  const { ws, wsList } = useSelector(selectWs);

  useEffect(() => {
    setName(ws.title);
  }, [ws.title]);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  const debounceInput = useDebounce(name, 500);

  useEffect(() => {
    if (debounceInput && debounceInput !== ws.title && debounceInput.length > 0) {
      isFocused && dispatch(updateWsAction({ ws: { title: debounceInput, _id: ws._id } }));
    }
  }, [debounceInput, ws, dispatch, isFocused]);

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  const handleChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    setName(evt.target.value);
  }, []);

  const handleDelete = useCallback(
    (ws: IWs) => () => {
      //не удаляем если осталось только одно рп
      if (wsList.length === 1) {
        return;
      }
      showModal(ModalType.DeleteElement, {
        title: 'рабочее простронство',
        onClick: () =>
          dispatch(
            deleteWsAction({
              ws,
              successCallback: () => {
                onCloseModal();
                navigate(ROUTE_ROOT);
              },
            }),
          ),
      });
    },
    [dispatch, navigate, showModal, onCloseModal, wsList],
  );

  return (
    <Authorized>
      <MainTemplate customSideBar={<SidebarSettings />}>
        <Containter>
          <Header>
            <SettingsSpace />
            <Title>Настройки пространства</Title>
          </Header>
          <ContentWrapper>
            <Content>
              <WorkPlaceEdit>
                <EditSection>
                  <Label>Пространство</Label>
                  <EditBlock>
                    <WorkPlaceFields>
                      <WorkPlaceTop>
                        <EditLogo>
                          <LogoPreview>
                            <CoverDefault />
                          </LogoPreview>
                          <LogoLabel>Логотип пространства</LogoLabel>
                        </EditLogo>
                        <LoadLogo>
                          <LoadLogoLabel>Мы поддерживаем PNG, JPEG и GIF до 10 МБ</LoadLogoLabel>
                          <Button label='Загрузить' onClick={handleClick} />
                          <input ref={hiddenFileInput} onChange={handleFile} type='file' style={{ display: 'none' }} />
                        </LoadLogo>
                      </WorkPlaceTop>
                      <WorkPlaceTitle>
                        <WorkPlaceTitleLabel>Название пространства</WorkPlaceTitleLabel>
                        <input type='text' value={name} onChange={handleChange} onFocus={onFocus} onBlur={onBlur} />
                      </WorkPlaceTitle>
                    </WorkPlaceFields>
                  </EditBlock>
                </EditSection>
                <EditSection>
                  <Label>Тариф</Label>
                  <EditBlock>
                    <FreeRates>Сейчас все тарифы бесплатные</FreeRates>
                  </EditBlock>
                </EditSection>
                <EditSection>
                  <Label>Удаление</Label>
                  <EditBlock>
                    <WorkPlaceDelete>
                      <DeleteLabel>
                        Если вы хотите навсегда удалить это пространство и все данные в ней, нажмите «Удалить рабочую
                        область» ниже.
                      </DeleteLabel>
                      <Button label='Удалить пространство' isWarning={true} onClick={handleDelete(ws)} />
                    </WorkPlaceDelete>
                  </EditBlock>
                </EditSection>
              </WorkPlaceEdit>
            </Content>
          </ContentWrapper>
        </Containter>
      </MainTemplate>
    </Authorized>
  );
};
