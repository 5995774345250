import { AxiosPromise } from 'axios';
import { IError, apiInstance } from '../base';
import { IGroup } from 'processes/store';

type Request = {
  wsId: string;
  title: string;
  collectionName: string;
};

type Response = {
  result: IGroup;
  error: IError[];
};

export const createGroupApi = ({ wsId, title, collectionName }: Request): AxiosPromise<Response> => {
  return apiInstance.post(`ws/${wsId}/contacts/groups`, {
    title,
    collectionName,
  });
};
