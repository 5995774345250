import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWs } from 'processes/store';
import { getWsListApi, createWsApi } from 'shared/api';
import { RequestState } from 'shared/lib/types';

const initialState = {
  wsList: [] as IWs[],
  ws: {} as IWs,
  status: RequestState.PENDING,
};

type PayloadType = {
  ws: Partial<IWs>;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const createWsAction = createAsyncThunk<object, PayloadType>('ws/update', async (payload, { dispatch }) => {
  const { ws, errorCallback, successCallback } = payload;
  try {
    const response = await createWsApi(ws);

    if (response.status === 200) {
      dispatch(setWs({ ws: response.data.result, isCreate: true }));
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});

export const getWsListAction = createAsyncThunk<object, { wId?: string; successCallback?: (id?: string) => void }>(
  'getWsListAction',
  async (payload, { dispatch }) => {
    try {
      const { wId, successCallback } = payload;
      const response = await getWsListApi({ page: 1, limit: 100 });

      if (response.status === 200) {
        dispatch(
          setWsList({
            wsList: response.data.result.list,
            wId,
          }),
        );
        let ws;
        if (wId) {
          ws = response.data.result.list.find(el => el._id === wId);
        }
        if (!ws) {
          ws = response.data.result.list[0];
          ws && successCallback && successCallback(ws?._id);
          localStorage.setItem('wsId', ws?._id);
        }
      }
    } catch (err) {
      console.log('Rejected', err);
    }
  },
);

const wsSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setWs: (state, action: PayloadAction<{ ws: IWs; isUpdate?: boolean; isDelete?: boolean; isCreate?: boolean }>) => {
      if (action.payload.isUpdate) {
        state.ws = { ...state.ws, ...action.payload.ws };
        const index = state.wsList.findIndex(el => el._id === state.ws._id);
        if (index) {
          state.wsList = [
            ...state.wsList.slice(0, index),
            { ...state.wsList[index], ...action.payload.ws },
            ...state.wsList.slice(index + 1),
          ];
        }
      }
      if (action.payload.isDelete) {
        state.wsList = state.wsList.filter(el => el._id !== state.ws._id);
        state.ws = state.wsList[0];
      }
      if (action.payload.isCreate) {
        state.ws = { ...action.payload.ws };
        state.wsList = [{ ...action.payload.ws }, ...state.wsList];
      } else {
        state.ws = { ...action.payload.ws };
      }
    },
    setWsList: (state, action: PayloadAction<{ wsList: IWs[]; wId?: string }>) => {
      let ws;
      if (action.payload.wId) {
        ws = action.payload.wsList.find(el => el._id === action.payload.wId);
      }
      if (!ws) {
        ws = action.payload.wsList[0];
      }
      state.ws = ws;
      state.wsList = action.payload.wsList;
    },
    changeWs: (state, action: PayloadAction<{ wId: string }>) => {
      const ws = state.wsList.find(el => el._id === action.payload.wId);

      if (ws) {
        state.ws = ws;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getWsListAction.pending, state => {
        state.status = RequestState.LOADING;
      })
      .addCase(getWsListAction.fulfilled, (state, action) => {
        state.status = RequestState.SUCCESS;
      })
      .addCase(getWsListAction.rejected, state => {
        state.status = RequestState.SUCCESS;
      });
  },
});

export const { setWs, setWsList, changeWs } = wsSlice.actions;

export default wsSlice.reducer;
