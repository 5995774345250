import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNeedUpdateContact } from 'processes/store';
import { addContactInGroupApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  groupId: string[];
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const addContactInGroupAction = createAsyncThunk<object, PayloadType>(
  'addcontactin/group',
  async (payload, { dispatch }) => {
    const { wsId, id, groupId, errorCallback, successCallback } = payload;
    try {
      const response = await addContactInGroupApi({ wsId, id, groupId });

      if (response.status === 200) {
        dispatch(setNeedUpdateContact(true));
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
