import { RequestState } from 'shared/lib/types';
import { IContact, TableContactsType } from '../types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  contactList: IContact[];
  contactListTable: TableContactsType[];
  contact: IContact;
  status: RequestState;
  needUpdateContact?: boolean;
  total: number;
};

const initialState: initialStateType = {
  contactList: [],
  contactListTable: [],
  contact: {} as IContact,
  status: RequestState.PENDING,
  needUpdateContact: false,
  total: 0,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setContact: (state, action: PayloadAction<{ contact: IContact }>) => {
      state.contact = action.payload.contact;
      state.status = RequestState.SUCCESS;
    },
    setContactList: (
      state,
      action: PayloadAction<{ contactList?: IContact[]; total?: number; contact?: IContact }>,
    ) => {
      const { contactList } = action.payload;
      const tableData = contactList?.map(el => ({
        contact: { title: el?.title || el?.organizationTitle || '', avatar: el?.imageSrc, id: el?._id },
        companies: el?.companies?.filter(({ isFavorites }) => isFavorites).length
          ? el?.companies?.filter(({ isFavorites }) => isFavorites)[0]?.value
          : el?.companies[0]?.value || '',
        email: el?.emails?.filter(({ isFavorites }) => isFavorites)[0]?.value || '',
        phone: el?.phones?.filter(({ isFavorites }) => isFavorites)[0]?.value || '',
        groups: el,
      }));

      state.contactList = action.payload.contactList || state.contactList;
      state.contactListTable = tableData?.length ? tableData : [];
      state.total = action.payload.total || 0;
      state.status = RequestState.SUCCESS;
    },
    setNeedUpdateContact: (state, action: PayloadAction<boolean>) => {
      state.needUpdateContact = action.payload;
    },
    setStatusContact: (state, action: PayloadAction<RequestState>) => {
      state.status = action.payload;
    },
  },
});

export const { setContactList, setContact, setNeedUpdateContact, setStatusContact } = contactSlice.actions;

export default contactSlice.reducer;
