import React, { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { CopyIcon, FavoriteIcon, ThreePointsIcon } from 'shared/lib/Icons';
import { copyText } from 'shared/lib/copyText';
import { MaskedInput, SettingsDropdown } from 'shared/ui';
import { ActionButton, ActionButtons, FavoriteBtn, Input, InputBlock, Pin, Text } from './styled';

type Props = {
  field?: {
    id: string;
    value: string;
    isFavorites?: boolean;
  };
  name: string;
  isPin?: boolean;
  index?: number;
  $isEmpty?: boolean;
  isCopy?: boolean;
  placeholder?: string;
  isActionButtons?: boolean;
  register: UseFormRegister<any>;
  onBlur?: () => void;
  onFavorite?: () => void;
  onDelete?: () => void;
  delCode?: (e: any) => void;
  handleTelephone?: (e: string, index: number) => void;
};

export const InputField: React.FC<Props> = ({
  field,
  name,
  isPin,
  index,
  $isEmpty,
  isCopy,
  placeholder,
  isActionButtons,
  register,
  onBlur,
  onFavorite,
  onDelete,
  delCode,
  handleTelephone,
}) => {
  const [visible, setVisible] = useState(false);

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, text: string) => {
    e.preventDefault();
    copyText(text);
  };

  const handleFavorite = () => {
    setVisible(false);
    onFavorite && onFavorite();
  };

  const handleDelete = () => {
    setVisible(false);
    onDelete && onDelete();
  };

  return (
    <InputBlock visible={visible} key={field?.id}>
      {isPin && field?.value && <Pin>{field.value[0]}</Pin>}
      {name === `phones.${index}.value` ? (
        <MaskedInput
          mask={'+{7}(000)000 00 00'}
          onBlur={delCode}
          onAccept={(e: string) => handleTelephone && handleTelephone(e, index ? index : 0)}
          lazy={false}
          onChange={() => {}}
          $isEmpty={$isEmpty}
          //@ts-ignore
          value={field.value}
        />
      ) : (
        <Input isPin={isPin} placeholder={placeholder} name={name} register={register} rules={{ onBlur }} />
      )}
      {field?.isFavorites && isActionButtons && (
        <FavoriteBtn>
          <FavoriteIcon />
        </FavoriteBtn>
      )}
      {isActionButtons && (
        <ActionButtons>
          {isCopy && (
            <ActionButton onClick={e => handleCopy(e, field?.value || '')}>
              <CopyIcon />
            </ActionButton>
          )}
          <SettingsDropdown
            open={visible}
            setOpen={setVisible}
            labelIcon={<ThreePointsIcon />}
            children={
              <>
                {onFavorite && (
                  <Text border onClick={handleFavorite}>
                    Выбрать по умолчанию
                  </Text>
                )}
                {onDelete && <Text onClick={handleDelete}>Удалить</Text>}
              </>
            }
          />
        </ActionButtons>
      )}
    </InputBlock>
  );
};
