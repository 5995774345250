import styled from 'styled-components';
import { UserTag } from 'shared/ui/UserTag';
import { Link } from 'react-router-dom';
import { flexCol, flexRow, alignCenter, flexFull, selfStart, alignMiddle } from 'shared/lib/mixins';

export const Wrapper = styled.aside`
  transition: width 200ms;
  width: 300px;
  ${flexCol};
  background-color: var(--color-gray-light);
  border-right: 1px solid var(--color-gray-dark);
`;

export const Header = styled.div`
  flex: 0 0 70px;
  padding-left: 25px;
  border-bottom: 1px solid var(--color-gray-dark);
  column-gap: 13px;
  font-size: 14px;
  ${flexRow};
  ${alignCenter};
  cursor: pointer;
  &:hover {
    background-color: var(--color-gray-hv);
  }
`;

export const Content = styled.div`
  ${flexCol};
  ${flexFull};
  padding: 23px 0 12px;
  overflow: auto;
`;

export const Navigation = styled.div`
  ${flexCol};
  ${flexFull};
  row-gap: 25px;
`;

export const NavSection = styled.div`
  ${flexCol};
`;

export const NavItem = styled(Link)<{ $isActive?: boolean }>`
  ${flexRow};
  ${alignCenter};
  column-gap: 10px;
  font-size: 14px;
  height: 46px;
  padding: 0 25px;
  transition: background 100ms;
  color: var(--color-black);
  position: relative;
  &:hover {
    background-color: var(--color-gray-hv);
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    width: 2px;
    background-color: var(--color-blue);
    transition: opacity 100ms;
  }
  ${({ $isActive }) =>
    !$isActive &&
    `
    &:not(.isActive):after {
      opacity: 0;
    }
  `}
`;

export const NavIcon = styled.span`
  flex: 0 0 20px;
  width: 20px;
  height: 16px;
  ${flexRow};
  ${alignMiddle};
`;

export const NavLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray-label);
  margin: 0 0 10px 25px;
`;

export const Exit = styled.div`
  ${flexCol};
  flex: 0 0 46px;
`;

export const DataTag = styled(UserTag)`
  margin: 0 0 6px 25px;
  ${selfStart};
`;

export const DataTagName = styled(UserTag)`
  margin: 0 0 29px 25px;
  ${selfStart};
`;
