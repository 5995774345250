import styled from 'styled-components';
import { IconButton } from 'shared/ui';
import { Link } from 'react-router-dom';
import { flexCol, flexRow, alignCenter, alignMiddle, spaceBetween, flexFull } from 'shared/lib/mixins';

export const Header = styled.div<{ $isActive?: boolean }>`
  flex: 0 0 70px;
  padding: 0 12px 0 20px;
  border-bottom: 1px solid var(--color-gray-dark);
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
    width: 2px;
    background-color: var(--color-blue);
    transition: opacity 100ms;
  }
  ${({ $isActive }) =>
    !$isActive &&
    `
    &:not(.isActive):after {
      opacity: 0;
    }
  `}
`;

export const BarToggleButton = styled(IconButton)``;

export const SectionTitle = styled.div`
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
  height: 55px;
  padding: 0 12px 0 22px;
  transition: background 100ms;
  &:hover {
    background-color: var(--color-gray-hv);
  }
`;

export const NavItem = styled(Link)`
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
  height: 46px;
  padding: 0 25px;
  transition: background 100ms;
  color: var(--color-black);
  &:hover {
    background-color: var(--color-gray-hv);
  }
`;

export const GroupsTop = styled.div`
  height: 50px;
  padding: 0 12px 0 25px;
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
`;

export const NavIcon = styled.span`
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  ${flexRow};
  ${alignMiddle};
`;

export const ExitNav = styled(Link)`
  ${flexRow};
  ${alignCenter};
  height: 46px;
  padding: 0 25px;
  transition: background 100ms;
  color: var(--color-black);
  column-gap: 10px;
  font-size: 14px;
  ${NavIcon} {
    flex: 0 0 20px;
    width: 20px;
  }
  &:hover {
    background-color: var(--color-gray-hv);
  }
`;

export const Wrapper = styled.aside<{ toggleView?: boolean }>`
  transition: width 200ms;
  width: ${({ toggleView }) => (!toggleView ? '300px' : '60px')};
  ${flexCol};
  background-color: var(--color-gray-light);
  border-right: 1px solid var(--color-gray-dark);
  overflow: hidden;
  padding-bottom: 12px;
  ${({ toggleView }) =>
    toggleView &&
    `
    ${BarToggleButton} {
      svg {
        transform: scale(-1, 1);
      }
    }
    ${GroupsTop}, ${NavItem}, ${SectionTitle}, ${Header}, ${ExitNav} {
      padding: 0;
      ${alignMiddle};
    }
  `}
`;

export const TitleWrapper = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 10px;
  font-family: var(--font-bold);
  font-size: 24px;
  cursor: pointer;
`;

export const MenuSection = styled.div`
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const SectionTitleWrapper = styled.div`
  ${flexRow};
  ${alignCenter};
  font-size: 17px;
  column-gap: 12px;
  font-family: var(--font-medium);
`;

export const Navigation = styled.div``;

export const NavContent = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 10px;
  font-size: 14px;
`;

export const Amount = styled.span`
  font-size: 11px;
  font-family: var(--font-medium);
  color: var(--color-gray-text);
`;

export const GroupsSection = styled.div`
  margin-top: 10px;
`;

export const GroupsTitle = styled.div`
  ${flexRow};
  ${alignCenter};
  font-size: 17px;
  font-family: var(--font-medium);
  column-gap: 13px;
`;

export const AddGroup = styled(IconButton)`
  &:not(:hover) {
    svg {
      fill: var(--color-blue);
    }
  }
`;

export const GroupsMenu = styled.div``;

export const GroupToggleButton = styled.div<{ $isActive?: boolean }>`
  cursor: pointer;
  svg {
    transform: ${props => (props.$isActive ? 'rotate(180deg)' : 'rotate(0)')};
    transition: 0.3s;
  }
`;

export const ActionGroup = styled(IconButton)``;

export const AccessText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  button {
    max-width: max-content;
  }
`;

export const GroupItem = styled.div`
  cursor: pointer;
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
  height: 46px;
  padding: 0 25px;
  transition: background 100ms;
  &:hover {
    background-color: var(--color-gray-hv);
    /* padding-right: 12px; */
    ${Amount} {
      display: none;
    }
  }
  &:not(:hover) {
    ${AccessText} {
      display: none;
    }
  }
`;

export const GroupItemContent = styled.div`
  font-size: 14px;
  /* white-space: nowrap; */
  width: 100%;
`;

export const Content = styled.div`
  ${flexCol};
  ${flexFull};
`;

export const Exit = styled.div`
  ${flexCol};
  flex: 0 0 46px;
`;

export const LogoPreview = styled.div`
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  ${flexRow};
  ${alignMiddle};
  border: 1px solid var(--color-gray-dark);
  border-radius: 50%;
  svg {
    fill: var(--color-gray-text);
  }
`;

export const DropdownList = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
`;

export const Text = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
`;

export const DeleteButton = styled(IconButton)`
  max-width: max-content;
  max-height: max-content;
  margin-right: 10px;
  svg {
    fill: #ff0000;
  }
`;
