import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IWs, ModalType, selectWs } from 'processes/store';
import { CoverDefault, PlusIcon, SettingsNav } from 'shared/lib/Icons';
import { useModal } from 'shared/hooks/useModal';
import { ROUTE_WS } from 'shared/lib/routes';
import {
  Wrapper,
  Item,
  Divider,
  NewWrapper,
  Text,
  AvatarDefault,
  ItemDescription,
  ItemTitle,
  ItemAccess,
  ItemIcon,
  ItemInfo,
} from './styled';

type Props = {
  ws: IWs;
};

const Ws: React.FC<Props> = ({ ws }) => {
  const navigate = useNavigate();

  return (
    <Item>
      <ItemInfo onClick={() => navigate(`${ROUTE_WS}/${ws._id}`)}>
        <AvatarDefault>
          <CoverDefault width={12} height={10} />
        </AvatarDefault>
        <ItemDescription>
          <ItemTitle>{ws.title}</ItemTitle>
          <ItemAccess>Общий доступ</ItemAccess>
        </ItemDescription>
      </ItemInfo>
      <ItemIcon onClick={() => navigate(`${ROUTE_WS}/${ws._id}/settings/workplace`)}>
        <SettingsNav />
      </ItemIcon>
    </Item>
  );
};

export const WsList: React.FC = () => {
  const { showModal } = useModal();

  const { wsList } = useSelector(selectWs);

  const handleNew = useCallback(() => showModal(ModalType.CreateWs), [showModal]);

  return (
    <Wrapper>
      {wsList.map(el => (
        <Ws ws={el} key={el._id} />
      ))}
      <Divider />
      <NewWrapper onClick={handleNew}>
        <PlusIcon />
        <Text>Новое пространство</Text>
      </NewWrapper>
    </Wrapper>
  );
};
