import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';

type Request = {
  code: string;
};

type Response = {
  result: {};
  errors: IError[];
};

export const confirmEmailCodeApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.post('/user/email/confirm/checkCode', {
    ...params,
  });
};
