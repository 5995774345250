import { AxiosPromise } from 'axios';
import { IStatus } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  page: number;
  limit?: number;
  sort?: 'asc' | 'desc' | null;
  order_by?: string | null;
  _groupId?: string;
};

type Response = {
  result: { list: IStatus[]; total: number };
  errors: IError[];
};

export const getContactsStatusesApi = ({
  wsId,
  page,
  limit,
  sort,
  order_by,
  _groupId,
}: Request): AxiosPromise<Response> => {
  const size = limit || 10;

  return apiInstance.get(`ws/${wsId}/contacts/statuses`, {
    params: {
      'pagination[page]': page ? page : 1,
      'pagination[limit]': limit || 10,
      'pagination[size]': size,
      'pagination[sort]': sort ? sort : null,
      'pagination[order_by]': order_by ? order_by : '_createdOn',
      // inArchive: false,
      // _groupId,
    },
  });
};
