import React, { ChangeEvent, useEffect, useState } from 'react';
import { CloseIcon, SearchIcon } from 'shared/lib/Icons';
import { useDebounce } from 'shared/hooks';
import { IconButton } from 'shared/ui';
import { SearchField, SearchInput, SearchOpenButton } from './styled';

type Props = {
  setSearch: (e: string) => void;
};

export const SearchBlock: React.FC<Props> = ({ setSearch }) => {
  const [toggle, setToggle] = useState(false);
  const [value, setValue] = useState('');

  const debounceInput = useDebounce(value, 500);

  useEffect(() => {
    setSearch(debounceInput);
  }, [debounceInput, setSearch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleClose = () => {
    setSearch('');
    setValue('');
    setToggle(false);
  };

  return toggle ? (
    <SearchField>
      <IconButton icon={<SearchIcon />} />
      <SearchInput autoFocus onChange={handleChange} value={value} placeholder='Искать' />
      <IconButton onClick={handleClose} icon={<CloseIcon />} />
    </SearchField>
  ) : (
    <SearchOpenButton onClick={() => setToggle(true)} icon={<SearchIcon />} />
  );
};

export default SearchBlock;
