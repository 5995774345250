import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNeedUpdateContact, setNeedUpdateGroup } from 'processes/store';
import { importContactsApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  successCallback?: () => void;
  errorCallback?: (err: unknown) => void;
};
export const importContactsGoogleAction = createAsyncThunk<object, PayloadType>(
  'add/contacts',
  async (payload, { dispatch }) => {
    const { wsId, successCallback, errorCallback } = payload;
    try {
      const response = await importContactsApi({ wsId });
      if (response.status === 200) {
        dispatch(setNeedUpdateContact(true));
        dispatch(setNeedUpdateGroup(true));
        successCallback?.();
      }
    } catch (err) {
      //TODO убрать позже
      dispatch(setNeedUpdateGroup(true));
      errorCallback?.(err);
    }
  },
);
