import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import modalReducer from './reducers/modalReducer';
import profileReducer from './reducers/profileReducer';
import userReducer from './reducers/userReducer';
import viewReducer from './reducers/viewReducer';
import wsReducer from './reducers/wsReducer';
import contactReducer from './reducers/contactReducer';
import groupReducer from './reducers/groupReducer';
import touchReducer from './reducers/touchesReducer';

const reducers = combineReducers({
  contact: contactReducer,
  user: userReducer,
  profile: profileReducer,
  view: viewReducer,
  modal: modalReducer,
  group: groupReducer,
  touch: touchReducer,
  ws: wsReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
