import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWs, setWs } from 'processes/store';
import { updateWsApi } from 'shared/api/ws/updateWsApi';

type PayloadType = {
  ws: Partial<IWs>;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const updateWsAction = createAsyncThunk<object, PayloadType>('ws/update', async (payload, { dispatch }) => {
  const { ws, errorCallback, successCallback } = payload;
  try {
    const response = await updateWsApi(ws);

    if (response.status === 200) {
      successCallback && successCallback();
      //@ts-ignore
      dispatch(setWs({ ws, isUpdate: true }));
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
