import { createAsyncThunk } from '@reduxjs/toolkit';
import { logoutProfile, logoutUser } from 'processes/store';
import { logoutApi } from 'shared/api';

export const logoutAction = createAsyncThunk<object>('user/logoutall', async (payload, { dispatch }) => {
  localStorage.removeItem('persist:root');
  localStorage.removeItem('wsId');
  dispatch(logoutUser());
  dispatch(logoutProfile());
  await logoutApi();
});
