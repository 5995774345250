import React from 'react';
import ReactModal from 'react-modal';
import { InitialModalState, ModalType } from 'processes/store';
import { useModal } from 'shared/hooks/useModal';
import { CreateWsModal } from '../CreateWsModal';
import { CreateGroupModal } from '../CreateGroupModal';
import { DeleteElementModal } from '../DeleteElementModal';
import { InviteUserModal } from '../InviteUserModal';
import { AddContactModal } from '../AddContactModal';
import { AddManuallyContactModal } from '../AddManuallyContactModal';
import { ChangeEmailModal } from '../ChangeEmailModal';
import { AddContactInGroupModal } from '../AddContactInGroupModal';
import { PreviewEmailModal } from '../PreviewEmailModal';
import { AvatarModal } from '../AvatarModal';
import { UpdateGroupModal } from '../UpdateGroupModal';
import { AlertModal } from '../AlertModal';

ReactModal.setAppElement('#root');

export type ModalComponentProps = {
  onCloseModal: () => void;
  isShowModal: boolean;
  params?: InitialModalState['params'];
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    overflow: 'inherit',
    padding: 0,
    borderRadius: '16px',
    boxShadow: '0px 0px 40px rgba(10, 30, 64, 0.1)',
  },
};

const modalRenderers: Record<ModalType, React.FC<ModalComponentProps>> = {
  [ModalType.CreateWs]: CreateWsModal,
  [ModalType.DeleteElement]: DeleteElementModal,
  [ModalType.CreateGroup]: CreateGroupModal,
  [ModalType.UpdateGroup]: UpdateGroupModal,
  [ModalType.AlertModal]: AlertModal,
  [ModalType.InviteUser]: InviteUserModal,
  [ModalType.AddContact]: AddContactModal,
  [ModalType.AddManuallyContact]: AddManuallyContactModal,
  [ModalType.ChangeEmail]: ChangeEmailModal,
  [ModalType.AddContactInGroup]: AddContactInGroupModal,
  [ModalType.PreviewModal]: PreviewEmailModal,
  [ModalType.AvatarModal]: AvatarModal,
};

interface IProps {}

export const Modal: React.FC<IProps> = () => {
  const { current, active, onCloseModal, params } = useModal();

  if (!active || !current) {
    return null;
  }

  /*seEffect(() => {
    if (!active || !current) {
      return null;
    }

    //disablePageScroll();
    //return () => enablePageScroll();
  }, [active, current]);*/

  return (
    <ReactModal
      isOpen={active}
      style={
        params?.alert
          ? {
              content: {
                top: '85%',
                left: '83%',
                right: 'auto',
                bottom: 'auto',
                background: '#363F47',
                color: 'white',
                padding: '8px 10px',
              },
              overlay: { backgroundColor: 'transparent', position: 'inherit' },
            }
          : customStyles
      }
      shouldCloseOnOverlayClick
      onRequestClose={onCloseModal}
      preventScroll
    >
      {React.createElement(modalRenderers[current], { onCloseModal, isShowModal: active, params })}
    </ReactModal>
  );
};
