import axios from 'axios';

export const generateTextError = ({ name, type, message }: { name: string; type?: string; message?: string }) => {
  switch (type) {
    case 'maxLength': {
      return 'Превышен лимит символов';
    }
    case 'required': {
      if (name === 'email') {
        return 'Укажите емаил';
      } else {
        return 'Укажите пароль';
      }
    }
    case 'pattern': {
      return 'Введите корректный email';
    }
    case 'custom': {
      return message;
    }
    default: {
      return '';
    }
  }
};

export const getMessageFromError = (error: unknown) => {
  if (!axios.isAxiosError(error)) {
    return { name: 'email', message: 'Пользователь не зарегистрирован' };
  }
  const err = error.response?.data?.error;
  switch (err?.message) {
    case 'blockGuardPasswordIncorrect':
      return { name: 'password', message: 'Неверный  пароль' };
    case 'You are blocked':
      return { name: 'email', message: 'Доступ заблокирован' };
    case 'UserWithEmailAlreadyRegistered':
      return { name: 'email', message: 'Такой емаил уже есть в системе. Ввведите новый емаил' };
    case 'incorrectCode':
      return { name: 'code', message: 'Неверный код' };
    default:
      return { name: 'email', message: 'Пользователь не зарегистрирован' };
  }
};
