import styled from 'styled-components';

export const TdBody = styled.td`
  height: 66px;
  &:hover {
    border: 1px solid #b1b6c0;
  }
`;

export const FieldElement = styled.div`
  padding: 20px;
`;

export const Chip = styled.div`
  width: max-content;
  display: flex;
  column-gap: 7px;
  padding: 7px 10px 7px 7px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  cursor: pointer;
`;

export const Pin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 0px 0px;
  width: 16px;
  height: 16px;
  background: var(--color-white);
  border: 2px solid var(--color-gray-dark);
  border-radius: 2px;
  font-size: 9px;
`;

export const EmptyBlock = styled.div`
  text-align: center;
`;
