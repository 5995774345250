import styled from 'styled-components';

export const ModalWrapper = styled.div`
  width: 450px;
`;

export const Header = styled.div`
  height: 70px;
  border-bottom: 1px solid var(--color-gray-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
  font-family: Geometria;
  font-size: 19px;
  font-weight: 500;
  line-height: 20px;

  button {
    &:hover {
      background-color: var(--color-gray-btn-hv);
    }
  }
`;

export const Content = styled.div`
  padding: 35px;
`;
