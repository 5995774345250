import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectUser } from 'processes/store';
import { ROUTE_ROOT } from 'shared/lib/routes';
import { Authorized } from 'processes/Authorized';
import { LoginForm } from 'features/Auth/LoginForm';
import { PageTitle } from '../ui/PageTitle';
import { Wrapper, Content } from './styled';

export const LoginPage: React.FC = () => {
  const { user, isLogged } = useSelector(selectUser);

  if (isLogged && user.emailConfirmedStatus === 'confirmed') {
    return <Navigate to={ROUTE_ROOT} />;
  }

  return (
    <Authorized needRedirect={false}>
      <Wrapper>
        <Content>
          <PageTitle />
          <LoginForm />
        </Content>
      </Wrapper>
    </Authorized>
  );
};
