import React, { useState } from 'react';
import { IconButton, SettingsDropdown } from 'shared/ui';
import { FilterIcon, PlusIcon } from 'shared/lib/Icons';
import { FieldFilter } from './ui/FieldFilter';
import { FieldsConditionType, FieldsOptionsType, FieldsType } from './model/types';
import { AccessText, AddButton, DropdownList, SaveButton, Text } from './styled';

type Props = {
  isFiltered?: boolean;
  setFields: (e: FieldsType[]) => void;
};

export const GroupFilterBlock: React.FC<Props> = ({ isFiltered, setFields }) => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState<FieldsType[]>([{ id: 1, field: '', condition: '' }]);

  const handleSelectField = (newValue: { value: FieldsOptionsType['value'] }, id: number) => {
    setList(
      list.map(el => ({
        ...el,
        field: id === el.id ? newValue.value : el.field,
      })),
    );
  };

  const handleSelectCondition = (newValue: { value: FieldsConditionType['value'] }, id: number) => {
    setList(
      list.map(el => ({
        ...el,
        condition: id === el.id ? newValue.value : el.condition,
      })),
    );
  };

  const addFilter = () => {
    setList([...list, { id: list.length + 1, field: '', condition: '' }]);
  };

  const deleteField = (id: number) => {
    setList(list.length > 1 ? list.filter(el => el.id !== id) : [{ id: 1, field: '', condition: '' }]);
  };

  const onSave = () => {
    setFields(list);
    setOpen(false);
  };

  return (
    <SettingsDropdown
      open={open}
      setOpen={setOpen}
      label={
        <AccessText onClick={() => setOpen(true)} active={isFiltered}>
          <IconButton active={open} icon={<FilterIcon />} />
        </AccessText>
      }
    >
      <DropdownList>
        <Text color='#6A7B9B'>Фильтровать</Text>
        {list?.map(({ id, field, condition, value }) => (
          <FieldFilter
            key={id}
            id={id}
            field={field}
            condition={condition}
            inputDefaultValue={value}
            list={list}
            setList={setList}
            onSelectField={e => handleSelectField(e, id)}
            onSelectCondition={e => handleSelectCondition(e, id)}
            deleteField={() => deleteField(id)}
          />
        ))}
        <AddButton onClick={addFilter} disabled={!list[0].condition}>
          <PlusIcon />
          Добавить фильтр
        </AddButton>
        <SaveButton onClick={onSave} label='Применить' />
      </DropdownList>
    </SettingsDropdown>
  );
};
