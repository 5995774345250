import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { selectUser } from 'processes/store';
import { ROUTE_ROOT } from 'shared/lib/routes';
import { PageTitle } from '../ui/PageTitle';
import { Content } from '../LoginPage/styled';
import { RestorePasswordRoutes, RestorePasswordRoutesRenderers } from './model/types';
import { Wrapper } from './styled';

export const RestorePasswordPage: React.FC = () => {
  const [step, setStep] = useState(RestorePasswordRoutes.requestCode);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const [searchParams] = useSearchParams();

  const { isLogged, user } = useSelector(selectUser);

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      setCode(token);
      setStep(RestorePasswordRoutes.newPassword);
    }
  }, [searchParams]);

  const handleSaveEmail = useCallback((email: string) => setEmail(email), []);

  const handleSaveCode = useCallback((code: string) => setCode(code), []);

  const handleRedirect = useCallback((route: RestorePasswordRoutes) => setStep(route), []);

  if (isLogged && user.emailConfirmedStatus === 'confirmed') {
    return <Navigate to={ROUTE_ROOT} />;
  }

  return (
    <Wrapper>
      <Content>
        <PageTitle />
        {React.createElement(RestorePasswordRoutesRenderers[step], {
          stateEmail: email,
          stateCode: code,
          handleRedirect,
          handleSaveCode,
          handleSaveEmail,
        })}
      </Content>
    </Wrapper>
  );
};
