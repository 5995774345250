import styled from 'styled-components';
import { BlueBlock, Button, WhiteBlock } from '../styled';

export const DivBlock = styled.div`
  max-height: 658px;
  width: 100%;
  display: flex;
  position: relative;
`;

export const CustomWhiteBlock = styled(WhiteBlock)`
  padding: 40px 65px 50px;
`;

export const CustomBlueBlock = styled(BlueBlock)`
  padding: 65px;
  position: relative;
  overflow: hidden;

  img {
    height: 593px;
  }
`;

export const Text = styled.div<{ fontSize?: number }>`
  margin-top: 15px;
  font-style: normal;
  color: #6a7b9b;
  ${({ fontSize }) =>
    fontSize === 12
      ? `
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    `
      : fontSize === 15
      ? `
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    `
      : `
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    `}
`;

export const TagsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 25px;
`;

export const Tag = styled.div<{ active?: boolean }>`
  padding: 10px;
  border: 1px solid #d9dfeb;
  border-radius: 6px;
  width: max-content;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  background: ${({ active }) => (active ? '#225ffc' : 'white')};
  color: ${({ active }) => (active ? 'white' : 'black')};
`;

export const GoBack = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: 0;
  top: -72px;
  padding: 10px;
  cursor: pointer;

  background: #ffffff;
  border-radius: 6px;
`;

export const Title = styled.div`
  margin-top: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;

export const CustomButton = styled(Button)`
  margin-top: 35px;
`;
