import { useEffect } from 'react';
import { updateView, useAppDispatch } from 'processes/store';

const phoneMedia = window.matchMedia('(max-width: 767px)');
const tabletMedia = window.matchMedia('(max-width: 1023px)');
const laptopMedia = window.matchMedia('(max-width: 1599px)');
const desktopMedia = window.matchMedia('(min-width: 1600px)');

export const useView = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const update = () => {
      const view = {
        isPhoneView: phoneMedia.matches,
        isTabletView: tabletMedia.matches,
        isLaptopView: laptopMedia.matches,
        isDesktopView: desktopMedia.matches,
      };
      dispatch(updateView(view));
    };
    try {
      phoneMedia.addEventListener('change', update);
      tabletMedia.addEventListener('change', update);
      laptopMedia.addEventListener('change', update);
      desktopMedia.addEventListener('change', update);
    } catch (err) {
      try {
        phoneMedia.addListener(update);
        tabletMedia.addListener(update);
        laptopMedia.addListener(update);
        desktopMedia.addListener(update);
      } catch (err2) {
        console.error(err2);
      }
    }
    update();

    return () => {
      try {
        phoneMedia.removeEventListener('change', update);
        tabletMedia.removeEventListener('change', update);
        laptopMedia.removeEventListener('change', update);
        desktopMedia.removeEventListener('change', update);
      } catch (err) {
        try {
          phoneMedia.removeListener(update);
          tabletMedia.removeListener(update);
          laptopMedia.removeListener(update);
          desktopMedia.removeListener(update);
        } catch (err2) {
          console.error(err2);
        }
      }
    };
  }, [dispatch]);
};
