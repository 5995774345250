export const ROUTE_ROOT = '/ws/:wid';
export const ROUTE_WS = '/ws';

// AUTH
export const ROUTE_LOGIN = `/login`;
export const ROUTE_RESTORE_PASSWORD = `/restore-pass`;
export const ROUTE_REGISTRATION = `/registration`;
export const LOGOUT = `/logout`;

export const ROUTE_LOGIN_SOCIAL = '/login-social';

//contacts
export const ROUTE_CONTACTS = `/ws/:wid/contacts`;
export const ROUTE_CONTACT = `/ws/:wid/contacts/:id`;

//touches
export const ROUTE_TOUCHES = `/ws/:wid/touches`;

//groups
export const ROUTE_GROUP = `/ws/:wid/groups/:id`;

//emails
export const ROUTE_EMAILS = '/ws/:wid/emails';
export const ROUTE_EMAIL = '/ws/:wid/emails/:id';

//Settings
export const ROUTE_WS_SETTINGS = '/ws/:wid/settings/workplace';
export const ROUTE_PERSONAL_DATA_SETTINGS = '/ws/:wid/settings/personalData';
export const ROUTE_SETTINGS = '/ws/:wid/settings';
export const ROUTE_USERS_SETTINGS = '/ws/:wid/settings/users';

//USERS

//OTHER
export const ROUTE_TERMS_OF_USE = `/terms-of-use`;
export const ROUTE_EMPTY = `/empty`;
export const ROUTE_404 = `/404`;
export const ROUTE_403 = `/403`;
