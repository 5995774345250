import { ChangeEvent, FC, useState } from 'react';
import { DeleteBasket } from 'shared/lib/Icons';
import { IconButton } from 'shared/ui';
import {
  FieldsType,
  fieldsCondition,
  fieldsOptions,
  FieldsOptionsEnum,
  FieldsConditionEnum,
  FieldsOptionsType,
  FieldsConditionType,
} from '../../model/types';
import { DivWrapper, FieldSelect, FieldsWrapper, Input } from './styled';

type Props = {
  id: number;
  field: FieldsType['field'];
  condition: FieldsType['condition'];
  inputDefaultValue?: string;
  list: FieldsType[];
  setList: (e: FieldsType[]) => void;
  onSelectField?: (e: { value: FieldsOptionsType['value'] }) => void;
  onSelectCondition?: (e: { value: FieldsConditionType['value'] }) => void;
  deleteField?: () => void;
};

export const FieldFilter: FC<Props> = ({
  id,
  field,
  condition,
  inputDefaultValue,
  list,
  setList,
  onSelectField,
  onSelectCondition,
  deleteField,
}) => {
  const [value, setValue] = useState(inputDefaultValue);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setValue(value);
    setList(
      list.map(el => ({
        ...el,
        value: id === el.id ? value : el.value || undefined,
      })),
    );
  };

  const handleDelete = () => {
    deleteField && deleteField();
    setValue('');
  };

  return (
    <FieldsWrapper>
      <DivWrapper>
        <FieldSelect
          options={fieldsOptions}
          value={field ? { label: FieldsOptionsEnum[field], value: field } : ''}
          components={{
            IndicatorSeparator: () => null,
          }}
          // @ts-ignore
          onChange={e => onSelectField(e)}
          placeholder='Атрибут'
        />
        <FieldSelect
          options={fieldsCondition}
          value={condition ? { label: FieldsConditionEnum[condition], value: condition } : ''}
          components={{
            IndicatorSeparator: () => null,
          }}
          // @ts-ignore
          onChange={e => onSelectCondition(e)}
          isDisabled={!field}
          placeholder='Условие'
        />
        {(condition === 'notContain' || condition === 'contains') && (
          <Input onChange={handleChange} value={value} placeholder='Введите условие' />
        )}
      </DivWrapper>
      <IconButton icon={<DeleteBasket />} onClick={handleDelete} />
    </FieldsWrapper>
  );
};
