export type FieldsOptionsType = {
  label:
    | 'Должность'
    | 'Емаил'
    | 'Телефон'
    // | 'Статус'
    // | 'Тег'
    | 'Компания'
    | 'Группа'
    | 'День рождения'
    | 'Хобби'
    | 'Адрес';
  value:
    | 'roles'
    | 'emails'
    | 'phones'
    // | 'status'
    // | 'tags'
    | 'companies'
    | 'groups'
    | 'birthdays'
    | 'hobbies'
    | 'addresses';
};

export enum FieldsOptionsEnum {
  'roles' = 'Должность',
  'emails' = 'Емаил',
  'phones' = 'Телефон',
  // 'status' = 'Статус',
  // 'tags' = 'Тег',
  'companies' = 'Компания',
  'groups' = 'Группа',
  'birthdays' = 'День рождения',
  'hobbies' = 'Хобби',
  'addresses' = 'Адрес',
}

export enum FieldsConditionEnum {
  'notContain' = 'Не содержит',
  'contains' = 'Содержит',
  'empty' = 'Пустой',
  'notEmpty' = 'Не пустой',
}

export type FieldsConditionType = {
  label: 'Не содержит' | 'Содержит' | 'Пустой' | 'Не пустой';
  value: 'notContain' | 'contains' | 'empty' | 'notEmpty';
};

export const fieldsOptions: FieldsOptionsType[] = [
  { label: 'Должность', value: 'roles' },
  { label: 'Емаил', value: 'emails' },
  { label: 'Телефон', value: 'phones' },
  // { label: 'Статус', value: 'status' },
  // { label: 'Тег', value: 'tags' },
  { label: 'Компания', value: 'companies' },
  { label: 'Группа', value: 'groups' },
  { label: 'День рождения', value: 'birthdays' },
  { label: 'Хобби', value: 'hobbies' },
  { label: 'Адрес', value: 'addresses' },
];

export const fieldsCondition: FieldsConditionType[] = [
  { label: 'Не содержит', value: 'notContain' },
  { label: 'Содержит', value: 'contains' },
  { label: 'Пустой', value: 'empty' },
  { label: 'Не пустой', value: 'notEmpty' },
];

export type FieldsType = {
  id: number;
  field: FieldsOptionsType['value'] | '';
  condition: FieldsConditionType['value'] | '';
  value?: string;
};
