import React, { useState, useRef, useCallback, ChangeEvent } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { WSModal, CoverDefault } from 'shared/lib/Icons';
import { InputLight } from 'shared/ui';
import { Button, ModalCommon } from 'shared/ui';
import { createWsAction, useAppDispatch } from 'processes/store';
import { CoverField, CreateAvatar, LogoPreview, Label, TitleField, Actions, CreateButton } from './styled';

type Props = {
  onCloseModal: () => void;
};

export const CreateWsModal: React.FC<Props> = ({ onCloseModal }) => {
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);

  const hiddenFileInput = useRef(null);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<{ title: string }>({
    defaultValues: {
      title: '',
    },
  });

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  const onSubmit: SubmitHandler<{ title: string }> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        createWsAction({
          ws: { title: data.title },
          successCallback: () => {
            setIsLoading(false);
            onCloseModal();
          },
          errorCallback: () => {
            setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, onCloseModal],
  );

  return (
    <ModalCommon header={<WSModal />}>
      <CoverField>
        <CreateAvatar>
          <LogoPreview onClick={handleClick}>
            <CoverDefault />
          </LogoPreview>
          <input ref={hiddenFileInput} onChange={handleFile} type='file' style={{ display: 'none' }} />
        </CreateAvatar>
        <Label>Создать новое рабочее пространство</Label>
      </CoverField>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleField>
          <InputLight
            name='title'
            register={register}
            rules={{
              required: true,
              maxLength: 250,
            }}
            hasError={!!errors.title}
            textError={
              errors.title && (errors.title?.type === 'required' ? 'Введите название' : 'Превышен лимит символов')
            }
            placeholder='Название'
            isFocus={!!watch('title')}
          />
        </TitleField>
        <Actions>
          <Button label='Отмена' $isEmpty onClick={onCloseModal} isLoading={isLoading} />
          <CreateButton label='Создать' isLoading={isLoading} />
        </Actions>
      </form>
    </ModalCommon>
  );
};
