import { Divider, IconButton } from 'shared/ui';
import styled from 'styled-components';

export const ContactInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;

  div:first-child {
    width: 22px;
    height: 22px;

    svg {
      width: 12px;
      height: 10px;
    }
  }
`;

export const DropdownList = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
`;

export const AccessText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DeleteButton = styled(IconButton)`
  max-width: max-content;
  max-height: max-content;
  margin-right: 5px;
  svg {
    fill: var(--color-error);
  }
`;

export const Text = styled.div<{ color?: string; border?: boolean }>`
  display: flex;
  column-gap: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
  color: ${({ color }) => (color ? color : 'black')};
  border-bottom: ${({ border }) => (!border ? 'none' : '1px solid #d9dfeb')};
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;

export const DividerCustom = styled(Divider)`
  border: none;
  background-color: var(--color-gray-dark);
  height: 3px;
`;
