import { Passport } from 'shared/lib/Icons';
import { ROUTE_LOGIN } from 'shared/lib/routes';
import { AuthLink } from 'pages/Auth/ui/AuthLink';
import { IconWrapper, Label, LinkWrapper, Wrapper } from '../styled';
import { RestorePasswordRoutes } from 'pages/Auth/RestorePasswordPage/model/types';

type Props = {
  handleRedirect: (route: RestorePasswordRoutes) => void;
};

export const Finish: React.FC<Props> = () => {
  return (
    <Wrapper>
      <Label>
        Укажите
        <br /> новый пароль
      </Label>
      <IconWrapper>
        <Passport />
      </IconWrapper>
      <p>
        Пароль успешно
        <br /> изменен
      </p>
      <p>Теперь вы можете войти на сайт используя новый пароль</p>
      <LinkWrapper>
        <AuthLink to={ROUTE_LOGIN} text='Авторизоваться' />
      </LinkWrapper>
    </Wrapper>
  );
};
