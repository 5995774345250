import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';
import { IUser } from 'processes/store';

type Response = {
  result: IUser;
  errors: IError[];
};

export const addGoogleAccountApi = (): AxiosPromise<Response> => {
  return apiInstance.post('/user/auth/social/account/attach');
};
