import axios from 'axios';

export const generateTextError = ({ name, type, message }: { name: string; type?: string; message?: string }) => {
  switch (type) {
    case 'required': {
      return 'Укажите код';
    }
    case 'custom': {
      return message;
    }
    default: {
      return '';
    }
  }
};

export const getMessageFromError = (error: unknown) => {
  if (!axios.isAxiosError(error)) {
    return { name: 'code', message: 'Пользователь не зарегистрирован' };
  }
  const err = error.response?.data?.error;
  switch (err?.message) {
    case 'blockGuardPasswordIncorrect':
      return { name: 'code', message: 'Неверный код' };
    default:
      return { name: 'code', message: 'Неверный код' };
  }
};
