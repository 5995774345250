import React, { useState, useRef, useCallback, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InitialModalState, selectWs, useAppDispatch } from 'processes/store';
import { WSModal, CoverDefault } from 'shared/lib/Icons';
import { Button, InputLight, ModalCommon } from 'shared/ui';
import { updateGroupAction } from './model/updateGroupAction';
import { CoverField, CreateAvatar, LogoPreview, Label, TitleField, Actions, CreateButton } from './styled';

type Inputs = {
  title: string;
};

type Props = {
  onCloseModal: () => void;
  params?: InitialModalState['params'];
};

export const UpdateGroupModal: React.FC<Props> = ({ params, onCloseModal }) => {
  const [file, setFile] = useState<File>();

  const dispatch = useAppDispatch();

  const { ws } = useSelector(selectWs);

  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      title: params?.title || '',
    },
  });

  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      ws._id &&
        params?.id &&
        dispatch(
          updateGroupAction({
            wsId: ws._id,
            title: data.title,
            groupId: params?.id,
            successCallback: () => {
              onCloseModal && onCloseModal();
            },
            errorCallback: () => {},
          }),
        );
    },
    [dispatch, onCloseModal, params?.id, ws._id],
  );

  const enterSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.code === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <ModalCommon header={<WSModal />}>
      <CoverField>
        <CreateAvatar>
          <LogoPreview onClick={handleClick}>
            <CoverDefault />
          </LogoPreview>
          <input ref={hiddenFileInput} onChange={handleFile} type='file' style={{ display: 'none' }} />
        </CreateAvatar>
        <Label>{`Изменить группу ${params?.title}?`}</Label>
      </CoverField>
      <form onSubmit={handleSubmit(onSubmit)} onKeyDown={enterSubmit}>
        <TitleField>
          <InputLight name='title' register={register} placeholder='Новое название' />
        </TitleField>
        <Actions>
          <Button label='Отмена' onClick={onCloseModal} />
          <CreateButton label='Изменить' type='submit' />
        </Actions>
      </form>
    </ModalCommon>
  );
};
