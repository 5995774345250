import styled from 'styled-components';

export const Button = styled.button<{ isGoogle?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 6px;
  width: 270px;
  transition: ease-out 0.4s;
  background: ${props => (props.isGoogle ? 'transparent' : 'var(--color-bg-auth-button)')};
  border: ${props => (props.isGoogle ? '1px solid var(--color-mybase-line-gray);' : '')};
  height: ${props => (props.isGoogle ? '50px' : '60px')};
  padding: ${props => (props.isGoogle ? '17px 25px 17px 25px' : '23px 25px 21px 25px')};

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

export const Text = styled.div<{ isGoogle?: boolean }>`
  font-weight: ${props => (props.isGoogle ? '400' : '700')};
  font-size: ${props => (props.isGoogle ? '15px' : '16px')};
  color: ${props => (props.isGoogle ? 'var(--color-black)' : 'var(--color-auth-button)')};
  line-height: 16px;
`;

export const Img = styled.img`
  width: 14px;
  height: 14px;
`;
