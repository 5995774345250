import { createAsyncThunk } from '@reduxjs/toolkit';
import { setContact, setNeedUpdateContact, IContact } from 'processes/store';
import { updateContactApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  params: Partial<IContact>;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const updateContactAction = createAsyncThunk<object, PayloadType>(
  'update/contact',
  async (payload, { dispatch }) => {
    const { wsId, params, errorCallback, successCallback } = payload;

    try {
      const response = await updateContactApi({ wsId, params });
      if (response.status === 200) {
        dispatch(setContact({ contact: response.data.result }));
        dispatch(setNeedUpdateContact(true));
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
