import React, { useState } from 'react';
import { SettingsDropdown } from 'shared/ui';
import {
  AddCommentIcon,
  ArrowDown,
  CallIcon,
  NotificationIcon,
  PerfectCallIcon,
  SmsIcon,
  StatusChangeIcon,
  TouchesNav,
  VideoMeetIcon,
} from 'shared/lib/Icons';
import { CustomSwitcher, DividerCustom, DropdownList, FilterTouchesButton, Label } from './styled';

type Props = {
  filters: string[];
  setFilters: (e: string[]) => void;
};

export const FilterDropdown: React.FC<Props> = ({ filters, setFilters }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (type: string, check: boolean) => {
    switch (type) {
      case 'all':
        setFilters(check ? ['emailReceived', 'emailSent'] : []);
        break;
      case 'emailReceived':
        setFilters(check ? [...filters, type] : filters.filter(el => el !== type));
        break;
      case 'emailSent':
        setFilters(check ? [...filters, type] : filters.filter(el => el !== type));
    }
  };

  return (
    <SettingsDropdown
      open={open}
      setOpen={setOpen}
      label={
        <FilterTouchesButton onClick={() => setOpen(true)}>
          <TouchesNav />
          Все касания
          <ArrowDown />
        </FilterTouchesButton>
      }
    >
      <DropdownList>
        <Label>Касания</Label>
        <CustomSwitcher
          defaultValue={!!(filters.includes('emailReceived') && filters.includes('emailSent'))}
          onChange={e => handleClick('all', e)}
        >
          <div>
            <TouchesNav />
            Все касания
          </div>
        </CustomSwitcher>
        <DividerCustom margin='0' />

        {/* <CustomSwitcher>
          <div>
            <PerfectCallIcon />
            Совершен звонок
          </div>
        </CustomSwitcher>
        <CustomSwitcher>
          <div>
            <CallIcon />
            Принят звонок
          </div>
        </CustomSwitcher> */}
        <CustomSwitcher
          defaultValue={filters.includes('emailReceived')}
          onChange={e => handleClick('emailReceived', e)}
        >
          <div>
            <SmsIcon />
            Получено письмо
          </div>
        </CustomSwitcher>
        <CustomSwitcher defaultValue={filters.includes('emailSent')} onChange={e => handleClick('emailSent', e)}>
          <div>
            <SmsIcon />
            Отправленно письмо
          </div>
        </CustomSwitcher>
        {/* <CustomSwitcher>
          <div>
            <StatusChangeIcon />
            Смена статуса
          </div>
        </CustomSwitcher>
        <CustomSwitcher>
          <div>
            <AddCommentIcon />
            Комментарий
          </div>
        </CustomSwitcher>
        <CustomSwitcher>
          <div>
            <NotificationIcon />
            Напоминание
          </div>
        </CustomSwitcher>
        <CustomSwitcher>
          <div>
            <VideoMeetIcon />
            Видео встреча
          </div>
        </CustomSwitcher> */}
      </DropdownList>
    </SettingsDropdown>
  );
};
