import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWs, setNeedUpdateTouch } from 'processes/store';
import { chooseEmailReceiversApi, sendEmailApi } from 'shared/api';

type PayloadType = {
  wsId: IWs['_id'];
  ids: { _objectId: string }[];
  emailId: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const sendEmailAction = createAsyncThunk<object, PayloadType>('send/email', async (payload, { dispatch }) => {
  const { wsId, emailId, ids, errorCallback, successCallback } = payload;
  try {
    const response = await chooseEmailReceiversApi({ wsId, emailId, ids });
    if (response.status === 200) {
      const responseSend = await sendEmailApi({ wsId, emailId });
      if (responseSend.status === 200) {
        dispatch(setNeedUpdateTouch(true));
        successCallback?.();
      }
    }
  } catch (err) {
    errorCallback?.();
  }
});
