import { AxiosPromise } from 'axios';
import { IError, apiInstance } from '../base';
import { IGroup } from 'processes/store';

type Request = {
  wsId: string;
  page: number;
};

type Response = {
  result: {
    list: IGroup[];
  };
  error: IError[];
};

export const getGroupsApi = ({ wsId, page }: Request): AxiosPromise<Response> => {
  return apiInstance.get(`ws/${wsId}/contacts/groups`, {
    params: {
      'pagination[page]': page ? page : 1,
    },
  });
};
