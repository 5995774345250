import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from 'processes/store';
import { RequestState } from 'shared/lib/types';
//import { getProfileInfoApi } from 'shared/api';

const initialState = {
  status: RequestState.PENDING,
  profile: {} as IProfile,
};

/*export const getProfileInfo = createAsyncThunk<object>('profile/me', async (payload, { dispatch }) => {
  try {
    const profileResponse = await getProfileInfoApi();

    if (profileResponse.status === 200) {
      dispatch(setProfileStatus({ status: RequestState.SUCCESS }));
      dispatch(
        setProfile({
          profile: profileResponse.data.result,
        }),
      );
    }
  } catch (err) {
    dispatch(setProfileStatus({ status: RequestState.ERROR }));
  }
});*/

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<{ profile: Partial<IProfile> }>) => {
      state.status = RequestState.SUCCESS;
      state.profile = { ...state.profile, ...action.payload.profile };
    },
    setProfileStatus: (state, action: PayloadAction<{ status: RequestState }>) => {
      state.status = action.payload.status;
    },
    logoutProfile: state => {
      state.status = RequestState.PENDING;
      state.profile = {} as IProfile;
    },
  },
});

export const { setProfile, logoutProfile, setProfileStatus } = profileSlice.actions;

export default profileSlice.reducer;
