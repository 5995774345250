import { DefaultButton } from 'shared/ui';
import styled from 'styled-components';

export const FilesList = styled.div`
  display: flex;
  column-gap: 20px;
  font-family: Geometria;
  max-height: 72px;
  margin-bottom: 20px;
  position: absolute;
  top: -90px;
`;

export const FileBlock = styled.div`
  max-width: 340px;
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  border: 1px solid var(--color-gray-dark);
  border-radius: 10px;
  padding: 22px 20px;
`;

export const PercentageBlock = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-gray-text);
`;

export const FileTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 7px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -ms-line-clamp: 1;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;

export const FileCancelButton = styled(DefaultButton)`
  width: 36px;
  height: 36px;
  border: 1px solid var(--color-gray-dark);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-radius: 6px;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;

export const AddFileButton = styled(FileCancelButton)`
  width: 74px;
  height: 74px;
  border: none;
`;
