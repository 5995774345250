import styled from 'styled-components';

export const DivWrapper = styled.div`
  height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const TableWrapper = styled.table`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  tr:nth-child(1),
  tr:nth-child(4) {
    td {
      background: #f3faf6;
    }
  }

  thead {
    color: var(--color-mybase-gray-text);
  }

  th:nth-child(2),
  td:nth-child(2) {
    max-width: 280px;
  }
`;

export const TrHeadTable = styled.tr`
  position: relative;
  height: 60px;
  border: 1px solid var(--color-mybase-line-gray);
  border-left: none;

  th:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  th {
    background: var(--color-white);
    text-align: start;
    padding: 20px;
  }
  th {
    border-right: 1px solid var(--color-mybase-line-gray);
  }
`;

export const TrBodyTable = styled(TrHeadTable)`
  height: 60px;
  cursor: pointer;

  td:first-child {
    position: sticky;
    left: 0;
    /* z-index: 1; */
    min-width: 154px;
    border-right: 1px solid var(--color-mybase-line-gray);
  }
  td:nth-child(2) {
    min-width: 180px;
  }
  td:nth-child(3),
  td:nth-child(6),
  td:nth-child(7) {
    min-width: 200px;
  }
  td:nth-child(4) {
    min-width: 300px;
  }
  td:nth-child(5) {
    min-width: 170px;
  }
  td:nth-child(8) {
    min-width: 140px;
  }
  td:last-child {
    /* padding: 0; */
  }

  td {
    background: var(--color-white);
    padding: 20px;
    max-height: 60px;
    height: 60px;
  }
`;

export const AudioBlock = styled.div`
  width: 100%;
  height: 100;
  display: flex;
  justify-content: space-between;

  button {
    &:hover {
      background-color: var(--color-gray-btn-hv);
    }
  }
`;

export const StatusBlock = styled.div<{ color?: string }>`
  padding: 5px 10px;
  border-radius: 6px;
  background: ${({ color }) => (color ? color : '#cfe9da')};
  max-width: max-content;
`;

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    padding: 5px 10px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &:hover {
      background-color: var(--color-gray-btn-hv);
      svg {
        fill: var(--color-gray-icon-btn-hv);
      }
    }
  }
`;

export const CustomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
`;
