import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITouches } from '../types';
import { RequestState } from 'shared/lib/types';

type initialStateType = {
  touch: ITouches;
  touchesList: ITouches[];
  status: RequestState;
  needUpdateTouch: boolean;
  total: number;
};

const initialState: initialStateType = {
  touch: {} as ITouches,
  touchesList: [],
  needUpdateTouch: false,
  status: RequestState.PENDING,
  total: 0,
};

const touchSlice = createSlice({
  name: 'touch',
  initialState,
  reducers: {
    setTouch: (state, action: PayloadAction<{ touch: ITouches }>) => {
      state.touch = action.payload.touch;
    },
    setTouchesList: (state, action: PayloadAction<{ touchesList: ITouches[]; total?: number }>) => {
      const { touchesList, total } = action.payload;
      state.touchesList = touchesList;
      state.total = total ? total : 0;
    },
    setNeedUpdateTouch: (state, action: PayloadAction<boolean>) => {
      state.needUpdateTouch = action.payload;
    },
    setStatusTouch: (state, action: PayloadAction<RequestState>) => {
      state.status = action.payload;
    },
  },
});

export const { setTouch, setTouchesList, setNeedUpdateTouch, setStatusTouch } = touchSlice.actions;
export default touchSlice.reducer;
