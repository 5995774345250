import { flexFull } from 'shared/lib/mixins';
import styled from 'styled-components';

export const SearchField = styled.div`
  ${flexFull};
  position: relative;

  button {
    position: absolute;
  }

  button:last-child {
    top: 0;
    right: 0;
  }
`;

export const SearchInput = styled.input`
  padding: 14px 10px 12px 35px;
  width: 100%;
  height: 36px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
`;
