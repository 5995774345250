import React, { useEffect, useState, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { selectUser, selectWs, useAppDispatch } from 'processes/store';
import { ROUTE_LOGIN } from 'shared/lib/routes';
import { RequestState } from 'shared/lib/types';
import { NotConnectionPage } from 'pages/700';
import { authMainAction } from './model';
import { Spinner } from 'shared/ui';
import { Wrapper } from './styled';

type Props = {
  needRedirect?: boolean;
};

export const Authorized: React.FC<PropsWithChildren<Props>> = ({ children, needRedirect = true }) => {
  const [isConnect, setIsConnect] = useState(window.navigator.onLine);

  const dispatch = useAppDispatch();

  const { status, isLogged } = useSelector(selectUser);
  const { status: statusWs } = useSelector(selectWs);

  useEffect(() => {
    const handlerOffline = () => {
      setIsConnect(false);
    };
    const handlerOnline = () => setIsConnect(true);
    window.addEventListener('offline', handlerOffline);
    window.addEventListener('online', handlerOnline);

    !isLogged && isConnect && needRedirect && dispatch(authMainAction({}));

    return () => {
      window.removeEventListener('offline', handlerOffline);
      window.removeEventListener('offline', handlerOnline);
    };
  }, [dispatch, isConnect, needRedirect, isLogged]);

  if (statusWs === (RequestState.LOADING || RequestState.PENDING) && needRedirect) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }

  if (!localStorage.getItem('wsId') && needRedirect && !isLogged) {
    return <Navigate to={ROUTE_LOGIN} replace />;
  }

  if (status === RequestState.ERROR && needRedirect) {
    return <Navigate to={ROUTE_LOGIN} replace />;
  }

  return (
    <>
      {isConnect && children}
      {!isConnect && <NotConnectionPage />}
    </>
  );
};
