import React, { useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { getTime } from 'date-fns';
import { IContact, IGroup, IWs, ModalType, useAppDispatch } from 'processes/store';
import {
  CakeIcon,
  CallIcon,
  CompanyIcon,
  GenderIcon,
  GroupsIcon,
  LinksIcon,
  LocationIcon,
  RoleIcon,
  SmsIcon,
  TextIcon,
} from 'shared/lib/Icons';
import { clearMaskPhone } from 'shared/lib/clearMaskPhone';
import { useModal } from 'shared/hooks';
import { DatePicker } from 'shared/ui/DatePicker';
import { Select } from 'shared/ui';
import { FieldGroups } from './FieldGroups';
import { FieldInputs } from './FieldsInput';
import { Inputs, updateContactAction } from './model';
import { FieldWrapper, FieldsWrapper, InputBlock, Label, SelectWrapper } from './styled';

type Props = {
  contact: IContact;
  ws: IWs;
  groups: IGroup[];
};

export const ContactForm: React.FC<Props> = ({ contact, ws, groups }) => {
  const [$isEmpty, setIsEmpty] = useState(true);

  const { showModal } = useModal();
  const dispatch = useAppDispatch();

  const { register, handleSubmit, setValue, reset, control } = useForm<Inputs>({
    //@ts-ignore
    defaultValues: useMemo(() => {
      return {
        ...contact,
        firstName: contact?.title?.split(' ')[0] || '',
        lastName: contact?.title?.split(' ')[1] || '',
      };
    }, [contact]),
    mode: 'onBlur',
  });

  const addInGroupContact = useCallback(() => {
    showModal(ModalType.AddContactInGroup, { wsId: ws._id, contact, groups });
  }, [showModal, ws._id, contact, groups]);

  const handleDeleteGroup = useCallback(
    (id: string, title: string) => {
      showModal(ModalType.DeleteElement, {
        id,
        title,
        onClick: () =>
          dispatch(
            updateContactAction({
              wsId: ws._id,
              params: {
                _id: contact._id,
                _groupId: contact._groupId.filter(el => !el.includes(id)),
              },
            }),
          ),
      });
    },
    [showModal, ws._id, contact, dispatch],
  );

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    ({ birthDay, addresses, firstName, lastName, roles, links, companies, emails, phones, _groupId, gender }) => {
      const birthday =
        birthDay && (typeof birthDay === 'object' || typeof birthDay === 'number') ? getTime(birthDay) : null;

      const params = {
        birthDay: birthday,
        addresses: addresses?.filter(el => el.value),
        title: `${firstName} ${lastName}`,
        roles: roles?.filter(el => el.value),
        links: links?.filter(el => el.value),
        companies: companies?.filter(el => el.value),
        emails: emails?.filter(el => el.value),
        phones: phones?.filter(el => el.value),
        _id: contact._id,
        _groupId,
        gender,
      };
      dispatch(
        updateContactAction({
          wsId: ws._id,
          params,
          successCallback: () => {
            reset(params);
          },
          errorCallback: () => {},
        }),
      );
    },
    [dispatch, reset, ws._id, contact],
  );

  const changeFavorite = (name: 'companies' | 'emails' | 'phones', value: string) => {
    dispatch(
      updateContactAction({
        wsId: ws._id,
        params: {
          _id: contact._id,
          [name]: contact[name].map(el => ({
            ...el,
            isFavorites: el.value === value,
          })),
        },
        successCallback: () => {
          setValue(
            name,
            contact[name].map(el => ({
              ...el,
              isFavorites: el.value === value,
            })),
          );
        },
        errorCallback: () => {},
      }),
    );
  };

  const handleTelephone = useCallback(
    (phone: string, index: number) => {
      setValue(`phones.${index}.value`, clearMaskPhone(phone));
    },
    [setValue],
  );

  const delCode = useCallback(
    (event: any) => {
      if (event?.nativeEvent?.target?.value === '+7(___)___ __ __') {
        setIsEmpty(true);
        return setValue('phones', [{ value: '', isFavorites: false }]);
      }
      handleSubmit(onSubmit)();
      return setIsEmpty(false);
    },
    [setValue, handleSubmit, onSubmit],
  );

  return (
    <form autoComplete='off'>
      <FieldsWrapper>
        <FieldInputs
          isPin
          name='companies'
          label='Компании'
          register={register}
          control={control}
          icon={<CompanyIcon />}
          onBlur={handleSubmit(onSubmit)}
          //@ts-ignore
          onFavorite={changeFavorite}
        />
        <FieldInputs
          name='roles'
          label='Должность'
          register={register}
          control={control}
          icon={<RoleIcon />}
          onBlur={handleSubmit(onSubmit)}
        />
        <FieldInputs
          isCopy
          name='emails'
          label='Емаил'
          register={register}
          control={control}
          icon={<SmsIcon />}
          onBlur={handleSubmit(onSubmit)}
          //@ts-ignore
          onFavorite={changeFavorite}
        />
        <FieldInputs
          isCopy
          name='phones'
          label='Телефон'
          register={register}
          control={control}
          icon={<CallIcon />}
          handleTelephone={handleTelephone}
          delCode={delCode}
          onBlur={handleSubmit(onSubmit)}
          //@ts-ignore
          onFavorite={changeFavorite}
          // $isEmpty={$isEmpty}
        />
        <FieldGroups
          name='_groupId'
          label='Группы'
          icon={<GroupsIcon />}
          list={groups.filter(el => contact?._groupId.includes(el._id))}
          onClick={addInGroupContact}
          deleteGroup={(id, title) => handleDeleteGroup(id, title)}
        />

        <FieldWrapper>
          <Label onClick={e => e.preventDefault()}>
            <div>
              <CakeIcon /> День рождения
            </div>
          </Label>
          <InputBlock>
            <Controller
              control={control}
              name='birthDay'
              render={({ field }) => (
                <DatePicker
                  placeholder='Выберите дату'
                  dateFormat='dd.MM.yyyy'
                  onSelect={() => handleSubmit(onSubmit)()}
                  onChange={date => field.onChange(date)}
                  initDate={field.value ? new Date(field.value) : null}
                />
              )}
            />
          </InputBlock>
        </FieldWrapper>

        <FieldInputs
          name='firstName'
          label='Имя'
          register={register}
          icon={<TextIcon />}
          control={control}
          isOnly
          isActionButtons={false}
          onBlur={handleSubmit(onSubmit)}
          placeholder='Введите имя'
        />
        <FieldInputs
          name='lastName'
          label='Фамилия'
          register={register}
          icon={<TextIcon />}
          control={control}
          isOnly
          isActionButtons={false}
          onBlur={handleSubmit(onSubmit)}
          placeholder='Введите фамилия'
        />
        <FieldInputs
          name='links'
          label='Ссылки'
          register={register}
          icon={<LinksIcon />}
          control={control}
          onBlur={handleSubmit(onSubmit)}
        />
        <FieldWrapper>
          <Label onClick={e => e.preventDefault()}>
            <div>
              <GenderIcon /> Пол
            </div>
          </Label>
          <SelectWrapper>
            <Controller
              control={control}
              name='gender'
              render={({ field }) => (
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                  }} //@ts-ignore
                  onChange={data => field.onChange(data.value)}
                  defaultValue={{ label: field.value === 'male' ? 'Мужской' : 'Женский', value: field.value }}
                  placeholder='Выберите пол'
                  onBlur={handleSubmit(onSubmit)}
                  options={[
                    { value: 'male', label: 'Мужской' },
                    { value: 'female', label: 'Женский' },
                  ]}
                />
              )}
            />
          </SelectWrapper>
        </FieldWrapper>
        <FieldInputs
          name='addresses'
          label='Адрес'
          register={register}
          icon={<LocationIcon />}
          control={control}
          onBlur={handleSubmit(onSubmit)}
          //@ts-ignore
          // onFavorite={changeFavorite}
        />
      </FieldsWrapper>
    </form>
  );
};
