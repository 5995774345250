import styled from 'styled-components';
import { flexRow, alignMiddle } from 'shared/lib/mixins';

export const Button = styled.button<{ active?: boolean }>`
  ${flexRow};
  ${alignMiddle};
  width: 36px;
  height: 36px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 100ms;
  svg {
    fill: var(--color-gray-icon-btn-df);
    transition: fill 100ms;
  }
  &:hover {
    /* background-color: var(--color-gray-btn-hv); */
    /* svg {
      fill: var(--color-gray-icon-btn-hv);
    } */
  }
  transform: rotate(${({ active }) => (active ? '-180deg' : '0')});
`;
