import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteContactsTagsApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const deleteTagsAction = createAsyncThunk<object, PayloadType>(
  'delete/contactTags',
  async (payload, { dispatch }) => {
    const { wsId, id, errorCallback, successCallback } = payload;
    try {
      const response = await deleteContactsTagsApi({ wsId, id });
      if (response.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
