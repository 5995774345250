import { hideModal, useAppDispatch } from 'processes/store';
import React, { useEffect } from 'react';

type Props = {};

export const AlertModal: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTimeout(() => dispatch(hideModal()), 3000);
  }, [dispatch]);

  return <div>Сообщение отправлено</div>;
};
