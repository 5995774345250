import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEmailApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  subject?: string;
  content?: string;
  successCallback?: (id?: string) => void;
  errorCallback?: () => void;
};

export const createEmailAction = createAsyncThunk<object, PayloadType>('create/email', async payload => {
  const { wsId, subject, content, errorCallback, successCallback } = payload;
  try {
    const response = await createEmailApi({ wsId, subject, content });
    if (response.status === 200) {
      successCallback && successCallback(response.data.result._id);
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
