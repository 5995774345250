import React, { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { AuthLink } from 'pages/Auth/ui/AuthLink';
import { AuthButton } from 'pages/Auth/ui/AuthButton';
import { useAppDispatch } from 'processes/store';
import { ROUTE_REGISTRATION, ROUTE_RESTORE_PASSWORD, ROUTE_ROOT } from 'shared/lib/routes';
import { LineWithText } from 'shared/ui';
import { generateTextError } from './lib';
import { loginAction } from './model';
import { Wrapper, Label, Text, LinkWrapper, InputWrapper } from './styled';

type Inputs = {
  email: string;
  password: string;
};

export const LoginForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        loginAction({
          login: data.email,
          password: data.password,
          successCallback: (needReg?: boolean) => {
            setIsLoading(false);
            needReg ? navigate(ROUTE_REGISTRATION) : navigate(ROUTE_ROOT);
          },
          errorCallback: (err: { name: string; message: string }) => {
            if (err.name === 'email' || err.name === 'password') {
              setError(err.name, { type: 'custom', message: err.message });
            }
            return setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, setError, navigate],
  );

  const handleGoogle = () => {
    window.location.assign(`${process.env.REACT_APP_API_URL}user/auth/google/callback/web?inviteToken=&referalToken=`);
  };

  return (
    <Wrapper>
      <Label>С возвращением  👋</Label>
      <LineWithText text='Войдите через' />
      <AuthButton label='Войти через Google' isGoogle onClick={handleGoogle} />
      <LineWithText text='Или' />
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <AuthInput
            label='Емаил'
            name='email'
            register={register}
            rules={{
              pattern: /[a-zA-Z0-9+._%-+]{1,256}@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+/,
              required: true,
              maxLength: 250,
            }}
            hasError={!!errors.email}
            textError={generateTextError({ name: 'email', type: errors.email?.type, message: errors.email?.message })}
            placeholder='Ваша электронная почта'
            isFocus={!!watch('email')}
          />
        </InputWrapper>
        <AuthInput
          label='Пароль'
          name='password'
          register={register}
          rules={{ required: true, maxLength: 250 }}
          hasError={!!errors.password}
          textError={generateTextError({
            name: 'password',
            type: errors.password?.type,
            message: errors.password?.message,
          })}
          placeholder='Введите пароль'
          isFocus={!!watch('password')}
          isPassword
        />
        <LinkWrapper>
          <AuthLink to={ROUTE_RESTORE_PASSWORD} text='Забыли пароль?' />
        </LinkWrapper>
        <AuthButton label='Войти на сайт' onClick={handleSubmit(onSubmit)} isLoading={isLoading} />
        <Text>Нажимая «Войти», вы соглашаетесь с условиями Публичного договора (Оферты)</Text>
        <AuthLink to={ROUTE_REGISTRATION} text='Зарегистрироваться' />
      </form>
    </Wrapper>
  );
};
