import styled from 'styled-components';
import { DefaultButton, InputLight } from 'shared/ui';

export const ActionButtons = styled.div`
  display: flex;
  column-gap: 8px;
  position: absolute;
  right: 15px;
`;

export const FavoriteBtn = styled(DefaultButton)`
  position: absolute;
  right: 20px;
  top: 8px;
`;

export const InputBlock = styled.div<{ visible?: boolean }>`
  display: flex;
  column-gap: 8px;
  height: 36px;
  position: relative;
  border-radius: 4px;

  &:not(:hover) {
    ${ActionButtons} {
      display: ${({ visible }) => (visible ? 'flex' : 'none')};
    }
  }

  &:hover {
    input {
      background: var(--color-gray-light);
    }

    ${FavoriteBtn} {
      display: none;
    }
  }

  input:focus {
    ${FavoriteBtn} {
      display: none;
    }
  }
`;

export const Pin = styled.div`
  border: 1px solid var(--color-gray-dark);
  border-radius: 2px;
  width: 16px;
  height: 16px;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const Input = styled(InputLight)<{ isPin?: boolean }>`
  width: 300px;
  height: 36px;

  input {
    padding: ${({ isPin }) => (isPin ? '0 0 0 34px' : '0 15px')};
    border: none;
  }
  input:focus {
    border: 1px solid var(--color-input-border);
  }
`;

export const ActionButton = styled(DefaultButton)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  &:hover {
    background: #e0e3eb;
    border-radius: 4px;
  }
`;

export const Text = styled.div<{ color?: string; border?: boolean; active?: boolean }>`
  display: flex;
  font-style: normal;
  column-gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
  color: ${({ color }) => (color ? color : 'var(--color-black)')};
  border-bottom: ${({ border }) => (!border ? 'none' : '1px solid var(--color-gray-dark)')};
  align-items: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
    
    `}

  &:hover {
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
`;
