import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';

export const Link = styled(RLink)`
  color: var(--color-auth-link);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;
