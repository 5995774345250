import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWs, setContactList, setNeedUpdateContact } from 'processes/store';
import { getContactsApi } from 'shared/api/contacts';

type PayloadType = {
  wsId: IWs['_id'];
  limit?: number;
  sort?: 'asc' | 'desc' | null;
  order_by?: string | null;
  isCount?: boolean;
  search?: string;
  page: number;
  successCallback?: (total?: number) => void;
  errorCallback?: () => void;
};

export const getContactsAction = createAsyncThunk<object, PayloadType>(
  'get/contacts',
  async (payload, { dispatch }) => {
    const { wsId, page, limit, search, sort, order_by, isCount, errorCallback, successCallback } = payload;
    try {
      const response = await getContactsApi({ wsId, page, limit, search, sort, order_by });

      if (response.status === 200) {
        const { list, total } = response.data.result;
        if (!isCount) {
          dispatch(setContactList({ contactList: list, total }));
          dispatch(setNeedUpdateContact(false));
          successCallback && successCallback();
        } else {
          successCallback && successCallback(total);
        }
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
