import styled from 'styled-components';
import { flexCol, flexRow, flexFull, alignCenter } from 'shared/lib/mixins';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
`;

export const Header = styled.header`
  padding: 0 35px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const Title = styled.h1`
  font-size: 19px;
  line-height: 20px;
  font-family: var(--font-medium);
`;
