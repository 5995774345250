import styled from 'styled-components';
import { alignCenter, flexCol, flexFull, flexRow } from 'shared/lib/mixins';
import { DefaultButton } from 'shared/ui';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
  position: relative;
`;

export const Header = styled.header`
  padding: 0 35px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
  justify-content: space-between;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const ShareButton = styled(DefaultButton)`
  padding: 10px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

export const SettingsButton = styled(ShareButton)`
  border: none;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-mybase-gray-text);
`;

export const GroupsList = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const GroupCard = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding: 8px 12px;
  /* width: 157px; */
  height: 32px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  cursor: pointer;
`;

export const ShowMoreGroups = styled(GroupCard)`
  cursor: pointer;
  &:hover {
    border: 1px solid black;
    color: black;
  }
`;

export const AddButton = styled(ShowMoreGroups)`
  color: var(--color-gray-text);
`;

export const NameWrapper = styled.div`
  max-height: 90px;
  padding: 25px 35px;
  display: flex;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div`
  display: flex;
  border-top: 1px solid var(--color-gray-dark);
  height: 100%;
`;

export const NameBlock = styled.div`
  display: flex;
  div:nth-child(2) {
    font-weight: 500;
    font-size: 19px;
    line-height: 20px;
  }
  height: max-content;
  align-items: center;
  column-gap: 20px;
`;

export const CompanyTag = styled.div`
  padding: 5px 20px 7px;
  /* width: 101px; */
  height: 32px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
`;

export const ContactSideBar = styled.div`
  width: 360px;
  min-width: 360px;
  height: 100%;
  border-right: 1px solid var(--color-gray-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Tabs = styled.div<{ gap?: number }>`
  display: flex;
  column-gap: ${({ gap }) => (gap ? gap : 20)}px;
  padding: 25px 35px;
  height: 66px;
  width: 100%;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const Tab = styled.div<{ active?: boolean }>`
  display: flex;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ active }) => (active ? 'var(--color-blue)' : 'var(--color-classic-black)')};
  border-bottom: 1px solid ${({ active }) => (active ? 'var(--color-blue)' : 'none')};
  cursor: pointer;
`;

export const ActionsBlock = styled.div`
  width: 100%;
  height: 100%;
`;

export const Count = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: var(--color-gray-text);
  margin-left: 10px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const ActionButton = styled.button`
  display: flex;
  padding: 10px 15px 10px 12px;
  column-gap: 10px;
  margin: 0;
  background: none;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;

export const FieldTitle = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-black);
  column-gap: 8px;
  display: flex;
  margin-top: 9px;

  svg {
    position: absolute;
    right: 10px;
    top: 2px;
  }
`;

export const Pin = styled.div`
  padding: 4px;
  width: 16px;
  height: 16px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-dark);
  border-radius: 2px;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: var(--color-mybase-gray-text);
`;

export const BottomWrapper = styled.div`
  background: white;
  display: flex;
  border-top: 1px solid var(--color-gray-dark);
  padding: 20px 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  column-gap: 20px;

  button {
    padding: 6px;
  }
  button:last-child {
    border: none;
  }
`;

export const BackButton = styled(DefaultButton)`
  cursor: pointer;
`;
