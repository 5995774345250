import { createAsyncThunk } from '@reduxjs/toolkit';
import { checkCodeApi } from 'shared/api';
import { getMessageFromError } from '../lib';

type PayloadType = {
  code: string;
  successCallback?: () => void;
  errorCallback?: (msg: { name: string; message: string }) => void;
};

export const checkCodeAction = createAsyncThunk<object, PayloadType>('user/chekcode', async (payload, { dispatch }) => {
  const { code, errorCallback, successCallback } = payload;
  try {
    const response = await checkCodeApi({ code });

    if (response.status === 200) {
      successCallback && successCallback();
    }
  } catch (err) {
    const msg = getMessageFromError(err);
    errorCallback && errorCallback(msg);
  }
});
