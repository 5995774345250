import { AxiosPromise } from 'axios';
import { IContact } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  page: number;
  limit?: number;
  search?: string;
  sort?: 'asc' | 'desc' | null;
  order_by?: string | null;
  _groupId?: string;
  filters?: any;
};

type Response = {
  result: { list: IContact[]; total: number };
  errors: IError[];
};

export const getContactsApi = ({
  wsId,
  page,
  limit,
  search,
  sort,
  order_by,
  _groupId,
  filters,
}: Request): AxiosPromise<Response> => {
  const size = limit || 10;
  const address = search ? `ws/${wsId}/contacts/search` : `ws/${wsId}/contacts`;

  const otherParams = filters
    ? {
        'filter[inArchive]': false,
        'filter[_groupId]': _groupId,
      }
    : {
        inArchive: false,
        _groupId: _groupId,
      };

  return apiInstance.get(address, {
    params: {
      q: search ? search : null,
      'pagination[page]': page ? page : 1,
      'pagination[limit]': limit || 10,
      'pagination[size]': size,
      'pagination[sort]': sort ? sort : null,
      'pagination[order_by]': order_by ? order_by : '_createdOn',
      ...otherParams,
      ...filters,
    },
  });
};
