import React from 'react';
import { RestorePasswordRoutes } from 'pages/Auth/RestorePasswordPage/model/types';
import { Envelope } from 'shared/lib/Icons';
import { IconWrapper, Label, Wrapper } from '../styled';

type Props = {
  handleRedirect: (route: RestorePasswordRoutes) => void;
};

export const CheckCode: React.FC<Props> = () => {
  return (
    <Wrapper>
      <Label>Забыли пароль?</Label>
      <IconWrapper>
        <Envelope />
      </IconWrapper>
      <p>На ваш емаил отправлена ссылка восстановления пароля</p>
      <p>
        Следуйте указаниям
        <br />в этом письме
      </p>
    </Wrapper>
  );
};
