import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { ModalType, TableContactsType, selectWs, useAppDispatch } from 'processes/store';
import { useModal } from 'shared/hooks';
import { deleteContactAction } from './model';
import { GroupsBlock, SortBlock, UserBlock } from './ui';
import { HeaderBlock, TableWrapper, TrBodyTable, TrHeadTable } from './styled';

type Props = {
  list: TableContactsType[];
  order_by?: string | null;
  sort?: 'asc' | 'desc' | null;
  setSort?: (e?: 'asc' | 'desc' | null) => void;
  setOrder_by?: (e?: string | null) => void;
};

export const TableContacts: React.FC<Props> = ({ list = [], sort, setSort, order_by, setOrder_by }) => {
  const { ws } = useSelector(selectWs);

  const { showModal } = useModal();
  const dispatch = useAppDispatch();

  const columns = useMemo(
    () => [
      {
        Header: 'Контакт',
        accessor: 'contact' as const,
        sortType: 'string',
      },
      {
        Header: 'Компания',
        accessor: 'companies' as const,
      },
      {
        Header: 'Емаил',
        accessor: 'email' as const,
      },
      {
        Header: 'Телефон',
        accessor: 'phone' as const,
      },
      {
        Header: 'Группы',
        accessor: 'groups' as const,
      },
    ],
    [],
  );

  const handleClickSort = (sort?: 'asc' | 'desc', order_by?: string) => {
    setSort && setSort(sort);
    setOrder_by && setOrder_by(order_by);
  };

  const tableInstance = useTable({ columns, data: list });
  const { headerGroups, rows, prepareRow } = tableInstance;

  const deleteContact = (id: string, title: string) => {
    showModal(ModalType.DeleteElement, {
      id,
      title,
      onClick: () => {
        dispatch(deleteContactAction({ wsId: ws._id, id }));
      },
    });
  };

  return (
    <TableWrapper>
      <thead>
        {headerGroups.map(({ id, headers }, i) => (
          <TrHeadTable key={id || i + 1}>
            {headers.map(({ id, render }) => (
              <th key={id}>
                {id === 'contact' && (
                  <HeaderBlock>
                    <div>{render('Header')}</div>
                    <SortBlock sort={order_by === 'title' ? sort : null} order_by='title' onClick={handleClickSort} />
                  </HeaderBlock>
                )}
                {id === 'companies' && (
                  <HeaderBlock>
                    <div>{render('Header')}</div>
                    <SortBlock
                      sort={order_by === 'companies' ? sort : null}
                      order_by='companies'
                      onClick={handleClickSort}
                    />
                  </HeaderBlock>
                )}
                {id === 'email' && (
                  <HeaderBlock>
                    <div>{render('Header')}</div>
                    <SortBlock sort={order_by === 'emails' ? sort : null} order_by='emails' onClick={handleClickSort} />
                  </HeaderBlock>
                )}
                {id !== 'contact' && id !== 'companies' && id !== 'email' && render('Header')}
              </th>
            ))}
          </TrHeadTable>
        ))}
      </thead>
      <tbody>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <TrBodyTable key={row.id} style={{ zIndex: rows.length - index }}>
              {row.cells.map(({ column, value }) => (
                <td key={column.id}>
                  {column.id === 'contact' && (
                    <UserBlock
                      id={value.id}
                      avatar={value.avatar}
                      fullName={value.title}
                      email={list.find(el => el.contact.id === value.id)?.email}
                      link={`http://localhost:3000/ws/${ws._id}/contacts/${value.id}`}
                      deleteContact={() => deleteContact(value.id, value.title)}
                    />
                  )}
                  {column.id === 'groups' && <GroupsBlock contact={value} />}
                  {column.id === 'phone' && <div>{value || '—'}</div>}
                  {column.id === 'companies' && <div>{value || '—'}</div>}
                  {column.id === 'email' && <div>{value || '—'}</div>}
                </td>
              ))}
            </TrBodyTable>
          );
        })}
      </tbody>
    </TableWrapper>
  );
};
