import styled from 'styled-components';
import { BlueBlock, WhiteBlock } from '../styled';

export const InputWrapper = styled.div`
  margin-bottom: 25px;
`;

export const DivBlock = styled.div`
  max-height: 623px;
  width: 100%;
  display: flex;
  position: relative;
`;

export const CustomWhiteBlock = styled(WhiteBlock)`
  padding: 40px 65px 50px;
`;

export const CustomBlueBlock = styled(BlueBlock)`
  padding: 60px;
  overflow: hidden;
  img {
    height: 563px;
  }
`;

export const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const Avatar = styled.div`
  width: 58px;
  height: 58px;
  margin-right: 20px;
  padding: 19px;
  background: #ffffff;
  border: 1px solid #d9dfeb;
  border-radius: 29px;
  cursor: pointer;
`;

export const BlockInfo = styled.div`
  width: 180px;
  div:first-child {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #0b0b0a;
    margin-bottom: 9px;
  }
  div:last-child {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #6a7b9b;
  }
`;

export const GoBack = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: 0;
  top: -72px;
  padding: 10px;
  cursor: pointer;

  background: #ffffff;
  border-radius: 6px;
`;

export const Title = styled.div`
  margin-top: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;
