import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTable } from 'react-table';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { ITouches, selectWs } from 'processes/store';
import { SortBlock } from '../../../features/Group/ui';
import {
  Count,
  CustomBlock,
  DivWrapper,
  HeaderBlock,
  StatusBlock,
  TableWrapper,
  TrBodyTable,
  TrHeadTable,
} from './styled';
import { useNavigate } from 'react-router-dom';
import { ROUTE_WS } from 'shared/lib/routes';

type Props = {
  touchesList: ITouches[];
};

export const TouchesTable: React.FC<Props> = ({ touchesList }) => {
  const { ws } = useSelector(selectWs);

  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id' as const,
      },
      {
        Header: 'Дата и время',
        accessor: 'date' as const,
      },
      {
        Header: 'Откуда',
        accessor: 'from' as const,
      },
      {
        Header: 'Куда',
        accessor: 'where' as const,
      },
      {
        Header: 'Имя',
        accessor: 'name' as const,
      },
      {
        Header: 'Компания',
        accessor: 'companies' as const,
      },
      // {
      //   Header: 'Длительность',
      //   accessor: 'duration' as const,
      // },
      {
        Header: 'Статус',
        accessor: 'status' as const,
      },
      // {
      //   Header: 'Результат',
      //   accessor: 'result' as const,
      // },
      // {
      //   Header: 'Аудио',
      //   accessor: 'audio' as const,
      // },
    ],
    [],
  );

  const mapedList = touchesList.map(el => {
    const objectInfo = el._relatedObjectInfo || [];
    const date = format(el._createdOn, 'dd MMM yyyy HH:mm', { locale: ru });
    const contactId = typeof el._relatedObject._id === 'string' ? el._relatedObject._id : el._relatedObject._id[0];
    const whereValue =
      el.action === 'emailSent' ? (typeof el.misc?.target === 'string' ? el.misc?.target : el.misc?.target[0]) : '';

    return {
      id: { id: el._id, objIndex: el.objIndex, contactId },
      date,
      from: el.action === 'emailReceived' ? el.misc.from : '-',
      where: {
        value: whereValue,
        count: objectInfo.length || 0,
      },
      name: {
        value: objectInfo[0]?.title || '',
        count: objectInfo.reduce((total, el) => (el.title ? total + 1 : total), 0),
      },
      companies: {
        value: objectInfo[0]?.companies[0]?.value || '-',
        count: objectInfo.reduce((total, el) => total + el.companies.length, 0),
      },
      status: { action: el.action, value: el.action === 'emailSent' ? 'Отправлено' : 'Получено' },
    };
  });

  const tableInstance = useTable({ columns, data: mapedList });
  const { headerGroups, rows, prepareRow } = tableInstance;
  return (
    <DivWrapper>
      <TableWrapper>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <TrHeadTable key={headerGroup.id || i + 1}>
              {headerGroup.headers.map(column => (
                <th key={column.id}>
                  {column.id !== 'id' && column.id !== 'where' && column.id !== 'audio' ? (
                    <HeaderBlock>
                      <div>{column.render('Header')}</div>
                      <SortBlock order_by='' onClick={() => {}} />
                    </HeaderBlock>
                  ) : (
                    column.render('Header')
                  )}
                </th>
              ))}
            </TrHeadTable>
          ))}
        </thead>
        <tbody>
          {rows.map(row => {
            prepareRow(row);
            const { contactId } = row.values.id;
            return (
              <TrBodyTable
                onClick={() => navigate(`${ROUTE_WS}/${ws._id}/contacts/${contactId}?touch=${row.values.id.id}`)}
                key={row.id}
              >
                {row.cells.map(({ column, value }) => (
                  <td key={column.id}>
                    {column.id === 'id' && (value ? value?.objIndex : '-')}
                    {column.id === 'status' &&
                      (value ? (
                        <StatusBlock color={value?.action === 'emailReceived' ? '#F9F2BB' : '#cfe9da'}>
                          {value?.value}
                        </StatusBlock>
                      ) : (
                        '-'
                      ))}
                    {column.id === 'where' &&
                      (value ? (
                        <CustomBlock>
                          <div>{value?.value}</div>
                          {value?.count > 1 && <Count>+{value?.count - 1}</Count>}
                        </CustomBlock>
                      ) : (
                        '-'
                      ))}
                    {column.id === 'name' &&
                      (value ? (
                        <CustomBlock>
                          <div>{value?.value}</div>
                          {value?.count > 1 && <Count>+{value?.count - 1}</Count>}
                        </CustomBlock>
                      ) : (
                        '-'
                      ))}
                    {column.id === 'companies' &&
                      (value ? (
                        <CustomBlock>
                          <div>{value?.value}</div>
                          {value?.count > 1 && <Count>+{value?.count - 1}</Count>}
                        </CustomBlock>
                      ) : (
                        '-'
                      ))}
                    {/* {column.id === 'audio' &&
                      (value ? (
                        <AudioBlock>
                          <IconButton icon={<DownloadIcon />} />
                          <IconButton icon={<PlayIcon />} />
                        </AudioBlock>
                      ) : (
                        '-'
                      ))} */}
                    {column.id !== 'audio' &&
                      column.id !== 'status' &&
                      column.id !== 'name' &&
                      column.id !== 'where' &&
                      column.id !== 'id' &&
                      column.id !== 'companies' &&
                      (value ? value : '-')}
                  </td>
                ))}
              </TrBodyTable>
            );
          })}
        </tbody>
      </TableWrapper>
    </DivWrapper>
  );
};
