import { FC, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import bg from 'shared/img/Lazy 3.png';
import { BackArrow } from 'shared/lib/Icons';
import { ROUTE_ROOT } from 'shared/lib/routes';
import { Divider, Step } from 'shared/ui';
import { RegistrationRoutes } from 'pages/Auth/RegistrationPage/model/types';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { useAppDispatch } from 'processes/store';
import { generateTextError } from './lib';
import {
  CustomBlueBlock,
  CustomButton,
  CustomWhiteBlock,
  DivBlock,
  GoBack,
  SkipButton,
  Text,
  Title,
  InputWrapper,
} from './styled';

type Props = {
  handleRedirect: (route: RegistrationRoutes) => void;
};

export const RegistrationCollaborateTeam: FC<Props> = ({ handleRedirect }) => {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<{ email1: string; email2: string }>({
    defaultValues: {
      email1: '',
      email2: '',
    },
  });

  const onSubmit: SubmitHandler<{ email1: string; email2: string }> = useCallback(
    data => {
      navigate(ROUTE_ROOT);
      //setIsLoading(true);
      /*dispatch(
        signUpAction({
          email: data.email,
          password: data.password,
          fullName: 'test test',
          successCallback: () => {
            setIsLoading(false);
            handleSaveEmail && handleSaveEmail(data.email);
            handleRedirect(RegistrationRoutes[RegistrationRoutes.checkEmail]);
          },
          errorCallback: (err: { name: string; message: string }) => {
            if (err.name === 'email') {
              setError(err.name, { type: 'custom', message: err.message });
            }
            return setIsLoading(false);
          },
        }),
      );*/
    },
    [navigate],
  );

  return (
    <DivBlock>
      {location.hash !== '#space' && (
        <GoBack onClick={() => handleRedirect(RegistrationRoutes[RegistrationRoutes.customizeSpace])}>
          <BackArrow />
        </GoBack>
      )}
      <CustomWhiteBlock>
        <Step count={5} finishStep={5} />
        <Title>Сотрудничайте со своей командой</Title>

        <Text>Чем больше ваши товарищи по команде используют MyBase, тем мощнее он становится.</Text>
        <Text fontSize={12}>
          Мы невероятно упрощаем сотрудничество с вашей командой. От анализа отношений и обмена сообщениями до
          построения рабочего процесса, управления задачами и комментариев.
        </Text>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <AuthInput
            name='email1'
            label='Приглаcить людей'
            placeholder='example@email.com'
            register={register}
            rules={{
              pattern: /[a-zA-Z0-9+._%-+]{1,256}@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+/,
              required: true,
              maxLength: 250,
            }}
            hasError={!!errors.email1}
            textError={generateTextError({
              name: 'email1',
              type: errors.email1?.type,
              message: errors.email1?.message,
            })}
          />
          <InputWrapper>
            <AuthInput
              name='email2'
              label='Приглаcить людей'
              placeholder='example@email.com'
              register={register}
              rules={{
                pattern: /[a-zA-Z0-9+._%-+]{1,256}@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+/,
                maxLength: 250,
              }}
              hasError={!!errors.email2}
              textError={generateTextError({
                name: 'email2',
                type: errors.email2?.type,
                message: errors.email2?.message,
              })}
            />
          </InputWrapper>
          <CustomButton label='Отправить приглашения' isLoading={isLoading} />
        </form>
        <SkipButton onClick={() => navigate(ROUTE_ROOT)}>Пропустить</SkipButton>
      </CustomWhiteBlock>
      <CustomBlueBlock>
        <img src={bg} alt='' />
      </CustomBlueBlock>
    </DivBlock>
  );
};
