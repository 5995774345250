import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { InitialModalState, useAppDispatch } from 'processes/store';
import { Button, Input, ModalCommon } from 'shared/ui';
import { SmsIcon, WSModal } from 'shared/lib/Icons';
import { changeEmailAction } from './model/changeEmailAction';
import { getUserInfoAction } from './model';
import { Actions, CreateButton, IconBlock, Label, TitleField } from './styled';

type Props = {
  onCloseModal: () => void;
  params?: InitialModalState['params'];
};

export const ChangeEmailModal: React.FC<Props> = ({ onCloseModal, params }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState<string | undefined>(params?.email);
  const [code, setCode] = useState<string | undefined>('');
  const [error, setError] = useState<{ name: string; message: string }>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params?.email) {
      setEmail(params.email);
    }
  }, [params?.email]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, type: 'email' | 'code') => {
    type === 'email' ? setEmail(e.target.value) : setCode(e.target.value);
    setError(undefined);
  };

  const changeEmail = useCallback(() => {
    dispatch(
      changeEmailAction({
        email,
        code,
        successCallback: () => {
          step === 1 && setEmail(undefined);
          step === 1 ? setStep(2) : onCloseModal?.();
          step === 2 && dispatch(getUserInfoAction({}));
        },
        errorCallback: err => err && setError(err),
      }),
    );
  }, [dispatch, onCloseModal, email, code, step]);

  return (
    <ModalCommon header={<WSModal />}>
      <Label>{step === 1 ? 'Сменить емаил' : 'Введите код'}</Label>
      {step === 1 ? (
        <TitleField>
          <Input
            name='email'
            value={email}
            placeholder='Новый емаил'
            onChange={e => handleChange(e, 'email')}
            textError={error?.name === 'email' ? error.message : undefined}
          />
          <IconBlock>
            <SmsIcon />
          </IconBlock>
        </TitleField>
      ) : (
        <TitleField>
          <Input
            name='code'
            value={code}
            onChange={e => handleChange(e, 'code')}
            placeholder='Введите код'
            textError={error?.name === 'code' ? error.message : undefined}
          />
        </TitleField>
      )}
      <Actions>
        <Button label='Отмена' onClick={onCloseModal} />
        <CreateButton label='Изменить' onClick={changeEmail} />
      </Actions>
    </ModalCommon>
  );
};
