import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestCodeApi } from 'shared/api';
import { getMessageFromError } from '../lib';

type PayloadType = {
  login: string;
  successCallback?: () => void;
  errorCallback?: (msg: { name: string; message: string }) => void;
};

export const requestCodeAction = createAsyncThunk<object, PayloadType>(
  'user/requestcode',
  async (payload, { dispatch }) => {
    const { login, errorCallback, successCallback } = payload;
    try {
      const response = await requestCodeApi({ login });

      if (response.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      const msg = getMessageFromError(err);
      errorCallback && errorCallback(msg);
    }
  },
);
