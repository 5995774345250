import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWs, setContactList, setNeedUpdateContact } from 'processes/store';
import { getContactsApi } from 'shared/api/contacts';

type PayloadType = {
  wsId: IWs['_id'];
  limit?: number;
  sort?: 'asc' | 'desc' | null;
  order_by?: string | null;
  search?: string;
  page: number;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const getContactsAction = createAsyncThunk<object, PayloadType>(
  'get/contacts',
  async (payload, { dispatch }) => {
    const { wsId, page, limit, search, sort, order_by, errorCallback, successCallback } = payload;
    try {
      const response = await getContactsApi({ wsId, page, limit, search, sort, order_by });

      if (response.status === 200) {
        const { list, total } = response.data.result;
        dispatch(setContactList({ contactList: list, total }));
        dispatch(setNeedUpdateContact(false));
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
