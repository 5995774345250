import styled from 'styled-components';
import { alignCenter, flexCol, flexFull, flexRow } from 'shared/lib/mixins';
import { Button } from 'shared/ui';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
  position: relative;
`;

export const Header = styled.header`
  padding: 0 35px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const Title = styled.h1`
  font-size: 19px;
  line-height: 20px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const SearchField = styled.div`
  ${flexFull};
  position: relative;

  button {
    position: absolute;
    top: 4px;
    right: 0;
  }
`;

export const SearchBlock = styled.div`
  max-height: 72px;
  padding: 15px 35px;
`;

export const FooterBlock = styled.footer`
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  padding: 12px 20px 12px 33px;
  max-height: 60px;
  border: 1px solid rgb(217, 223, 235);
  background: white;
`;

export const AddButton = styled(Button)`
  background: none;
  padding: 0;
  div {
    display: flex;
    column-gap: 10px;
    color: black;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const EmptyData = styled.div`
  color: var(--color-mybase-gray-text);
  margin: auto;
  font-size: 20px;
  line-height: 24px;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const SearchInput = styled.input`
  padding: 14px 20px 12px;
  width: 100%;
  height: 42px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
`;

export const DivElement = styled.div`
  overflow: auto;
  height: 100%;
`;
