import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeContactsStatusApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  color?: string;
  title?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const changeStatusAction = createAsyncThunk<object, PayloadType>(
  'change/contactStatus',
  async (payload, { dispatch }) => {
    const { wsId, id, color, title, errorCallback, successCallback } = payload;

    try {
      const response = await changeContactsStatusApi({ wsId, id, color, title });
      if (response.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
