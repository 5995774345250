import React, { PropsWithChildren } from 'react';
import { Containter, Header, Content } from './styled';

type Props = {
  header: string | JSX.Element;
};

export const ModalCommon: React.FC<PropsWithChildren<Props>> = ({ header, children }) => {
  return (
    <Containter>
      <Header>{header}</Header>
      <Content>{children}</Content>
    </Containter>
  );
};
