import styled from 'styled-components';

export const ButtonStyled = styled.button<{ $isEmpty?: boolean; isWarning?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  width: max-content;
  transition: ease-out 0.4s;
  height: 44px;
  padding: 15px 20px 13px;
  background: ${props =>
    props.isWarning ? 'var(--color-error)' : props.$isEmpty ? 'transparent' : 'var(--color-bg-auth-button)'};
  border: ${props => (props.$isEmpty ? '1px solid var(--color-mybase-line-gray);' : '')};

  &:hover,
  &:focus {
    opacity: 0.8;
  }
`;

export const Text = styled.div<{ $isEmpty?: boolean }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: ${props => (props.$isEmpty ? 'var(--color-btn-empty-text)' : 'var(--color-btn-text)')};
`;

export const DefaultButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
`;
