import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { useAppDispatch } from 'processes/store';
import { checkCodeAction } from './model/checkCodeAction';
import { RegistrationRoutes } from '../model';
import { BlueBlock, Button, WhiteBlock } from '../styled';
import { generateTextError } from './lib';
import { Text, TitleBlockWhite, TitleBlockBlue, Wrapper, DivBlock, TextStepTwo, CodeWrapper } from './styled';

type Inputs = {
  code: string;
};

type Props = {
  handleRedirect: (route: RegistrationRoutes) => void;
};

export const RegistrationCheckEmail: FC<Props> = ({ handleRedirect }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      code: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        checkCodeAction({
          code: data.code,
          successCallback: () => {
            setIsLoading(false);
            handleRedirect(RegistrationRoutes[RegistrationRoutes.meetUser]);
          },
          errorCallback: (err: { name: string; message: string }) => {
            if (err.name === 'code') {
              setError(err.name, { type: 'custom', message: err.message });
            }
            return setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, handleRedirect, setError],
  );

  return (
    <Wrapper>
      <DivBlock>
        <WhiteBlock>
          <TitleBlockWhite>
            Проверь <br /> входящие!
          </TitleBlockWhite>
          <TextStepTwo>
            Мы только что отправили вам временный пароль по электронной почте. Пожалуйста, введите его ниже.
          </TextStepTwo>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CodeWrapper>
              <AuthInput
                label='Введите код'
                name='code'
                register={register}
                rules={{ required: true, maxLength: 250 }}
                isPassword
                isFocus={!!watch('code')}
                hasError={!!errors.code}
                placeholder={'Введите код'}
                textError={generateTextError({
                  name: 'code',
                  type: errors.code?.type,
                  message: errors.code?.message,
                })}
              />
            </CodeWrapper>
            <Button label='Продолжить' isLoading={isLoading} />
          </form>
        </WhiteBlock>
        <BlueBlock>
          <TitleBlockBlue>
            Добро <br /> пожаловать  👋 
          </TitleBlockBlue>
          <Text>
            MyBase — это радикально новый тип CRM. Благодаря совершенно новому типу архитектуры данных у вас будут
            профили и записи о каждом взаимодействии в вашей сети за считанные минуты, всегда обновляемые в режиме
            реального времени. <br /> <br /> Вы сможете настроить и создать свою CRM именно так, как вы этого хотите.{' '}
            <br /> <br /> Давай начнем.
          </Text>
        </BlueBlock>
      </DivBlock>
    </Wrapper>
  );
};
