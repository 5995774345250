import React, { useState, useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { WSModal } from 'shared/lib/Icons';
import { Button, ModalCommon } from 'shared/ui';
import { useAppDispatch } from 'processes/store';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { inviteUserAction } from './model/inviteUserAction';
import { CoverField, Label, TitleField, Actions, CreateButton } from './styled';

type Props = {
  onCloseModal: () => void;
};

export const InviteUserModal: React.FC<Props> = ({ onCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const { register, handleSubmit } = useForm<{ email: string }>({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit: SubmitHandler<{ email: string }> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        inviteUserAction({
          email: data.email,
          successCallback: () => {
            setIsLoading(false);
            onCloseModal();
          },
          errorCallback: () => {
            setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, onCloseModal],
  );

  return (
    <ModalCommon header={<WSModal />}>
      <CoverField>
        <Label>Пригласить пользователя</Label>
      </CoverField>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleField>
          <AuthInput
            label='Емаил'
            name='email'
            register={register}
            rules={{
              pattern: /[a-zA-Z0-9+._%-+]{1,256}@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+/,
              required: true,
              maxLength: 250,
            }}
            // hasError={!!errors.email}
            // textError={generateTextError({
            //   name: 'email',
            //   type: errors.email?.type,
            //   message: errors.email?.message,
            // })}
            placeholder='Введите электронную почту'
            // isFocus={!!watch('email')}
          />
        </TitleField>
        <Actions>
          <Button label='Отмена' $isEmpty onClick={onCloseModal} isLoading={isLoading} />
          <CreateButton label='Пригласить' isLoading={isLoading} />
        </Actions>
      </form>
    </ModalCommon>
  );
};
