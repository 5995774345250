import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import bg from 'shared/img/Lazy 2.png';
import { BackArrow, Check } from 'shared/lib/Icons';
import { Divider, Step } from 'shared/ui';
import { RegistrationRoutes } from '../model';
import { CustomBlueBlock, CustomWhiteBlock, DivBlock, SetUpLater, Text, GoBack, Title, CustomButton } from './styled';

type Props = {
  handleRedirect: (route: RegistrationRoutes) => void;
};

export const RegistrationSynchronization: FC<Props> = ({ handleRedirect }) => {
  const location = useLocation();

  useEffect(() => {
    location.hash === '#space' && handleRedirect(RegistrationRoutes[RegistrationRoutes.customizeSpace]);
  }, [location, handleRedirect]);

  const handleClick = () => {
    handleRedirect(RegistrationRoutes[RegistrationRoutes.customizeSpace]);
  };

  const handleAddGoogle = () => {
    localStorage.setItem('attach', 'true');
    window.location.assign(`${process.env.REACT_APP_API_URL}user/auth/google/callback/web?inviteToken=&referalToken=`);
  };

  return (
    <DivBlock>
      <GoBack onClick={() => handleRedirect(RegistrationRoutes[RegistrationRoutes.space])}>
        <BackArrow />
      </GoBack>
      <CustomWhiteBlock>
        <Step count={3} finishStep={5} />
        <Title>Синхронизируйте почту и календарь</Title>
        <Text>
          Подключив свою электронную почту и календарь, вы сможете мгновенно создавать профили для всей вашей сети.
        </Text>
        <Text>
          <Check /> Каждое взаимодействие будет автоматически сортироваться
        </Text>
        <Text>
          <Check /> Каждый профиль обогащен <br /> сотнями точек данных
        </Text>
        <Text>
          <Check /> Каждая запись всегда <br /> будет актуальной
        </Text>
        <Divider />
        <CustomButton onClick={handleAddGoogle} label='Добавить Google account' />
        {/* <Button label='Добавить Mail account' /> */}
        <SetUpLater onClick={handleClick}>Настроить позже</SetUpLater>
      </CustomWhiteBlock>
      <CustomBlueBlock>
        <img src={bg} alt='' />
      </CustomBlueBlock>
    </DivBlock>
  );
};
