import { flexRow, spaceBetween } from 'shared/lib/mixins';
import { Button } from 'shared/ui';
import styled from 'styled-components';

export const Label = styled.div`
  font-size: 19px;
  line-height: 26px;
  font-family: var(--font-medium);
  margin-bottom: 15px;
`;

export const TitleField = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

export const Actions = styled.div`
  ${flexRow};
  ${spaceBetween};
`;

export const CreateButton = styled(Button)`
  min-width: 168px;
`;

export const IconBlock = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;
