import { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ROUTE_LOGIN } from 'shared/lib/routes';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { AuthLink } from 'pages/Auth/ui/AuthLink';
import { useAppDispatch } from 'processes/store';
import { signUpAction } from './model/signUpAction';
import { RegistrationRoutes } from '../model';
import { BlueBlock, WhiteBlock, Button } from '../styled';
import { generateTextError } from './lib';
import {
  CustomLineWithText,
  Text,
  TitleBlockBlue,
  Wrapper,
  DivBlock,
  ContinueButton,
  TextStepOne,
  EmailWrapper,
} from './styled';

type Inputs = {
  email: string;
  password: string;
};

type Props = {
  handleSaveEmail?: (email: string) => void;
  handleRedirect: (route: RegistrationRoutes) => void;
};

export const RegistrationGreetings: FC<Props> = ({ handleRedirect, handleSaveEmail }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        signUpAction({
          email: data.email,
          password: data.password,
          fullName: 'test test',
          successCallback: () => {
            setIsLoading(false);
            handleSaveEmail && handleSaveEmail(data.email);
            handleRedirect(RegistrationRoutes[RegistrationRoutes.checkEmail]);
          },
          errorCallback: (err: { name: string; message: string }) => {
            if (err.name === 'email') {
              setError(err.name, { type: 'custom', message: err.message });
            }
            return setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, handleRedirect, handleSaveEmail, setError],
  );

  return (
    <Wrapper>
      <DivBlock>
        <WhiteBlock>
          <Button label='Войти через Google' isGoogle />
          <CustomLineWithText text='Или' />
          <form onSubmit={handleSubmit(onSubmit)}>
            <EmailWrapper>
              <AuthInput
                label='Емаил'
                name='email'
                register={register}
                rules={{
                  pattern: /[a-zA-Z0-9+._%-+]{1,256}@[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}(.[a-zA-Z0-9][a-zA-Z0-9\-]{0,25})+/,
                  required: true,
                  maxLength: 250,
                }}
                hasError={!!errors.email}
                textError={generateTextError({
                  name: 'email',
                  type: errors.email?.type,
                  message: errors.email?.message,
                })}
                placeholder='Ваша электронная почта'
                isFocus={!!watch('email')}
              />
            </EmailWrapper>
            <AuthInput
              label='Пароль'
              name='password'
              register={register}
              rules={{ required: true, maxLength: 250 }}
              hasError={!!errors.password}
              textError={generateTextError({
                name: 'password',
                type: errors.password?.type,
                message: errors.password?.message,
              })}
              placeholder='Введите пароль'
              isFocus={!!watch('password')}
              isPassword
            />
            <ContinueButton label='Продолжить' isLoading={isLoading} />
            <AuthLink to={ROUTE_LOGIN} text='Авторизация' />
          </form>
          <TextStepOne>
            Вставляя свой адрес электронной почты, вы подтверждаете, что соглашаетесь с тем, что MyBase будет
            связываться с вами по поводу наших продуктов и услуг. Вы можете отказаться в любое время, нажав «отписаться»
            в наших электронных письмах. Узнайте больше о том, как мы используем данные, в нашей политике
            конфиденциальности.
          </TextStepOne>
        </WhiteBlock>
        <BlueBlock>
          <TitleBlockBlue>
            Добро <br /> пожаловать  👋 
          </TitleBlockBlue>
          <Text>
            MyBase — это радикально новый тип CRM. Благодаря совершенно новому типу архитектуры данных у вас будут
            профили и записи о каждом взаимодействии в вашей сети за считанные минуты, всегда обновляемые в режиме
            реального времени. <br /> <br /> Вы сможете настроить и создать свою CRM именно так, как вы этого хотите.{' '}
            <br /> <br /> Давай начнем.
          </Text>
        </BlueBlock>
      </DivBlock>
    </Wrapper>
  );
};
