import { IEmail } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
  emailId: string;
  ids: { _objectId: string }[];
};

type Response = {
  result: IEmail;
  errors: IError[];
};
export const chooseEmailReceiversApi = ({ wsId, emailId, ids }: Request): AxiosPromise<Response> => {
  return apiInstance.post(`ws/${wsId}/emails/${emailId}/email_receivers`, ids);
};
