import styled from 'styled-components';
import { Divider } from 'shared/ui';

export const DivWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const TableWrapper = styled.table`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  thead {
    color: var(--color-mybase-gray-text);
  }

  th:nth-child(2),
  td:nth-child(2) {
    max-width: 280px;
  }
`;

export const TrHeadTable = styled.tr`
  position: relative;
  height: 60px;
  border: 1px solid var(--color-mybase-line-gray);
  border-left: none;

  th:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  th {
    background: var(--color-white);
    text-align: start;
    padding: 20px;
  }
  th {
    border-right: 1px solid var(--color-mybase-line-gray);
  }
`;

export const TrBodyTable = styled(TrHeadTable)`
  height: 66px;

  td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    min-width: 285px;
  }
  td:nth-child(2) {
    min-width: 250px;
  }
  td:nth-child(3),
  td:nth-child(6),
  td:nth-child(7) {
    min-width: 200px;
  }
  td:nth-child(4) {
    min-width: 220px;
  }
  td:nth-child(5) {
    min-width: 170px;
  }

  td {
    background: var(--color-white);
  }
  td {
    border-right: 1px solid var(--color-mybase-line-gray);
  }
`;

export const HeaderContact = styled.div<{ sort?: string | null }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column-reverse;
    padding: 5px 10px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &:hover {
      background-color: var(--color-gray-btn-hv);
      svg {
        fill: var(--color-gray-icon-btn-hv);
      }
    }
  }
`;

export const AccessText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DropdownList = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  min-width: 200px;
`;

export const Text = styled.div<{ color?: string; border?: boolean; active?: boolean }>`
  display: flex;
  font-style: normal;
  column-gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
  color: ${({ color }) => (color ? color : 'black')};
  border-bottom: ${({ border }) => (!border ? 'none' : '1px solid #d9dfeb')};
  align-items: center;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
    
    `}

  &:hover {
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const DividerCustom = styled(Divider)`
  border: none;
  background-color: #d9dfeb;
  height: 3px;
`;
