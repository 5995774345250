import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div>
      <div>Ничего не найдено</div>
      <button onClick={handleBack}>Вернуться назад</button>
    </div>
  );
};
