import { DefaultButton } from 'shared/ui';
import styled from 'styled-components';

export const ShowButton = styled(DefaultButton)`
  display: none;
  position: absolute;
  cursor: pointer;
  right: 0;
`;

export const EmptyBlock = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 97%;
  padding: 20px;
  position: relative;

  &:hover {
    ${ShowButton} {
      display: block;
    }
  }
`;

export const Chip = styled.div<{ color?: string }>`
  min-width: max-content;
  display: flex;
  column-gap: 7px;
  padding: 7px 10px 7px 7px;
  border: ${({ color }) => (color ? 'none' : '1px solid var(--color-gray-dark)')};
  background: ${({ color }) => (color ? color : 'white')};
  border-radius: 6px;
  cursor: pointer;
  height: 30px;
`;

export const Input = styled.input`
  &:focus {
    ${EmptyBlock} {
      display: none;
    }
  }
`;

export const Text = styled.div`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  padding: 6px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const AddButton = styled(DefaultButton)`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 10px 15px 10px 22px;
  color: var(--color-blue);

  &:hover {
    background-color: var(--color-gray-btn-hv);
    border-radius: 0 0 10px 10px;
    svg {
      fill: var(--color-gray-icon-btn-hv);
    }
  }

  svg {
    path {
      fill: var(--color-blue);
    }
  }
`;

export const StatusesBlock = styled.div`
  padding: 15px 0 0;
  background: var(--color-white);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  min-width: 250px;
`;

export const Label = styled.div`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 20px;
  color: var(--color-mybase-gray-text);
  margin-bottom: 10px;
`;

export const SettingsBtn = styled(DefaultButton)`
  cursor: pointer;
`;

export const FieldBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* gap: 5px; */
  /* padding-left: 20px; */

  input {
    width: 100%;
  }
`;
