import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMessageFromError } from 'features/Auth/LoginForm/lib';
import { confirmEmailCodeApi, getEmailCodeApi } from 'shared/api';

type PayloadType = {
  email?: string;
  code?: string;
  successCallback?: () => void;
  errorCallback?: (err?: { name: string; message: string }) => void;
};

export const changeEmailAction = createAsyncThunk<object, PayloadType>('change/email', async payload => {
  const { email, code, successCallback, errorCallback } = payload;
  try {
    if (email) {
      const response = await getEmailCodeApi({ email });
      if (response.status === 200) {
        successCallback && successCallback();
      }
    }
    if (code) {
      const response = await confirmEmailCodeApi({ code });
      if (response.status === 200) {
        successCallback && successCallback();
      }
    }
  } catch (err) {
    const msg = getMessageFromError(err);
    errorCallback && errorCallback(msg);
  }
});
