import styled from 'styled-components';
import { flexCol, flexRow, flexFull, alignCenter, alignMiddle, spaceBetween } from 'shared/lib/mixins';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
`;

export const Header = styled.header`
  padding: 0 35px;
  column-gap: 10px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const Title = styled.h1`
  font-size: 19px;
  line-height: 20px;
  font-family: var(--font-medium);
`;

export const ContentWrapper = styled.div`
  ${flexCol};
  ${flexFull};
  ${alignCenter};
  padding: 50px 0 0;
`;

export const Content = styled.div`
  width: 800px;
`;

export const WorkPlaceEdit = styled.div`
  ${flexCol};
  row-gap: 30px;
`;

export const EditSection = styled.div`
  ${flexCol};
  row-gap: 15px;
`;

export const Label = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-label);
`;

export const EditBlock = styled.div`
  padding: 25px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 10px;
`;

export const WorkPlaceFields = styled.div`
  ${flexCol};
  row-gap: 25px;
`;

export const WorkPlaceTop = styled.div`
  ${flexRow};
  ${spaceBetween};
  padding-bottom: 25px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const WorkPlaceTitle = styled.div`
  ${flexCol};
  row-gap: 10px;
`;

export const WorkPlaceTitleLabel = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

export const EditLogo = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 20px;
`;

export const LogoLabel = styled.span`
  font-size: 19px;
  font-family: var(--font-medium);
`;

export const LogoPreview = styled.div`
  width: 58px;
  height: 58px;
  flex: 0 0 58px;
  ${flexRow};
  ${alignMiddle};
  border: 1px solid var(--color-gray-dark);
  border-radius: 50%;
  svg {
    fill: var(--color-gray-text);
  }
`;

export const LoadLogo = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 15px;
`;

export const LoadLogoLabel = styled.span`
  font-size: 12px;
  line-height: 19px;
  text-align: right;
  color: var(--color-gray-label);
  width: 150px;
`;

export const FreeRates = styled.span`
  height: 144px;
  ${flexRow};
  ${alignMiddle};
  font-size: 14px;
  color: var(--color-gray-text);
`;

export const WorkPlaceDelete = styled.div`
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
`;

export const DeleteLabel = styled.span`
  width: 345px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-label);
`;
