import { css } from 'styled-components';

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;
export const flexCol = css`
  display: flex;
  flex-direction: column;
`;
export const flexWrap = css`
  flex-wrap: wrap;
`;
export const flexNowrap = css`
  flex-wrap: nowrap;
`;
export const spaceBetween = css`
  justify-content: space-between;
`;
export const spaceCenter = css`
  justify-content: center;
`;
export const spaceEnd = css`
  justify-content: flex-end;
`;
export const spaceStart = css`
  justify-content: flex-start;
`;
export const alignStart = css`
  align-items: flex-start;
`;
export const alignCenter = css`
  align-items: center;
`;
export const alignEnd = css`
  align-items: flex-end;
`;
export const alignMiddle = css`
  justify-content: center;
  align-items: center;
`;
export const selfStart = css`
  align-self: flex-start;
`;
export const flexAuto = css`
  flex: 0 0 auto;
`;
export const flexFull = css`
  flex: 1 1 auto;
`;
