import { createAsyncThunk } from '@reduxjs/toolkit';
import { IEmail } from 'processes/store';
import { getEmailApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  successCallback?: (email: IEmail) => void;
  errorCallback?: () => void;
};

export const getEmailAction = createAsyncThunk<object, PayloadType>('get/email', async (payload, { dispatch }) => {
  const { wsId, id, errorCallback, successCallback } = payload;
  try {
    const response = await getEmailApi({ wsId, id });
    if (response.status === 200) {
      successCallback && successCallback(response.data.result);
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
