import { AuthButton } from 'pages/Auth/ui/AuthButton';
import styled from 'styled-components';

export const WhiteBlock = styled.div`
  background: white;
  width: 50%;
  border-radius: 20px 0 0 20px;
  padding: 40px 65px 50px;
`;

export const BlueBlock = styled.div`
  background: linear-gradient(135deg, #002fa4 0%, #295dff 45.83%, #babff8 100%);
  width: 50%;
  border-radius: 0 20px 20px 0;
  padding: 90px;

  img {
    border-radius: 0 0 20px 0;
  }
`;

export const Button = styled(AuthButton)`
  width: 100%;
`;
