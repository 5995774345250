import React, { ReactNode, useEffect, useRef } from 'react';
import { Instance, createPopper } from '@popperjs/core';
import { ButtonCustom, DropdownContent, Label, Wrapper } from './styled';

type Props = {
  open: boolean;
  setOpen: (e: boolean) => void;
  label?: ReactNode;
  labelIcon?: JSX.Element;
  children?: ReactNode;
  placement?: string;
  width?: string;
  offset?: number[];
  ref?: any;
};

export const SettingsDropdown: React.FC<Props> = ({
  open,
  setOpen,
  label,
  labelIcon,
  width,
  children,
  placement,
  offset,
}) => {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  let popperInstance: Instance | null = null;

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        dropdownRef.current && //@ts-ignore
        !dropdownRef.current.contains(event.target) &&
        triggerRef.current && //@ts-ignore
        !triggerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleDocumentClick, { capture: true });
    };
  }, [setOpen]);

  useEffect(() => {
    if (open) {
      //@ts-ignore
      popperInstance = createPopper(triggerRef.current, dropdownRef.current, {
        placement: placement ? placement : 'bottom-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: offset ? offset : [0, 10],
            },
          },
        ],
      });
    } else if (popperInstance) {
      popperInstance.destroy();
      popperInstance = null;
    }
  }, [open]);

  const handleDropdownToggle = () => {
    setOpen(!open);
  };

  return (
    <Wrapper width={width}>
      {!!label ? (
        <Label ref={triggerRef}>{label}</Label>
      ) : (
        <ButtonCustom
          ref={triggerRef}
          onClick={e => {
            e.preventDefault();
            handleDropdownToggle();
          }}
        >
          {labelIcon}
        </ButtonCustom>
      )}
      {open && <DropdownContent ref={dropdownRef}>{children}</DropdownContent>}
    </Wrapper>
  );
};
