import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { IStatus } from 'processes/store';
import { SettingsDropdown } from 'shared/ui';
import { PlusIcon } from 'shared/lib/Icons';
import { StatusBlock } from '../StatusBlock';
import { StatusElement } from '../StatusElement';
import { AddButton, Chip, EmptyBlock, FieldBlock, Input, Label, ShowButton, StatusesBlock, Wrapper } from './styled';

type Props = {
  list?: IStatus[];
  isBtn?: boolean;
  isTag?: boolean;
  sufixIcon?: ReactNode;
  addBtnText?: string;
  initValue?: IStatus;
  addBtn?: (e: { color: string; title: string }) => void;
  onSelect?: (id: string) => void;
  onDelete?: (id: string) => void;
  onBlur?: (e: { id: string; color?: string; title?: string }) => void;
};

export const FieldDropdown: React.FC<Props> = ({
  list = [],
  isBtn,
  isTag,
  sufixIcon,
  initValue,
  addBtnText,
  addBtn,
  onSelect,
  onDelete,
  onBlur,
}) => {
  const [value, setValue] = useState<IStatus | undefined>(initValue);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const handleClick = () => {
    setOpen(true);
    inputRef.current?.focus();
  };

  const handleSelect = (value: IStatus) => {
    onSelect && onSelect(value._id);
    setValue(value);
    setOpen(false);
  };

  const handleAdd = (e: { color: string; title: string }) => {
    addBtn && addBtn(e);
    setOpenAdd(false);
  };

  const handleDelete = (id: string) => {
    onDelete && onDelete(id);
    setValue(prev => (prev?._id === id ? undefined : prev));
  };

  return (
    <Wrapper>
      <SettingsDropdown
        open={open}
        width='100%'
        setOpen={() => setOpen(false)}
        label={
          <FieldBlock onClick={handleClick}>
            {value && <Chip color={value.color}>{value.title}</Chip>}
            {!value && <EmptyBlock>—</EmptyBlock>}
            <Input type='text' value='' onChange={() => {}} ref={inputRef} />
            {isBtn && <ShowButton>{sufixIcon}</ShowButton>}
          </FieldBlock>
        }
      >
        <StatusesBlock>
          <Label>{isTag ? 'Выберите несколько' : 'Выберите один'}</Label>
          {list.map(el => (
            <StatusElement
              id={el._id}
              key={el._id}
              title={el.title}
              color={el.color}
              onSelect={() => handleSelect(el)}
              onDelete={() => handleDelete(el._id)}
              onBlur={onBlur}
            />
          ))}
          {addBtnText && (
            <SettingsDropdown
              open={openAdd}
              setOpen={() => setOpenAdd(false)}
              offset={[20, -200]}
              label={
                <AddButton onClick={() => setOpenAdd(true)}>
                  <PlusIcon /> Новый
                </AddButton>
              }
            >
              <StatusBlock defaultValue='' addBtn={handleAdd} />
            </SettingsDropdown>
          )}
        </StatusesBlock>
      </SettingsDropdown>
    </Wrapper>
  );
};
