import { IconButton } from 'shared/ui';
import styled from 'styled-components';

export const Item = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 20px 35px 0;

  @keyframes hide {
    0% {
      box-shadow: 10px 9px 48px -3px var(--color-gray-dark);
      border-radius: 6px;
    }
    100% {
      box-shadow: none;
    }
  }

  ${({ active }) =>
    active &&
    `
      animation: hide linear 3s;
    `}
`;

export const DivElement = styled.div`
  border-bottom: 1px solid var(--color-gray-dark);
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 20px;
`;

export const LeftBlock = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const IconBlock = styled.div`
  min-width: 50px;
  height: 50px;
  border-radius: 10px;
  background: var(--color-gray-light);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoBlock = styled.div``;

export const Title = styled.div`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 10px;

  span {
    margin-left: 6px;
    font-family: Geometria;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-mybase-gray-text);
  }
`;

export const Content = styled.div`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  max-width: 420px;

  span {
    color: var(--color-blue);
    /* cursor: pointer; */
    svg {
      path {
        fill: var(--color-blue);
      }
    }
  }
`;

export const Text = styled.div`
  max-width: 774px;
  margin-top: 10px;
  font-family: Geometria;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-gray-label);
`;

export const RightBlock = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const Button = styled(IconButton)`
  border: 1px solid var(--color-gray-dark);
  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;

export const SettingsButton = styled(Button)`
  border: none;
`;
