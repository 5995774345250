import { AxiosPromise } from 'axios';
import { IError, apiInstance } from '../base';

type Request = {
  wsId: string;
};

type Response = {
  result: {
    [key: string]: number;
  };
  error: IError[];
};

export const getCountByGroupsApi = ({ wsId }: Request): AxiosPromise<Response> => {
  return apiInstance.get(`ws/${wsId}/contacts/countByGroups`);
};
