import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% + 130px);
  margin: 20px -65px;
`;

export const Line = styled.div<{ isShort?: boolean }>`
  background: var(--color-mybase-line-gray);
  height: 1px;
  flex-grow: 1;
  max-width: ${props => (props.isShort ? '55px' : '100%')};
`;

export const Text = styled.div`
  color: var(--color-mybase-gray-icon);
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin: 0 10px;
  flex-shrink: 0;
`;
