import { IMaskInput } from 'react-imask';
import styled from 'styled-components';

export const InputLightWrapper = styled.div`
  display: flex;
  flex-direction: column;

  input {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: var(--color-classic-black);
    flex-grow: 1;
    border: 1px solid var(--color-input-border);
    width: 100%;
    height: 44px;
    padding: 0 15px;
    border-radius: 6px;
  }

  input::placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: var(--color-mybase-gray-icon);
  }
`;

export const Error = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: var(--color-error);
  margin-top: 20px;
`;

export const MaskedInput = styled(IMaskInput)`
  height: 42px;
  background: white;
  padding: 0 10px;
  border-radius: 2px;
  width: 100%;
  font-size: 16px;
  color: ${(props: { $isEmpty: boolean }) => (props.$isEmpty ? 'var(--color-mybase-gray-icon)' : 'black')};
  line-height: 1;
  transition: none;
  .linkField & {
  }
  & input {
    font-size: 16px;
  }
  &:hover,
  &:not(:hover) {
  }
  &:focus {
    box-shadow: none;
    color: black;
  }
  &::-webkit-input-placeholder {
    opacity: 1;
    color: var(--color-mybase-gray-icon);
  }
  &:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  &::-moz-placeholder {
    opacity: 1;
  }
  &:focus::-moz-placeholder {
    opacity: 0;
    color: var(--color-mybase-gray-icon);
  }
`;
