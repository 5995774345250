import styled from 'styled-components';

export const AvatarBlock = styled.div<{ size?: number }>`
  width: ${({ size }) => (size ? size : 64)}px;
  height: ${({ size }) => (size ? size : 64)}px;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`;

export const DefaultAvatar = styled.div<{ size?: number }>`
  width: ${({ size }) => (size ? size : 64)}px;
  height: ${({ size }) => (size ? size : 64)}px;
  border-radius: 50%;
  padding: 4px;
  border: 1px solid #d9dfeb;
  display: flex;
  align-items: center;
  justify-content: center;
`;
