import React from 'react';
import { Wrapper, Line, Text } from './styled';

type Props = {
  text: string;
};

export const LineWithText: React.FC<Props> = ({ text }) => {
  return (
    <Wrapper>
      <Line isShort />
      <Text>{text}</Text>
      <Line />
    </Wrapper>
  );
};
