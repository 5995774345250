import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectWs } from 'processes/store';
import { ROUTE_WS } from 'shared/lib/routes';
import {
  SettingsUser,
  BackArrow,
  SettingsPlan,
  SettingsRobots,
  SettingsNumbers,
  SettingsExit,
  SettingsIntegrations,
  SettingsSend,
  SettingsSpace,
  SettingsSync,
  SettingsUsers,
} from 'shared/lib/Icons';
import {
  Wrapper,
  Header,
  Content,
  Navigation,
  Exit,
  NavSection,
  DataTag,
  NavItem,
  NavIcon,
  NavLabel,
  DataTagName,
} from './styled';

export const SidebarSettings: React.FC = () => {
  const [isActiveLink, setIsActiveLink] = useState(false);

  const { user } = useSelector(selectUser);
  const { ws } = useSelector(selectWs);

  const handleActiveLink = () => {
    setIsActiveLink(true);
  };

  return (
    <Wrapper>
      <Header onClick={() => window.history.back()}>
        <BackArrow />
        Назад
      </Header>
      <Content>
        <Navigation>
          <NavSection>
            <DataTag label={user.email} />
            <NavItem
              $isActive={isActiveLink}
              onClick={handleActiveLink}
              to={`${ROUTE_WS}/${ws._id}/settings/personalData`}
            >
              <NavIcon>
                <SettingsUser />
              </NavIcon>
              Персональные данные
            </NavItem>
          </NavSection>
          <NavSection>
            <DataTagName label={user.fullName} />
            <NavLabel>Рассылки</NavLabel>
            <NavItem $isActive={isActiveLink} onClick={handleActiveLink} to=''>
              <NavIcon>
                <SettingsPlan />
              </NavIcon>
              Планировщик
            </NavItem>
            <NavItem $isActive={isActiveLink} onClick={handleActiveLink} to=''>
              <NavIcon>
                <SettingsRobots />
              </NavIcon>
              Мои роботы
            </NavItem>
          </NavSection>
          <NavSection>
            <NavLabel>Коллтрекинг</NavLabel>
            <NavItem $isActive={isActiveLink} onClick={handleActiveLink} to=''>
              <NavIcon>
                <SettingsNumbers />
              </NavIcon>
              Виртуальные номера
            </NavItem>
          </NavSection>
          <NavSection>
            <NavLabel>Общие</NavLabel>
            <NavItem
              $isActive={isActiveLink}
              onClick={handleActiveLink}
              to={`${ROUTE_WS}/${ws._id}/settings/workplace`}
            >
              <NavIcon>
                <SettingsSpace />
              </NavIcon>
              Настройки пространства
            </NavItem>
            <NavItem $isActive={isActiveLink} onClick={handleActiveLink} to={`${ROUTE_WS}/${ws._id}/settings/users`}>
              <NavIcon>
                <SettingsUsers />
              </NavIcon>
              Пользователи
            </NavItem>
            <NavItem $isActive={isActiveLink} onClick={handleActiveLink} to=''>
              <NavIcon>
                <SettingsSync />
              </NavIcon>
              Синхронизация
            </NavItem>
            <NavItem $isActive={isActiveLink} onClick={handleActiveLink} to=''>
              <NavIcon>
                <SettingsSend />
              </NavIcon>
              Отправители
            </NavItem>
            <NavItem $isActive={isActiveLink} onClick={handleActiveLink} to=''>
              <NavIcon>
                <SettingsIntegrations />
              </NavIcon>
              Интеграции
            </NavItem>
          </NavSection>
        </Navigation>
        <Exit>
          <NavItem to='/logout'>
            <NavIcon>
              <SettingsExit />
            </NavIcon>
            Выйти
          </NavItem>
        </Exit>
      </Content>
    </Wrapper>
  );
};
