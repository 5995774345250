import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Authorized } from 'processes/Authorized';
import { IEmail, ModalType, selectContact, selectUser, selectWs, useAppDispatch } from 'processes/store';
import { useDebounce, useModal } from 'shared/hooks';
import { MainTemplate } from 'shared/ui';
import { AvatarIcon, BackArrow, ClipIcon, CloseIcon } from 'shared/lib/Icons';
import { EmailAddContactDropdown } from 'features/Email/EmailAddContactDropdown';
import { EmailFilesList } from 'features/Email/EmailFilesList';
import { changeEmailAction, getContactsAction, getEmailAction, sendEmailAction } from './model';
import {
  ActionBlock,
  Avatar,
  AvatarBlock,
  BackButton,
  BlockUser,
  BottomText,
  Containter,
  Count,
  DivElement,
  FooterBlock,
  FooterButton,
  Header,
  SendMessage,
  Text,
  TextArea,
  Title,
  TitleInput,
  WriteMessage,
} from './styled';

export const EmailPage: React.FC = () => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isLoadFiles, setIsLoadFiles] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [email, setEmail] = useState<IEmail>();

  const { contactList } = useSelector(selectContact);
  const { user } = useSelector(selectUser);
  const { ws } = useSelector(selectWs);
  const { showModal } = useModal();

  const location = useLocation();
  const debounceTitle = useDebounce(title, 500);
  const debounceContent = useDebounce(content, 500);

  const receiver = decodeURIComponent(new URL(window.location.href).searchParams.get('receiver') || '');
  const groupId = decodeURIComponent(new URL(window.location.href).searchParams.get('groupId') || '');

  const emailId = location.pathname.split('/').at(-1);

  const dispatch = useAppDispatch();

  useEffect(() => {
    ws._id && dispatch(getContactsAction({ wsId: ws._id, page: 1, _groupId: groupId || undefined }));
  }, [ws, groupId, dispatch]);

  useEffect(() => {
    emailId && dispatch(getEmailAction({ wsId: ws._id, id: emailId, successCallback: email => setEmail(email) }));
  }, [dispatch, ws, emailId]);

  useEffect(() => {
    emailId &&
      debounceTitle &&
      debounceContent &&
      dispatch(changeEmailAction({ wsId: ws._id, id: emailId, subject: debounceTitle, content: debounceContent }));
  }, [ws, emailId, debounceTitle, debounceContent, dispatch]);

  useEffect(() => {
    if (groupId) {
      setSelectedIds(contactList.map(el => el._id));
    }
    if (receiver) {
      setSelectedIds([receiver]);
    }
  }, [receiver, contactList, groupId]);

  useEffect(() => {
    if (email) {
      setTitle(email.subject);
      setContent(email.content);
    }
  }, [email]);

  const handleDelete = (id: string) => {
    setSelectedIds(prev => [...prev.filter(el => !el.includes(id))]);
  };

  // const openPreview = () => {
  //   showModal(ModalType.PreviewModal);
  // };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const handleChangeContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(e.target.value);
  };

  const sendEmail = useCallback(() => {
    const ids = selectedIds.map(el => ({ _objectId: el }));
    emailId &&
      dispatch(
        sendEmailAction({
          wsId: ws._id,
          ids,
          emailId,
          successCallback: () => {
            window.history.back();
            showModal(ModalType.AlertModal, { alert: true });
          },
          errorCallback: () => {},
        }),
      );
  }, [selectedIds, emailId, dispatch, ws._id, showModal]);

  return (
    <Authorized>
      <MainTemplate>
        <Containter>
          <Header>
            <Title>
              <BackButton onClick={() => window.history.back()}>
                <BackArrow />
              </BackButton>
              Написать письмо
            </Title>
            {/* <ActionButton type='button' onClick={openPreview}>
              <EyeIcon />
              Превью
            </ActionButton> */}
          </Header>
          <ActionBlock>
            <Text>От</Text>
            <BlockUser>
              <AvatarBlock>
                <Avatar>{<AvatarIcon />}</Avatar>
              </AvatarBlock>
              <div>{user.fullName}</div>
              {/* <div>
                <ArrowDown />
              </div> */}
            </BlockUser>
          </ActionBlock>
          <ActionBlock>
            <Text>Кому</Text>
            {contactList
              .filter(item => selectedIds.includes(item._id))
              .map(el => (
                <BlockUser key={el._id}>
                  <AvatarBlock>
                    <Avatar>{<AvatarIcon />}</Avatar>
                  </AvatarBlock>
                  <div>{el.title}</div>
                  <div onClick={() => handleDelete(el._id)}>
                    <CloseIcon />
                  </div>
                </BlockUser>
              ))}
            <EmailAddContactDropdown list={contactList} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
          </ActionBlock>
          <WriteMessage>
            <TitleInput onChange={handleChangeTitle} value={title} placeholder='Тема письма' />
            <TextArea onChange={handleChangeContent} value={content} placeholder='Начнните вводить сообщение' />
          </WriteMessage>
          <FooterBlock>
            <BottomText isTop={isLoadFiles}>
              Отправлено с помощью <span>MyBase</span>
            </BottomText>
            {isLoadFiles && <EmailFilesList percentage={percentage} />}
            <DivElement>
              <FooterButton type='button' onClick={() => setIsLoadFiles(!isLoadFiles)}>
                <ClipIcon />
              </FooterButton>
              {/* <FooterButton>
                  <BracketsIcon />
                </FooterButton> */}
            </DivElement>
            <DivElement>
              {/* <FooterButton>
                <ClockIcon />
              </FooterButton>
              <FooterButton>
                <MailboxIcon />
                Сохранить черновик
              </FooterButton> */}
              <SendMessage onClick={sendEmail}>
                Отправить письмо {!!selectedIds.length && <Count>{selectedIds.length}</Count>}
              </SendMessage>
            </DivElement>
          </FooterBlock>
        </Containter>
      </MainTemplate>
    </Authorized>
  );
};
