import styled from 'styled-components';
import { alignCenter, flexCol, flexFull, flexRow } from 'shared/lib/mixins';
import { DefaultButton } from 'shared/ui';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
  position: relative;
  font-family: Geometria;
`;

export const Header = styled.header`
  padding: 0 35px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
  justify-content: space-between;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: var(--color-black);
`;

export const BackButton = styled(DefaultButton)`
  cursor: pointer;
`;

export const ActionBlock = styled.div`
  max-height: 62px;
  border-bottom: 1px solid var(--color-gray-dark);
  padding: 15px 35px;
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-right: 20px;
  min-width: 36px;
`;

export const BlockUser = styled.div`
  padding: 5px 8px;
  border: 1px solid var(--color-gray-dark);
  display: flex;
  column-gap: 10px;
  border-radius: 4px;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  align-items: center;
  margin-right: 10px;

  svg {
    cursor: pointer;
  }

  div:last-child {
    display: flex;
  }
`;

export const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 22px;
`;

export const Avatar = styled.div`
  width: 22px;
  height: 22px;
  margin-right: 10px;
  padding: 4px;
  background: var(--color-white);
  border: 1px solid var(--color-gray-dark);
  border-radius: 29px;
  display: flex;
  align-items: center;
`;

export const WriteMessage = styled.div`
  padding: 40px 35px 30px;
`;

export const TitleInput = styled.input`
  font-family: Geometria;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;

  &::placeholder {
    color: var(--color-gray-text);
  }
`;

export const TextArea = styled.textarea`
  font-family: Geometria;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
  height: 100%;
  margin: 35px 0;
  /* max-width: 1230px; */
  max-height: 370px;
  min-height: 370px;
  resize: none;

  &::placeholder {
    color: var(--color-mybase-gray-text);
  }
`;

export const BottomText = styled.div<{ isTop?: boolean }>`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  position: absolute;
  top: ${({ isTop }) => (isTop ? -120 : -35)}px;

  span {
    text-decoration: underline;
  }
`;

export const FooterBlock = styled.div`
  border-top: 1px solid var(--color-gray-dark);
  padding: 18px 25px;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const FooterButton = styled(DefaultButton)`
  border: 1px solid var(--color-gray-dark);
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-radius: 6px;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;

export const SendMessage = styled(FooterButton)`
  border: none;
  background: var(--color-blue);
  color: var(--color-white);

  &:hover {
    background: var(--color-blue);
    opacity: 80%;
  }
`;

export const Count = styled.div`
  font-family: Geometria;
  font-size: 11px;
  font-weight: 500;
  line-height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: var(--color-blue);
  background: var(--color-white);
  border-radius: 50%;
`;

export const DivElement = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const ActionButton = styled.button`
  display: flex;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  max-height: 36px;
  padding: 10px 15px;
  column-gap: 10px;
  margin: 0;
  background: none;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;
