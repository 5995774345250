import React, { PropsWithChildren, ReactNode } from 'react';
import { Sidebar } from '../Sidebar';
import { Container } from './styled';

type Props = {
  customSideBar?: ReactNode;
};

export const MainTemplate: React.FC<PropsWithChildren<Props>> = ({ customSideBar, children }) => {
  return (
    <Container>
      {customSideBar ? customSideBar : <Sidebar />}
      {children}
    </Container>
  );
};
