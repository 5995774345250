import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IContact, IGroup, ModalType } from 'processes/store/types';

export type InitialModalState = {
  active: boolean;
  current: '' | ModalType;
  params?: {
    wsId?: string;
    contact?: IContact;
    id?: string;
    title?: string;
    onClick?: () => void;
    successCallback?: () => void;
    onCancel?: () => void;
    groups?: IGroup[];
    tab?: string;
    email?: string;
    file?: Blob;
    defaultAvatar?: string;
    alert?: boolean;
  };
};

const initialState: InitialModalState = {
  active: false,
  current: '' as ModalType,
  params: {},
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (
      state,
      action: PayloadAction<{
        active: boolean;
        current: ModalType;
        params: InitialModalState['params'];
      }>,
    ) => {
      state.active = action.payload.active;
      state.current = action.payload.current;
      state.params = { ...action.payload.params };
    },
    hideModal: state => {
      state.active = false;
      state.current = '';
    },
  },
});

export const { setModal, hideModal } = modalSlice.actions;

export default modalSlice.reducer;
