import styled from 'styled-components';

export const Block = styled.div`
  color: var(--color-white);
  font-family: var(--font-medium);
  min-width: 16px;
  height: 16px;
  line-height: 18px;
  padding: 0 4px;
  border-radius: 20px;
  font-size: 11px;
  background: linear-gradient(135deg, #002fa4 0%, #295dff 45.83%, #babff8 100%);
`;
