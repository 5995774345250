export interface IProfile {
  gender: 'male' | 'female';
  verificationStatus: 'unconfirmed' | 'confirmed' | 'pending';
  rating: number;
  dateOfBirth?: number;
  balance?: number;
  about?: string;
  isViewed?: boolean;
  friendInfo?: {
    inviteStatus?: string;
    _id?: string;
  };
  attaches: {
    _id: string;
    title: string;
    size: number;
    fieldType?: string;
    type?: string;
    isPreview?: boolean;
    meta: {
      ext: string;
      filename: string;
      size: number;
    };
  }[];
  noprizMember: boolean;
  noprizRegNum?: string;
  allowed?: string[];
  _lastActivity?: number;
  _userLastOnlineDate?: number;
  _createdOn?: number;
}

export interface IUser {
  finishRegistration?: {
    email: string;
  };
  needWs?: boolean;
  balance?: number;
  phones: { type?: NumberConstructor; number: string };
  lastPhoneNumber?: string;
  id: string;
  email: string;
  imageSrc: string | null;
  phoneConfirmedStatus: 'not_confirmed' | 'confirmed' | 'partial_confirmed';
  emailConfirmedStatus: 'not_confirmed' | 'confirmed' | 'partial_confirmed';
  locale: string;
  introFlags: {
    profile: boolean;
  };
  isBlocked: boolean;
  confNotificationsCollection: {
    [key: string]: {
      disabled: boolean;
      sound: boolean;
      setTime?: null;
    };
  };
  fullName: string;
  shortId: string;
  botNotifications: {
    telegram: boolean;
    viber: boolean;
    vk: boolean;
  };
  lastReBill: number;
  _lastWs: string;
  personalAccount: string;
  lastEmail: string;
  socialAuth?: {
    google?: { accessToken: string; email: string; profileId: string; refreshToken: string };
  };
}

export interface IWs {
  _id: string;
  title: string;
}

export enum ModalType {
  CreateWs = 'CreateWs',
  DeleteElement = 'DeleteElement',
  CreateGroup = 'CreateGroup',
  UpdateGroup = 'UpdateGroup',
  AlertModal = 'AlertModal',
  InviteUser = 'InviteUser',
  AddContact = 'AddContact',
  AddManuallyContact = 'AddManuallyContact',
  ChangeEmail = 'ChangeEmail',
  AddContactInGroup = 'AddContactInGroup',
  PreviewModal = 'PreviewModal',
  AvatarModal = 'AvatarModal',
}

export interface IView {
  isPhoneView: boolean;
  isTabletView: boolean;
  isLaptopView: boolean;
  isDesktopView: boolean;
}

export interface IContact {
  title?: string;
  fullName: string;
  organizationTitle?: string;
  isOrganization?: boolean;
  roles: {
    value: string;
    isFavorites: boolean;
  }[];
  companies: {
    value: string;
    isFavorites: boolean;
  }[];
  emails: {
    value: string;
    isFavorites: boolean;
  }[];
  phones: {
    value: string;
    isFavorites: boolean;
  }[];
  links: {
    value: string;
    isFavorites: boolean;
  }[];
  addresses: {
    value: string;
    isFavorites: boolean;
  }[];
  statuses: {
    value: string;
  }[];
  tag: string[];
  gender: string;
  note: string;
  birthDay?: number | Date | null;
  _id: string;
  _createdOn: number;
  _updatedOn: number;
  __rev: number;
  objIndex: number;
  isDeleted: boolean;
  _createdBy: string;
  _creatorProfile: string;
  _updatedBy: string;
  _updaterProfile: string;
  _wsId: string;
  _archivedBy: string;
  _archivedOn: number;
  inArchive: boolean;
  defaultAvatar: boolean;
  imageSrc: string;
  isOnline: boolean;
  _groupId: string[];
}

export type TableContactsType = {
  contact: { title: string; avatar: string; id: string };
  companies: string;
  email: string;
  phone: string;
  groups: IContact;
};

export interface IGroup {
  collectionName: string;
  inArchive: boolean;
  isDeleted: boolean;
  objIndex: number;
  responsibles: [];
  title: string;
  _archivedBy: string | null;
  _archivedOn: number | null;
  _createdBy: string | null;
  _createdOn: number | null;
  _creatorProfile: string;
  _id: string;
  _updatedBy: string | null;
  _updatedOn: number | null;
  _updaterProfile: string;
  _wsId: string;
}

export interface IStatus {
  color: string;
  isDeleted?: false;
  title: string;
  description?: string;
  _id: string;
  _wsId?: string;
  _createdOn?: number;
}

export interface ITags {
  color: string;
  isDeleted?: false;
  title: string;
  description?: string;
  _id: string;
  _wsId?: string;
  _createdOn?: number;
}

export interface ITouches {
  misc: {
    subject: string;
    target: string[];
    from: string;
  };
  objIndex: number;
  action: string;
  isDeleted: false;
  _createdOn: number;
  _createdBy: string;
  _wsId: string;
  _id: string;
  _relatedObject: {
    _id: string[];
    collectionName: string;
  };
  options: { _groupId: string[]; _targetCompanies: string[]; _targetTitle: string };
  _relatedObjectInfo: { title: string; companies: { value: string; isFavorites: boolean }[] }[];
}

export interface IEmail {
  type: string;
  subject: string;
  content: string;
  isDraft: boolean | null;
  _id: string;
  _createdOn: number;
  isDeleted: false;
  _createdBy: string;
  _wsId: string;
  title: string;
  description: string;
  inArchive: false;
}

export interface IEmailReceivers {
  _objectId: string;
  _groupId: string[];
  status: null;
  _id: string;
  _createdOn: number;
  _updatedOn: number;
  objIndex: number;
  isDeleted: boolean;
  _wsId: string;
}
