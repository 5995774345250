import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeMessageApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  subject?: string;
  content?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const changeEmailAction = createAsyncThunk<object, PayloadType>('change/email', async payload => {
  const { wsId, id, subject, content, errorCallback, successCallback } = payload;
  try {
    const response = await changeMessageApi({ wsId, id, subject, content });

    if (response.status === 200) {
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
