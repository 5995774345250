import { FC } from 'react';
import { StepBlock, StepElem } from './styled';

type Props = {
  count: number;
  finishStep: number;
  size?: number;
};

export const Step: FC<Props> = ({ count, finishStep }) => {
  return (
    <StepBlock>
      <StepElem>{count}</StepElem> <span>из</span> <StepElem>{finishStep}</StepElem>
    </StepBlock>
  );
};
