import styled from 'styled-components';
import { DefaultButton, Divider } from 'shared/ui';

export const Wrapper = styled.div`
  width: 220px;
  background: var(--color-white);
  border-radius: 10px;
  font-family: Geometria;
  padding: 15px 0 0;
`;

export const InputBlock = styled.div`
  padding: 15px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  margin: 0 20px;
`;

export const Input = styled.input`
  width: 100%;
`;

export const DividerCustom = styled(Divider)`
  border-top: 3px solid var(--color-gray-dark);
`;

export const ColorsWrapper = styled.div``;

export const Title = styled.div`
  height: 40px;
  display: flex;
  align-items: end;
  padding: 0 20px;
  color: #6a7b9b;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

export const Text = styled.div`
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid var(--color-gray-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }

  div {
    display: flex;
    column-gap: 10px;
  }
`;

export const Pin = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px;
  font-weight: 500;
  line-height: 11px;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: ${({ color }) => (color ? color : 'none')};
`;

export const Button = styled(DefaultButton)`
  cursor: pointer;
  color: var(--color-error);
  display: flex;
  column-gap: 10px;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  svg {
    fill: var(--color-error);
  }
  &:hover {
    background-color: var(--color-gray-btn-hv);
    border-radius: 0 0 6px 6px;
  }
`;

export const AddButton = styled(Button)`
  color: var(--color-blue);

  svg {
    path {
      fill: var(--color-blue);
    }
  }
`;
