import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUser } from 'processes/store';
import { updateUserApi } from 'shared/api';

type PayloadType = {
  params: Partial<IUser>;
  successCallback?: () => void;
  errorCallback?: (msg: { name: string; message: string }) => void;
};

export const changeUserInfoAction = createAsyncThunk<object, PayloadType>(
  'user/signup',
  async (payload, { dispatch }) => {
    const { params, errorCallback, successCallback } = payload;
    try {
      const response2 = await updateUserApi({ ...params });
      if (response2.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      console.log('error', err);
    }
  },
);
