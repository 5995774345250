import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoggedUser, setUser } from 'processes/store';
import { loginUserApi } from 'shared/api';
import { getMessageFromError } from '../lib';

type PayloadType = {
  password: string;
  login: string;
  successCallback?: (flag?: boolean) => void;
  errorCallback?: (msg: { name: string; message: string }) => void;
};

export const loginAction = createAsyncThunk<object, PayloadType>('user/login', async (payload, { dispatch }) => {
  const { password, login, errorCallback, successCallback } = payload;
  try {
    const response = await loginUserApi({ password, login });

    if (response.status === 200) {
      if (response.data.result.emailConfirmedStatus === 'not_confirmed' && response.data.result.lastEmail !== '') {
        dispatch(
          setUser({
            user: { ...response.data.result, finishRegistration: { email: response.data.result.lastEmail } },
          }),
        );
        successCallback && successCallback(true);
      } else if (
        response.data.result.emailConfirmedStatus === 'not_confirmed' &&
        response.data.result.lastEmail === ''
      ) {
        window.open('/logout');
      } else {
        dispatch(
          setUser({
            user: response.data.result,
          }),
        );
        dispatch(setLoggedUser({ isLogged: true }));
        successCallback && successCallback();
      }
    }
  } catch (err) {
    const msg = getMessageFromError(err);
    errorCallback && errorCallback(msg);
  }
});
