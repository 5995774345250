import { createAsyncThunk } from '@reduxjs/toolkit';
import { chooseEmailReceiversApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  emailId: string;
  ids: { _objectId: string }[];
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const chooseEmailReceiversAction = createAsyncThunk<object, PayloadType>(
  'post/emailReceivers',
  async payload => {
    const { wsId, emailId, ids, errorCallback, successCallback } = payload;
    try {
      const response = await chooseEmailReceiversApi({ wsId, emailId, ids });

      if (response.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
