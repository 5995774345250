import { AxiosPromise } from 'axios';
import { IStatus } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  color: string;
  title: string;
  description?: string;
};

type Response = {
  result: { list: IStatus[]; total: number };
  errors: IError[];
};

export const addContactsStatusApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.post(`ws/${params.wsId}/contacts/statuses`, {
    color: params.color,
    title: params.title,
  });
};
