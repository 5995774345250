import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';
import { IWs } from 'processes/store';

type Response = {
  result: IWs;
  errors: IError[];
};

export const updateWsApi = (params: Partial<IWs>): AxiosPromise<Response> => {
  return apiInstance.patch(`ws/${params._id}`, {
    ...params,
  });
};
