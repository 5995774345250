import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Authorized } from 'processes/Authorized';
import { selectTouch, selectWs, useAppDispatch } from 'processes/store';
import { IconButton, MainTemplate, Pagination, Spinner, TouchesTable } from 'shared/ui';
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon, TouchesNav } from 'shared/lib/Icons';
import { RequestState } from 'shared/lib/types';
import { useDebounce } from 'shared/hooks';
import { getTouchesAction } from './model/getTouchesAction';
import { Containter, EmptyData, FooterBlock, Header, SearchBlock, SearchField, SearchInput, Title } from './styled';

export const TouchesPage: React.FC = () => {
  const [isLoading, setLoading] = useState(RequestState.PENDING);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const { touchesList, total } = useSelector(selectTouch);
  const { ws } = useSelector(selectWs);

  const dispatch = useAppDispatch();
  const debounceSearch = useDebounce(search, 500);

  useEffect(() => {
    setLoading(RequestState.LOADING);
    ws._id &&
      dispatch(
        getTouchesAction({
          wsId: ws._id,
          search: debounceSearch,
          page,
          successCallback: () => {
            setLoading(RequestState.SUCCESS);
          },
          errorCallback: () => {},
        }),
      );
  }, [ws._id, page, debounceSearch, dispatch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const handleClose = () => {
    setSearch('');
  };

  return (
    <Authorized>
      <MainTemplate>
        <Containter>
          <Header>
            <Title>
              <div>
                <TouchesNav />
              </div>
              <div>Касания</div>
            </Title>
          </Header>
          <SearchBlock>
            <SearchField>
              <SearchInput onChange={handleChange} name='search' value={search} placeholder='Поиск' />
              {!!search.length && <IconButton onClick={handleClose} icon={<CloseIcon />} />}
            </SearchField>
          </SearchBlock>
          {isLoading !== 'success' ? (
            <Spinner />
          ) : (
            <>
              {touchesList.length ? (
                <TouchesTable touchesList={touchesList} />
              ) : (
                <EmptyData>У вас еще нет ни одного касания</EmptyData>
              )}
            </>
          )}
          <FooterBlock justify='end'>
            {!!touchesList.length && (
              <Pagination
                pageCount={Math.ceil(total / 10)}
                previousLabel={<ArrowLeftIcon />}
                nextLabel={<ArrowRightIcon />}
                onChange={handlePagination}
              />
            )}
          </FooterBlock>
        </Containter>
      </MainTemplate>
    </Authorized>
  );
};
