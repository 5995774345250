import { FC, useState } from 'react';
import { Tag } from '../styled';

type Props = {
  tag: string;
};

export const TagUsage: FC<Props> = ({ tag }) => {
  const [active, setActive] = useState(false);
  return (
    <Tag active={active} onClick={() => setActive(state => !state)}>
      {tag}
    </Tag>
  );
};
