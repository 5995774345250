import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';

type Request = {
  email: string;
};

type Response = {
  result: {};
  errors: IError[];
};

export const getEmailCodeApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.post('/user/email/confirm/sendCode', {
    ...params,
  });
};

export const getEmailTimeApi = (): AxiosPromise<Response> => apiInstance.get('user/me/email/time');
