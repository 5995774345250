import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ModalType, selectUser } from 'processes/store';
import { Authorized } from 'processes/Authorized';
import { useModal } from 'shared/hooks/useModal';
import { SettingsUsers, FilterBtn, CoverDefault } from 'shared/lib/Icons';
import { useSelector } from 'react-redux';
import { Button, MainTemplate, SidebarSettings } from 'shared/ui';
import { InputLight } from 'shared/ui';
import { User } from './ui/User';
import {
  Header,
  Title,
  ContentWrapper,
  Content,
  WrapperSections,
  Label,
  EditSection,
  EditBlock,
  UserMain,
  UserInfo,
  UserStatus,
  UserAvatarDefault,
  UserData,
  UserTitle,
  UserNotation,
  EditBlockUsers,
  UsersSearch,
  SearchField,
  SearchActions,
  FilterButton,
  Container,
} from './styled';

const users = [
  {
    fullName: 'Максим Семенов',
    action: 'Редактор',
    avatar:
      'https://s3-alpha-sig.figma.com/img/45ad/54d3/fdcf7e52a64e24027e56a6c7fe80605f?Expires=1683504000&Signature=aJKaWGpXtI2-V-UueXniuREiRRugvjepIJp8gKYG41iw4BVSvgyq65-AL~ux39-6MTP-LW7sy9pOmF0XHxCWdUtfc82Ty0DFDcb63l4piAJ5QAK3lmOQp1JnT~JxyvYCmUUsURMLzlG4WwPmUI~AFvYXnMBAjQUBh7T82k1i-0JOHMP2EVtcr1YNpH44sayfjZ6ObW5oOQDVvuGfCTv-zfON159xSjst8bQYVgsCYTg4TcuMbNkNGnyEA0hfDOzMLt8aQoIHoEgPAZSFXFdDBspTw9ooVNhnKGv4SVP1UpIYzdo117GOTzXdeYeLtaL6rkMZA-Hyj0EWh-VL4gqRBA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
  },
  {
    fullName: 'Максим Семенов',
    action: 'Полный доступ',
    avatar:
      'https://s3-alpha-sig.figma.com/img/45ad/54d3/fdcf7e52a64e24027e56a6c7fe80605f?Expires=1683504000&Signature=aJKaWGpXtI2-V-UueXniuREiRRugvjepIJp8gKYG41iw4BVSvgyq65-AL~ux39-6MTP-LW7sy9pOmF0XHxCWdUtfc82Ty0DFDcb63l4piAJ5QAK3lmOQp1JnT~JxyvYCmUUsURMLzlG4WwPmUI~AFvYXnMBAjQUBh7T82k1i-0JOHMP2EVtcr1YNpH44sayfjZ6ObW5oOQDVvuGfCTv-zfON159xSjst8bQYVgsCYTg4TcuMbNkNGnyEA0hfDOzMLt8aQoIHoEgPAZSFXFdDBspTw9ooVNhnKGv4SVP1UpIYzdo117GOTzXdeYeLtaL6rkMZA-Hyj0EWh-VL4gqRBA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
  },
  {
    fullName: 'Максим Семенов',
    action: 'Полный доступ',
    avatar:
      'https://s3-alpha-sig.figma.com/img/45ad/54d3/fdcf7e52a64e24027e56a6c7fe80605f?Expires=1683504000&Signature=aJKaWGpXtI2-V-UueXniuREiRRugvjepIJp8gKYG41iw4BVSvgyq65-AL~ux39-6MTP-LW7sy9pOmF0XHxCWdUtfc82Ty0DFDcb63l4piAJ5QAK3lmOQp1JnT~JxyvYCmUUsURMLzlG4WwPmUI~AFvYXnMBAjQUBh7T82k1i-0JOHMP2EVtcr1YNpH44sayfjZ6ObW5oOQDVvuGfCTv-zfON159xSjst8bQYVgsCYTg4TcuMbNkNGnyEA0hfDOzMLt8aQoIHoEgPAZSFXFdDBspTw9ooVNhnKGv4SVP1UpIYzdo117GOTzXdeYeLtaL6rkMZA-Hyj0EWh-VL4gqRBA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
  },
  {
    fullName: 'Максим Семенов',
    action: 'Редактор',
    avatar:
      'https://s3-alpha-sig.figma.com/img/45ad/54d3/fdcf7e52a64e24027e56a6c7fe80605f?Expires=1683504000&Signature=aJKaWGpXtI2-V-UueXniuREiRRugvjepIJp8gKYG41iw4BVSvgyq65-AL~ux39-6MTP-LW7sy9pOmF0XHxCWdUtfc82Ty0DFDcb63l4piAJ5QAK3lmOQp1JnT~JxyvYCmUUsURMLzlG4WwPmUI~AFvYXnMBAjQUBh7T82k1i-0JOHMP2EVtcr1YNpH44sayfjZ6ObW5oOQDVvuGfCTv-zfON159xSjst8bQYVgsCYTg4TcuMbNkNGnyEA0hfDOzMLt8aQoIHoEgPAZSFXFdDBspTw9ooVNhnKGv4SVP1UpIYzdo117GOTzXdeYeLtaL6rkMZA-Hyj0EWh-VL4gqRBA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
  },
];

export const SettingsUsersPage: React.FC = () => {
  const { showModal } = useModal();

  const { register } = useForm();

  const deleteInvite = useCallback(() => {
    showModal(ModalType.DeleteElement, { title: 'приглашение' });
  }, [showModal]);

  const deleteUser = useCallback(() => {
    showModal(ModalType.DeleteElement, { title: 'пользователя' });
  }, [showModal]);

  const inviteUser = useCallback(() => {
    showModal(ModalType.InviteUser);
  }, [showModal]);

  const { user } = useSelector(selectUser);

  return (
    <Authorized>
      <MainTemplate customSideBar={<SidebarSettings />}>
        <Container>
          {' '}
          <Header>
            <SettingsUsers />
            <Title>Пользователи</Title>
          </Header>
          <ContentWrapper>
            <Content>
              <WrapperSections>
                <EditSection>
                  <Label>Владелец</Label>
                  <EditBlock>
                    <UserMain>
                      <UserInfo>
                        <UserAvatarDefault>
                          <CoverDefault />
                        </UserAvatarDefault>
                        <UserData>
                          <UserTitle>{user.fullName}</UserTitle>
                          <UserNotation>(Вы)</UserNotation>
                        </UserData>
                      </UserInfo>
                      <UserStatus>Полный доступ</UserStatus>
                    </UserMain>
                  </EditBlock>
                </EditSection>
                <EditSection>
                  <Label>Пользователи</Label>
                  <EditBlockUsers>
                    <UsersSearch>
                      <SearchField>
                        <InputLight register={register} name='search' placeholder='Искать' />
                      </SearchField>
                      <SearchActions>
                        <FilterButton>
                          Фильровать
                          <FilterBtn />
                        </FilterButton>
                        <Button onClick={inviteUser} label='Пригласить' />
                      </SearchActions>
                    </UsersSearch>
                    {users.map(({ avatar, fullName, action }) => (
                      <User avatar={avatar} fullName={fullName} action={action} deleteAction={deleteUser} />
                    ))}
                  </EditBlockUsers>
                </EditSection>
                <EditSection>
                  <Label>Отправленые приглашения</Label>
                  <EditBlockUsers>
                    {users.slice(-2).map(({ avatar, fullName, action }) => (
                      <User avatar={avatar} fullName={fullName} action={action} deleteAction={deleteInvite} />
                    ))}
                  </EditBlockUsers>
                </EditSection>
              </WrapperSections>
            </Content>
          </ContentWrapper>
        </Container>
      </MainTemplate>
    </Authorized>
  );
};
