import styled from 'styled-components';

export const Wrapper = styled.div`
  background: var(--color-auth-form);
  padding: 30px 65px 35px 65px;
  border-radius: 20px;
  width: 400px;

  button {
    margin-bottom: 25px;
  }

  p {
    margin-top: 45px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  p:nth-of-type(2) {
    margin-top: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #6a7b9b;
  }
`;

export const Label = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 30px;
`;

export const IconWrapper = styled.div`
  text-align: center;

  svg {
    margin-top: 10px;
  }
`;

export const LinkWrapper = styled.div`
  margin-top: 18px;
  text-align: center;
`;

export const InputWrapper = styled.div`
  margin-bottom: 35px;
`;
