import styled from 'styled-components';
import { Button } from 'shared/ui';
import { flexRow, alignCenter, alignMiddle, spaceBetween } from 'shared/lib/mixins';

export const CoverField = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 15px;
  margin-bottom: 15px;
`;

export const CreateAvatar = styled.div`
  width: 58px;
  height: 58px;
`;

export const LogoPreview = styled.div`
  width: 58px;
  height: 58px;
  flex: 0 0 58px;
  ${flexRow};
  ${alignMiddle};
  border: 1px solid var(--color-gray-dark);
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 100ms;
  svg {
    fill: var(--color-gray-text);
    transition: fill 100ms;
  }
  &:hover {
    border-color: var(--color-gray-text);
    svg {
      fill: var(--color-blue);
    }
  }
`;

export const Label = styled.span`
  font-size: 19px;
  line-height: 26px;
  font-family: var(--font-medium);
`;

export const TitleField = styled.div`
  margin-bottom: 25px;
`;

export const Actions = styled.div`
  ${flexRow};
  ${spaceBetween};
`;

export const CreateButton = styled(Button)`
  min-width: 168px;
`;
