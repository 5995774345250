import { FC } from 'react';
import { RegistrationCheckEmail } from 'features/Auth/Registration/RegistrationCheckEmail';
import { RegistrationCollaborateTeam } from 'features/Auth/Registration/RegistrationCollaborateTeam';
import { RegistrationCustomizeSpace } from 'features/Auth/Registration/RegistrationCustomizeSpace';
import { RegistrationGreetings } from 'features/Auth/Registration/RegistrationGreetings';
import { RegistrationMeet } from 'features/Auth/Registration/RegistrationMeet';
import { RegistrationSpace } from 'features/Auth/Registration/RegistrationSpace';
import { RegistrationSynchronization } from 'features/Auth/Registration/RegistrationSynchronization';

export enum RegistrationRoutes {
  'greeting' = 'greeting',
  'checkEmail' = 'checkEmail',
  'meetUser' = 'meetUser',
  'space' = 'space',
  'synchronization' = 'synchronization',
  'customizeSpace' = 'customizeSpace',
  'collaborateTeam' = 'collaborateTeam',
}

export const RegistrationRoutesRenderers: Record<
  RegistrationRoutes,
  FC<{
    stateEmail?: string;
    handleRedirect: (route: RegistrationRoutes) => void;
    handleSaveEmail?: (email: string) => void;
  }>
> = {
  [RegistrationRoutes.greeting]: RegistrationGreetings,
  [RegistrationRoutes.checkEmail]: RegistrationCheckEmail,
  [RegistrationRoutes.meetUser]: RegistrationMeet,
  [RegistrationRoutes.space]: RegistrationSpace,
  [RegistrationRoutes.synchronization]: RegistrationSynchronization,
  [RegistrationRoutes.customizeSpace]: RegistrationCustomizeSpace,
  [RegistrationRoutes.collaborateTeam]: RegistrationCollaborateTeam,
};
