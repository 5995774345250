import { ChangeEventHandler, FocusEventHandler } from 'react';
import { InputLightWrapper, Error } from './styled';

type InputProps = {
  name: string;
  placeholder?: string;
  textError?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  value?: string;
  className?: string;
};

export const Input: React.FC<InputProps> = ({ name, placeholder, textError, value, className, onChange, onBlur }) => (
  <InputLightWrapper className={className}>
    <input name={name} placeholder={placeholder} value={value} type={'text'} onChange={onChange} onBlur={onBlur} />
    {textError && <Error>{textError}</Error>}
  </InputLightWrapper>
);
