import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const Page403: React.FC = () => {
  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div>
      <div>Доступ заблокирован!</div>
      <div>К сожалению, у Вас недостаточно прав.</div>
      <button onClick={handleBack}>Вернуться назад</button>
    </div>
  );
};
