import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEmailReceiversApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  emailId: string;
  page: number;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const getEmailReceivers = createAsyncThunk<object, PayloadType>('get/emailReceivers', async payload => {
  const { wsId, emailId, page, errorCallback, successCallback } = payload;
  try {
    const response = await getEmailReceiversApi({ wsId, emailId, page });

    if (response.status === 200) {
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
