import { AxiosPromise } from 'axios';
import { IContact } from 'processes/store';
import { IError, apiInstance } from '../base';

type Request = {
  wsId: string;
  file: File;
};

type Response = {
  result: IContact[];
  errors: IError[];
};

export const importContactsExcelApi = ({ wsId, file }: Request): AxiosPromise<Response> => {
  const formData = new FormData();
  formData.append('file', file);

  return apiInstance.post(`ws/${wsId}/contacts/import`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });
};
