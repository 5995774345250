import React, { useEffect, useState } from 'react';
import { Avatar, SettingsDropdown } from 'shared/ui';
import { PlusIcon, SearchIcon } from 'shared/lib/Icons';
import { IContact } from 'processes/store';
import {
  AddUserButton,
  Contact,
  DropdownList,
  Email,
  InfoBlock,
  Input,
  InputBlock,
  Label,
  ListWrapper,
  Name,
} from './styled';

type Props = {
  list: IContact[];
  selectedIds?: string[];
  setSelectedIds?: (id: string[]) => void;
};

export const EmailAddContactDropdown: React.FC<Props> = ({ list, selectedIds = [], setSelectedIds }) => {
  const [showList, setShowList] = useState<IContact[]>([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    list && setShowList(list);
  }, [list]);

  const handleClick = (id: string) => {
    setSelectedIds && setSelectedIds([...selectedIds, id]);
    setShowList(prev => prev.filter(el => el._id !== id));
  };

  return (
    <SettingsDropdown
      open={open}
      setOpen={setOpen}
      label={
        <AddUserButton onClick={() => setOpen(true)}>
          <PlusIcon />
        </AddUserButton>
      }
    >
      <DropdownList>
        <Label>Емаил</Label>
        <InputBlock>
          <SearchIcon />
          <Input placeholder='Поиск контактам' />
        </InputBlock>
        <Label>Контакты</Label>
        <ListWrapper>
          {showList?.map(({ _id, fullName, emails }) => (
            <Contact key={_id} onClick={() => handleClick(_id)}>
              <Avatar size={32} src='' />
              <InfoBlock>
                <Name>{fullName}</Name>
                <Email>{emails[0]?.value}</Email>
              </InfoBlock>
            </Contact>
          ))}
        </ListWrapper>
      </DropdownList>
    </SettingsDropdown>
  );
};
