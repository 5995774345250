import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGroupList } from 'processes/store';
import { getGroupsApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  page: number;
  successCallback?: () => void;
  errorCallback?: (err?: unknown) => void;
};

export const getGroupsAction = createAsyncThunk<object, PayloadType>('get/groups', async (payload, { dispatch }) => {
  const { wsId, page, successCallback, errorCallback } = payload;

  try {
    const response = await getGroupsApi({ wsId, page });
    if (response.status === 200) {
      dispatch(setGroupList({ groupList: response.data.result.list }));
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback(err);
  }
});
