import styled from 'styled-components';

export const StepBlock = styled.div`
  display: flex;
  font-size: 11px;
  line-height: 11px;
  color: #adb8cd;

  span {
    margin: 0 7px;
  }
`;

export const StepElem = styled.div<{ size?: number }>`
  padding-top: 1px;
  text-align: center;
  width: ${({ size }) => (size ? size : '16px')};
  height: ${({ size }) => (size ? size : '16px')};
  border: 1px solid #d9dfeb;
  border-radius: 3px;
`;
