import { createAsyncThunk } from '@reduxjs/toolkit';

type PayloadType = {
  email: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const inviteUserAction = createAsyncThunk<object, PayloadType>('ws/update', async (payload, { dispatch }) => {
  const { errorCallback, successCallback } = payload;
  try {
    // const response = await inviteUserApi({email});
    // if (response.status === 200) {
    successCallback && successCallback();
    // }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
