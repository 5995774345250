import styled from 'styled-components';

export const Tag = styled.div`
  color: var(--color-white);
  font-family: var(--font-bold);
  padding: 0 11px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  font-size: 10px;
  background-color: var(--color-gray-text);
  text-transform: uppercase;
`;
