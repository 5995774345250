import styled from 'styled-components';

type ProgressBarType = {
  width?: string;
  height?: string;
  bgColor?: string;
};

export const ProgressBar = styled.div<ProgressBarType>`
  width: ${({ width }) => (width ? width : '206px')};
  height: ${({ height }) => (height ? height : '2px')};
  background: ${({ bgColor }) => (bgColor ? bgColor : 'var(--color-gray-dark)')};
`;

type ProgressLineType = {
  width: number;
  color?: string;
};
export const ProgressLine = styled.div<ProgressLineType>`
  height: 100%;
  width: ${({ width }) => width}%;
  background: ${({ color }) => (color ? color : 'var(--color-blue)')};
`;
