import styled from 'styled-components';
import { flexCol, flexRow, flexFull, alignCenter, alignMiddle, spaceBetween } from 'shared/lib/mixins';
import { IconButton } from 'shared/ui';
import { Divider } from 'shared/ui';

export const Container = styled.section`
  ${flexCol};
  ${flexFull};
`;

export const Header = styled.header`
  padding: 0 35px;
  column-gap: 10px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const Title = styled.h1`
  font-size: 19px;
  line-height: 20px;
  font-family: var(--font-medium);
`;

export const ContentWrapper = styled.div`
  ${flexCol};
  ${flexFull};
  ${alignCenter};
  padding: 50px 0 20px;
  overflow-y: auto;
  /* height: calc(100vh - 70px); */
`;

export const Content = styled.div`
  width: 800px;
`;

export const WrapperSections = styled.div`
  ${flexCol};
  row-gap: 30px;
`;

export const EditSection = styled.div`
  ${flexCol};
  row-gap: 15px;
`;

export const Label = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-gray-label);
`;

export const EditBlock = styled.div`
  padding: 25px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 10px;
`;

export const EditBlockUsers = styled.div`
  border: 1px solid var(--color-gray-dark);
  border-radius: 10px;
`;

export const UserMain = styled.div`
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
`;

export const UserInfo = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 20px;
`;

export const UserAvatarDefault = styled.div`
  ${flexRow};
  ${alignMiddle};
  width: 58px;
  height: 58px;
  border-radius: 50%;
  border: 1px solid var(--color-gray-dark);
  color: var(--color-white);
  font-family: var(--font-bold);
  font-size: 20px;
  svg {
    fill: var(--color-gray-text);
  }
`;

export const UserData = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 7px;
`;

export const UserTitle = styled.span`
  font-size: 19px;
  line-height: 20px;
  font-family: var(--font-medium);
`;

export const UserNotation = styled.span`
  font-size: 14px;
  color: var(--color-gray-label);
`;

export const UserStatus = styled.span`
  font-size: 14px;
  color: var(--color-gray-label);
`;

export const UsersSearch = styled.div`
  ${flexRow};
  padding: 25px;
  column-gap: 20px;
  border-bottom: 1px solid var(--color-gray-dark);
`;

export const SearchField = styled.div`
  ${flexFull};
`;

export const SearchActions = styled.div`
  ${flexRow};
  column-gap: 20px;
`;

export const FilterButton = styled.button`
  ${flexRow};
  ${alignMiddle};
  column-gap: 10px;
  width: 159px;
  height: 44px;
  cursor: pointer;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
`;

export const UserItem = styled.div`
  ${flexRow};
  ${alignCenter};
  ${spaceBetween};
  padding: 15px 30px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-dark);
  }
`;

export const UserItemData = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 15px;
`;

export const UserItemActions = styled.div`
  ${flexRow};
  ${alignCenter};
  column-gap: 16px;
`;

export const UserAvatarBlock = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const UserItemTitle = styled.span`
  font-size: 14px;
  line-height: 26px;
  font-family: var(--font-medium);
`;

export const AccessAction = styled.div`
  ${flexRow};
  ${alignCenter};
  font-size: 14px;
  line-height: 16px;
  column-gap: 11px;
  cursor: pointer;
`;

export const DropdownList = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
`;

export const Text = styled.div<{ color?: string; border?: boolean }>`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  padding: 0 20px;
  color: ${({ color }) => (color ? color : 'black')};
  border-bottom: ${({ border }) => (!border ? 'none' : '1px solid #d9dfeb')};
  align-items: center;
`;

export const DeleteButton = styled(IconButton)`
  max-width: max-content;
  max-height: max-content;
  margin-right: 10px;
  svg {
    fill: #ff0000;
  }
`;

export const DividerCustom = styled(Divider)`
  border: none;
  background-color: #d9dfeb;
  height: 3px;
`;

export const AccessText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
