import { RequestState } from 'shared/lib/types';
import { IGroup } from '../types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  groupList: [] as IGroup[],
  group: {} as IGroup,
  status: RequestState.PENDING,
  needUpdateGroup: false,
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<{ group: IGroup }>) => {
      state.group = action.payload.group;
    },
    setGroupList: (state, action: PayloadAction<{ groupList: IGroup[] }>) => {
      state.groupList = [...action.payload.groupList.filter(el => !el.inArchive)];
    },
    setNeedUpdateGroup: (state, action: PayloadAction<boolean>) => {
      state.needUpdateGroup = action.payload;
    },
  },
});

export const { setGroup, setGroupList, setNeedUpdateGroup } = groupSlice.actions;

export default groupSlice.reducer;
