import { createAsyncThunk } from '@reduxjs/toolkit';
import { addContactsTagsApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  color: string;
  title: string;
  description?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const addTagsAction = createAsyncThunk<object, PayloadType>('add/contactTags', async (payload, { dispatch }) => {
  const { wsId, color, title, description, errorCallback, successCallback } = payload;
  try {
    const response = await addContactsTagsApi({ wsId, color, title, description });
    if (response.status === 200) {
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
