import React from 'react';
import { ProgressBar, ProgressLine } from './styled';

type Props = {
  percentage: number;
  width?: string;
  height?: string;
  bgColor?: string;
  color?: string;
};

export const Progress: React.FC<Props> = ({ percentage, width, height, bgColor, color }) => {
  return (
    <ProgressBar width={width} height={height} bgColor={bgColor}>
      <ProgressLine width={percentage} color={color} />
    </ProgressBar>
  );
};
