import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';
import { IUser } from 'processes/store';

type Response = {
  result: IUser;
  errors: IError[];
};

export const updateUserApi = (params: Partial<IUser>): AxiosPromise<Response> => {
  return apiInstance.patch('user/me', {
    ...params,
  });
};
