import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
`;

export const Text = styled.h2`
  margin-left: 10px;
  font-size: 24px;
  font-weight: bold;
`;
