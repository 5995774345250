import { createAsyncThunk } from '@reduxjs/toolkit';
import { setUser } from 'processes/store';
import { getUserInfoApi } from 'shared/api';

type PayloadType = {
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const getUserInfoAction = createAsyncThunk<object, PayloadType>('user/me', async (payload, { dispatch }) => {
  const { successCallback, errorCallback } = payload;
  try {
    const userResponse = await getUserInfoApi();
    if (userResponse.status === 200) {
      dispatch(
        setUser({
          user: userResponse.data.result,
        }),
      );
      successCallback?.();
    }
  } catch (err) {
    errorCallback?.();
  }
});
