import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContactForm, ContactTouches } from 'features/Contact';
import {
  ModalType,
  selectContact,
  selectGroup,
  selectTouch,
  selectUser,
  selectWs,
  useAppDispatch,
} from 'processes/store';
import { Authorized } from 'processes/Authorized';
import { generateAvatarLink } from 'shared/lib/generateAvatarLink';
import { BackArrow, SmsIcon } from 'shared/lib/Icons';
import { ROUTE_WS } from 'shared/lib/routes';
import { RequestState } from 'shared/lib/types';
import { useModal } from 'shared/hooks';
import { Avatar, MainTemplate, Spinner } from 'shared/ui';
import { createEmailAction, getContactAction, getTouchesAction } from './model';
import {
  ActionButton,
  ActionsBlock,
  AddButton,
  BackButton,
  ButtonsWrapper,
  CompanyTag,
  ContactSideBar,
  Containter,
  ContentWrapper,
  Count,
  GroupCard,
  GroupsList,
  Header,
  NameBlock,
  NameWrapper,
  ShowMoreGroups,
  Tab,
  Tabs,
  Title,
} from './styled';

export const ContactPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(RequestState.PENDING);
  const [activeLeftTab, setActiveLeftTab] = useState(1);
  const [activeRightTab, setActiveRightTab] = useState(1);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { showModal } = useModal();

  const { contact, needUpdateContact } = useSelector(selectContact);
  const { touchesList, needUpdateTouch } = useSelector(selectTouch);
  const { groupList } = useSelector(selectGroup);
  const { ws } = useSelector(selectWs);
  const { user } = useSelector(selectUser);

  const contactId = useMemo(() => contact._id, [contact._id]);

  const id = location.pathname.split('/').at(-1);
  const filteredGroups = groupList.filter(el => contact._groupId?.includes(el._id));

  useEffect(() => {
    needUpdateContact && setIsNeedUpdate(needUpdateContact);
  }, [needUpdateContact]);

  useEffect(() => {
    setIsLoading(RequestState.LOADING);
    id &&
      ws._id &&
      dispatch(
        getContactAction({
          wsId: ws._id,
          id,
          successCallback: () => {
            setIsFirstLoad(false);
            setIsLoading(RequestState.SUCCESS);
            setIsNeedUpdate(false);
          },
        }),
      );
  }, [dispatch, ws._id, id, isNeedUpdate]);

  useEffect(() => {
    ws._id &&
      contactId &&
      dispatch(
        getTouchesAction({
          wsId: ws._id,
          page: 1,
          contactId: contactId,
          successCallback: () => {},
        }),
      );
  }, [ws, contactId, needUpdateTouch, dispatch]);

  const handleClick = useCallback(() => {
    showModal(ModalType.AddContactInGroup, {
      wsId: ws._id,
      contact,
      groups: groupList,
      successCallback: () => {
        setIsNeedUpdate(true);
      },
    });
  }, [showModal, ws._id, contact, groupList]);

  const writeEmail = () => {
    dispatch(
      createEmailAction({
        wsId: ws._id,
        successCallback: (id?: string) =>
          navigate(`${ROUTE_WS}/${ws._id}/emails/${id}?receiver=${encodeURIComponent(contact._id)}`),
        errorCallback: () => {},
      }),
    );
  };

  return (
    <Authorized>
      <MainTemplate>
        {isLoading !== 'success' && isFirstLoad ? (
          <Spinner />
        ) : (
          <Containter>
            <Header>
              <Title>
                <BackButton onClick={() => window.history.back()}>
                  <BackArrow />
                </BackButton>
                {!!filteredGroups.length && (
                  <>
                    <div>Видно в</div>
                    <GroupsList>
                      {filteredGroups.slice(0, 3).map(el => (
                        <GroupCard key={el._id} onClick={() => navigate(`${ROUTE_WS}/${ws._id}/groups/${el._id}#list`)}>
                          {el.title}
                        </GroupCard>
                      ))}
                      {filteredGroups.length > 3 && <ShowMoreGroups>+ {filteredGroups.slice(3).length}</ShowMoreGroups>}
                      <AddButton onClick={handleClick}>+</AddButton>
                    </GroupsList>
                  </>
                )}
              </Title>
              {/* <ButtonsBlock>
                <ShareButton>
                  <ShareIcon />
                </ShareButton>
                <SettingsButton>
                  <ThreePointsIcon />
                </SettingsButton>
              </ButtonsBlock> */}
            </Header>
            <NameWrapper>
              <NameBlock>
                <Avatar
                  src={
                    contact.imageSrc
                      ? generateAvatarLink({ src: contact.imageSrc, wsId: ws._id, id: contact._id, size: '50x50' })
                      : contact.imageSrc
                  }
                  size={50}
                />
                <div>{contact?.title}</div>
                {!!contact?.companies.length && <CompanyTag>{contact?.companies[0]?.value}</CompanyTag>}
              </NameBlock>
              <ButtonsWrapper>
                {/* <ActionButton>
                  <CallIcon />
                  Позвонить
                </ActionButton> */}
                <ActionButton onClick={writeEmail}>
                  <SmsIcon />
                  Написать
                </ActionButton>
                {/* <ActionButton>
                  <NotificationIcon />
                  Напоминание
                </ActionButton>
                <ActionButton>
                  <AddCommentIcon />
                  Комментарий
                </ActionButton> */}
              </ButtonsWrapper>
            </NameWrapper>
            <ContentWrapper>
              <ContactSideBar>
                <div>
                  <Tabs>
                    <Tab onClick={() => setActiveLeftTab(1)} active={activeLeftTab === 1}>
                      Контакты
                    </Tab>
                    {/* <Tab onClick={() => setActiveLeftTab(2)} active={activeLeftTab === 2}>
                      Связанные контакты
                    </Tab> */}
                  </Tabs>
                  <ContactForm contact={contact} ws={ws} groups={groupList} />
                </div>
                {/* <BottomWrapper>
                  <ActionButton>
                    <PlusIcon />
                    Новое поле
                  </ActionButton>
                  <ActionButton>
                    <EyeIcon />
                    Скрыть поля
                  </ActionButton>
                </BottomWrapper> */}
              </ContactSideBar>
              <ActionsBlock>
                <Tabs gap={35}>
                  <Tab onClick={() => setActiveRightTab(1)} active={activeRightTab === 1}>
                    Касания <Count>0</Count>
                  </Tab>
                  <Tab onClick={() => setActiveRightTab(2)} active={activeRightTab === 2}>
                    Задачи <Count>0</Count>
                  </Tab>
                  <Tab onClick={() => setActiveRightTab(3)} active={activeRightTab === 3}>
                    Комментарии <Count>0</Count>
                  </Tab>
                  <Tab onClick={() => setActiveRightTab(4)} active={activeRightTab === 4}>
                    Напоминания <Count>0</Count>
                  </Tab>
                </Tabs>
                {activeRightTab === 1 && <ContactTouches list={touchesList} user={user} contact={contact} />}
              </ActionsBlock>
            </ContentWrapper>
          </Containter>
        )}
      </MainTemplate>
    </Authorized>
  );
};
