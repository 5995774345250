import { Button } from 'shared/ui';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  min-width: 400px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleBlock = styled.div`
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: #000000;
`;

export const CloseButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ContentBlock = styled.div`
  margin-top: 20px;
  row-gap: 20px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

export const ChipBlock = styled.div`
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  height: 40px;
  width: 100%;
  padding: 4px;
`;

export const Chip = styled.div`
  border: 1px solid black;
  padding: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #6a7b9b;
  border-radius: 8px;
  max-height: 30px;
  cursor: pointer;
`;

export const Group = styled.div`
  color: #0b0b0a;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
`;

export const Footer = styled.div`
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
`;

export const DivBlock = styled.div`
  padding: 15px 30px;
`;

export const CancelButton = styled(Button)`
  background: none;
  border: 1px solid #d9dfeb;
  div {
    font-size: 16px;
    line-height: 16px;
    color: #adb8cd;
  }
`;

export const SaveButton = styled(Button)`
  width: 168px;
`;
