import React, { useCallback, useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ActionMeta } from 'react-select';
import { getTime } from 'date-fns';
import { IContact, InitialModalState, selectGroup, selectWs, useAppDispatch } from 'processes/store';
import { ROUTE_WS } from 'shared/lib/routes';
import { clearMaskPhone } from 'shared/lib/clearMaskPhone';
import { Button, DatePicker, Tab, Tabs } from 'shared/ui';
import {
  AvatarIcon,
  CakeIcon,
  CallIcon,
  CloseIcon,
  CompanyIcon,
  GenderIcon,
  LinksIcon,
  LocationIcon,
  NoteIcon,
  RoleIcon,
  SmsIcon,
  UserIcon,
} from 'shared/lib/Icons';
import { Field } from './ui';
import { addManuallyContactAction, getGroupsAction } from './model';
import {
  Avatar,
  AvatarBlock,
  BottomBlock,
  CloseButton,
  DivWrapper,
  Input,
  ModalWrapper,
  ScrollWrapper,
  SelectGender,
  SelectGroups,
  TabsWrapper,
  TitleBlock,
} from './styled';

type ListType = {
  value: string;
  isFavorites: boolean;
};

export type RemoveType = {
  name: string;
  index: number;
  needRemove: boolean;
};

type Props = {
  isShowModal: boolean;
  params?: InitialModalState['params'];
  onCloseModal: () => void;
};

const gender = [
  { value: 'male', label: 'Мужской' },
  { value: 'female', label: 'Женский' },
];

const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export const AddManuallyContactModal: React.FC<Props> = ({ onCloseModal, params }) => {
  const [isActive, setIsActive] = useState(params?.tab || '1');
  const [$isEmpty, setIsEmpty] = useState(true);
  const [needRemove, setNeedRemove] = useState<RemoveType>();
  const [selectedGroup, setSelectedGroup] = useState('');
  const [errorPhone, setErrorPhone] = useState('');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { ws } = useSelector(selectWs);
  const { groupList } = useSelector(selectGroup);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    clearErrors,
    formState: { errors },
  } = useForm<Partial<IContact>>({ mode: 'onSubmit' });

  const groupsOptions = groupList?.map(el => ({
    value: el._id,
    label: el.title,
  }));

  useEffect(() => {
    clearErrors();
  }, [clearErrors, isActive]);

  useEffect(() => {
    ws._id && dispatch(getGroupsAction({ wsId: ws._id, page: 1 }));
  }, [ws._id, dispatch]);

  const handleClick = (tab: string) => {
    setIsActive(tab);
  };

  const handleSelectGender = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    //@ts-ignore
    setValue('gender', newValue.value);
  };

  const handleSelectGroup = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    //@ts-ignore
    setSelectedGroup(newValue.value);
  };

  const onSubmit: SubmitHandler<Partial<IContact>> = useCallback(
    data => {
      const birthday = data.birthDay && typeof data.birthDay === 'object' ? getTime(data.birthDay) : null;

      const filteredList = (l: ListType[] | undefined) =>
        l
          ?.filter(el => el.value)
          .map((el, i) => ({
            ...el,
            isFavorites: i === 0,
          }));

      const tmp = {
        ...data,
        addresses: data?.addresses?.filter(el => el.value),
        links: data?.links?.filter(el => el.value),
        roles: data?.roles?.filter(el => el.value),
        companies: filteredList(data.companies),
        emails: filteredList(data?.emails),
        phones: data.phones
          ?.filter(el => el.value)
          .map(el => ({
            value: clearMaskPhone(el.value),
            isFavorites: el.isFavorites,
          })),
        birthDay: birthday,
        isOrganization: isActive === '2',
        _groupId: selectedGroup || groupsOptions[0]?.value ? [selectedGroup || groupsOptions[0]?.value] : [],
        note: Array.isArray(data.note) ? '' : data.note,
      };

      ws._id &&
        dispatch(
          addManuallyContactAction({
            wsId: ws._id,
            params: tmp,
            successCallback: (id?: string) => {
              navigate(`${ROUTE_WS}/${ws._id}/contacts/${id}`);
              onCloseModal();
            },
            errorCallback: (err: {
              name: string;
              message: string;
              response: { data: { error: { misc: { [key: string]: string } } } };
            }) => {
              const { misc } = err.response.data.error;
              if (Object.keys(misc).at(-1) === 'phones') {
                setErrorPhone('Невалидный номер телефона');
              }
            },
          }),
        );
    },
    [ws._id, selectedGroup, groupsOptions, isActive, dispatch, onCloseModal, navigate],
  );

  const handleTelephone = useCallback(
    (phone: string, index: number) => {
      setValue(`phones.${index}.value`, phone);
      setErrorPhone('');
    },
    [setValue],
  );

  const formData = watch();

  const delCode = useCallback(
    (event: any) => {
      if (event?.nativeEvent?.target?.value === '+7(___)___ __ __') {
        setIsEmpty(true);
        const index = formData?.phones?.findIndex(phone => phone.value === '+7(___)___ __ __');
        setNeedRemove({ name: 'phones', index: index || 0, needRemove: true });
      }
      return setIsEmpty(false);
    },
    [formData?.phones],
  );

  const onBlur = useCallback((event: any) => {
    if (event?.nativeEvent?.target?.value === '' || event?.nativeEvent?.target?.value === '+7(___)___ __ __') {
      const index = Number(event?.nativeEvent?.target?.attributes?.name?.value.replace(/\D/g, ''));
      const fieldName = event?.nativeEvent?.target?.attributes?.name?.value.match(/[^.]+/)[0];
      setNeedRemove({ name: fieldName, index: index, needRemove: true });
    }
  }, []);

  return (
    <ModalWrapper>
      <TitleBlock>
        <div>Добавить в</div>
        <SelectGroups
          onChange={handleSelectGroup}
          components={{
            IndicatorSeparator: () => null,
          }}
          defaultValue={groupsOptions ? groupsOptions[0] : 'Выберите группу'}
          placeholder='Выберите группу'
          options={groupsOptions}
        />
        <CloseButton onClick={onCloseModal}>
          <CloseIcon />
        </CloseButton>
      </TitleBlock>
      <TabsWrapper>
        <Tabs>
          <Tab onClick={() => handleClick('1')} active={isActive === '1'}>
            <div>
              <UserIcon />
            </div>
            <div>Контакт</div>
          </Tab>
          <Tab onClick={() => handleClick('2')} active={isActive === '2'}>
            <div>
              <CompanyIcon />
            </div>
            <div>Компания</div>
          </Tab>
        </Tabs>
      </TabsWrapper>
      <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <ScrollWrapper>
          <DivWrapper flex={true}>
            <AvatarBlock>
              <Avatar>
                <AvatarIcon />
              </Avatar>
            </AvatarBlock>
            <Input
              placeholder={isActive === '1' ? 'Имя Фамилия' : 'Название компании'}
              name={isActive === '1' ? 'title' : 'organizationTitle'}
              register={register}
              rules={{
                required: true,
                maxLength: 250,
              }}
              hasError={!!errors[isActive === '1' ? 'title' : 'organizationTitle']}
              textError={!!errors.title || !!errors.organizationTitle ? 'Обязательное поле' : ''}
              isFocus={!!watch(isActive === '1' ? 'title' : 'organizationTitle')}
            />
          </DivWrapper>
          {isActive === '1' && (
            <>
              <Field
                label='Компании'
                name='companies'
                placeholder='Добавить компанию'
                icon={<CompanyIcon />}
                register={register}
                onBlur={onBlur}
                control={control}
                needRemove={needRemove}
                setNeedRemove={setNeedRemove}
                isAddBtn={
                  formData?.companies &&
                  formData?.companies?.length > 0 &&
                  formData?.companies[formData.companies?.length - 1].value !== ''
                }
              />
              <Field
                label='Должность'
                name='roles'
                placeholder='Добавить должность'
                icon={<RoleIcon />}
                register={register}
                control={control}
                onBlur={onBlur}
                needRemove={needRemove}
                setNeedRemove={setNeedRemove}
                isAddBtn={
                  formData?.roles &&
                  formData?.roles?.length > 0 &&
                  formData?.roles[formData.roles?.length - 1].value !== ''
                }
              />
            </>
          )}
          <Field
            label='Емаил'
            name='emails'
            placeholder='Добавить емаил'
            icon={<SmsIcon />}
            register={register}
            control={control}
            onBlur={onBlur}
            needRemove={needRemove}
            setNeedRemove={setNeedRemove}
            rules={{
              required: {
                value: true,
                message: 'Обязательное поле',
              },
              pattern: {
                value: reg,
                message: 'Введите корректный емаил',
              },
            }}
            hasError={!!errors.emails?.length}
            textError={errors.emails?.length ? errors.emails[0]?.value?.message : ''}
            isFocus={!!watch('emails')}
            isAddBtn={
              formData?.emails &&
              formData?.emails?.length > 0 &&
              formData?.emails[formData.emails?.length - 1].value !== ''
            }
          />
          <Field
            label='Телефон'
            name='phones'
            icon={<CallIcon />}
            register={register}
            control={control}
            delCode={delCode}
            needRemove={needRemove}
            setNeedRemove={setNeedRemove}
            placeholder='Добавить телефон'
            handleTelephone={handleTelephone}
            $isEmpty={$isEmpty}
            textError={errorPhone}
            isAddBtn={
              formData?.phones &&
              formData?.phones?.length > 0 &&
              !!formData?.phones[formData.phones?.length - 1].value &&
              formData?.phones[formData.phones?.length - 1].value !== ''
            }
          />
          {isActive === '1' && (
            <>
              <DivWrapper>
                <label>
                  <div>
                    <CakeIcon />
                  </div>
                  <div>День рождения</div>
                </label>
                <Controller
                  control={control}
                  name='birthDay'
                  render={({ field }) => (
                    <DatePicker
                      placeholder='Выберите дату'
                      dateFormat='dd.MM.yyyy'
                      onChange={date => field.onChange(date)}
                      initDate={field.value ? new Date(field.value) : null}
                    />
                  )}
                />
              </DivWrapper>

              <DivWrapper>
                <label>
                  <div>
                    <GenderIcon />
                  </div>
                  <div>Пол</div>
                </label>
                <SelectGender
                  // menuIsOpen
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={handleSelectGender}
                  options={gender}
                  // defaultInputValue='Мужской'
                  defaultValue='male'
                  placeholder='Выберите пол'
                  name='gender'
                />
              </DivWrapper>
            </>
          )}

          <Field
            label='Адрес'
            name='addresses'
            placeholder='Добавить адрес'
            icon={<LocationIcon />}
            register={register}
            control={control}
            needRemove={needRemove}
            setNeedRemove={setNeedRemove}
            onBlur={onBlur}
            isAddBtn={
              formData?.addresses &&
              formData?.addresses?.length > 0 &&
              formData?.addresses[formData.addresses?.length - 1].value !== ''
            }
          />

          <Field
            label='Ссылки'
            name='links'
            placeholder='Добавить ссылку'
            icon={<LinksIcon />}
            register={register}
            control={control}
            needRemove={needRemove}
            setNeedRemove={setNeedRemove}
            onBlur={onBlur}
            isAddBtn={
              formData?.links && formData?.links?.length > 0 && formData?.links[formData.links?.length - 1].value !== ''
            }
          />

          <Field
            label='Заметка'
            name='note'
            placeholder='Добавить заметку'
            icon={<NoteIcon />}
            register={register}
            control={control}
            isNoArray
            isAddBtn={false}
          />
        </ScrollWrapper>
        <BottomBlock>
          <Button onClick={onCloseModal} label='Отмена' />
          <Button type='submit' label='Создать' />
        </BottomBlock>
      </form>
    </ModalWrapper>
  );
};
