import React, { useEffect, useState } from 'react';
import { IContact, ITouches, IUser } from 'processes/store';
import { FilterDropdown, SearchBlock, Touches } from './ui';
import { ActionBlock, ListWrapper, Wrapper } from './styled';

type Props = {
  list: ITouches[];
  user: IUser;
  contact: IContact;
};

export const ContactTouches: React.FC<Props> = ({ list, user, contact }) => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<string[]>(['emailReceived', 'emailSent']);

  const touchId = decodeURIComponent(new URL(window.location.href).searchParams.get('touch') || '');
  const element = document.getElementById(touchId);

  useEffect(() => {
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [element]);

  return (
    <Wrapper>
      <ActionBlock>
        <FilterDropdown filters={filters} setFilters={setFilters} />
        <SearchBlock setSearch={setSearch} />
      </ActionBlock>
      <ListWrapper>
        {list
          .filter(el => filters.includes(el.action))
          .map(el => (
            <React.Fragment key={el._id}>
              <Touches touch={el} user={user} contact={contact} />
            </React.Fragment>
          ))}
      </ListWrapper>
    </Wrapper>
  );
};
