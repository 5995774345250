import styled from 'styled-components';
import { alignCenter, flexCol, flexFull, flexRow } from 'shared/lib/mixins';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
  position: relative;
  width: calc(100% - 300px);
`;

export const Header = styled.header`
  padding: 0 35px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
  justify-content: space-between;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: var(--color-black);
`;

export const ActionButtons = styled.div`
  max-height: 90px;
  padding: 25px 35px;
  display: flex;
  justify-content: space-between;
`;

export const EmptyData = styled.div`
  color: var(--color-mybase-gray-text);
  margin: auto;
  font-size: 20px;
  line-height: 24px;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const FooterBlock = styled.footer<{ justify?: string }>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'space-between')};
  width: 100%;
  bottom: 0;
  padding: 12px 20px 12px 33px;
  max-height: 60px;
  border: 1px solid rgb(217, 223, 235);
  background: white;
`;

export const SearchBlock = styled.div`
  max-height: 72px;
  padding: 15px 35px;
`;

export const SearchField = styled.div`
  ${flexFull};
  position: relative;

  button {
    position: absolute;
    top: 4px;
    right: 0;
  }
`;

export const SearchInput = styled.input`
  padding: 14px 20px 12px;
  width: 100%;
  height: 42px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
`;
