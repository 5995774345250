export const countriesData = [
  {
    country: 'Россия',
    code: '+7',
  },
  {
    country: 'Афганистан',
    code: '+93',
  },
  {
    country: 'Албания',
    code: '+355',
  },
  {
    country: 'Алжир',
    code: '+213',
  },
  {
    country: 'Андорра',
    code: '+376',
  },
  {
    country: 'Ангола',
    code: '+244',
  },
  {
    country: 'Аргентина',
    code: '+54',
  },
  {
    country: 'Армения',
    code: '+374',
  },
  {
    country: 'Австралия',
    code: '+61',
  },
  {
    country: 'Австрия',
    code: '+43',
  },
  {
    country: 'Азербайджан',
    code: '+994',
  },
  {
    country: 'Багамы',
    code: '+1-242',
  },
  {
    country: 'Бахрейн',
    code: '+973',
  },
  {
    country: 'Бангладеш',
    code: '+880',
  },
  {
    country: 'Барбадос',
    code: '+1-246',
  },
  {
    country: 'Беларусь',
    code: '+375',
  },
  {
    country: 'Бельгия',
    code: '+32',
  },
  {
    country: 'Белиз',
    code: '+501',
  },
  {
    country: 'Бенин',
    code: '+229',
  },
  {
    country: 'Бутан',
    code: '+975',
  },
  {
    country: 'Боливия',
    code: '+591',
  },
  {
    country: 'Босния и Герцеговина',
    code: '+387',
  },
  {
    country: 'Ботсвана',
    code: '+267',
  },
  {
    country: 'Бразилия',
    code: '+55',
  },
  {
    country: 'Бруней',
    code: '+673',
  },
  {
    country: 'Болгария',
    code: '+359',
  },
  {
    country: 'Буркина Фасо',
    code: '+226',
  },
  {
    country: 'Бурунди',
    code: '+257',
  },
  {
    country: 'Камбоджа',
    code: '+855',
  },
  {
    country: 'Камерун',
    code: '+237',
  },
];
