import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  color: inherit;
`;

export const Wrapper = styled.div<{ isHidden?: boolean }>`
  visibility: ${props => (props.isHidden ? 'hidden' : '')};
  opacity: ${props => (props.isHidden ? '0' : '1')};
  z-index: 99;
`;
