import { AxiosPromise } from 'axios';
import { IContact } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  params: Partial<IContact>;
};

type Response = {
  result: IContact;
  errors: IError[];
};

export const updateContactApi = ({ wsId, params }: Request): AxiosPromise<Response> => {
  return apiInstance.patch(`ws/${wsId}/contacts/${params._id}`, {
    ...params,
  });
};
