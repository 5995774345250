import { FC, useState } from 'react';
import { SettingsDropdown } from 'shared/ui';
import { ThreePointsIcon } from 'shared/lib/Icons';
import { StatusBlock } from '../StatusBlock';
import { Chip, SettingsBtn, Text } from './styled';

type Props = {
  id?: string;
  title: string;
  color: string;
  selected?: boolean;
  onSelect: () => void;
  onDelete?: () => void;
  onBlur?: (e: { id: string; color?: string; title?: string }) => void;
};

export const StatusElement: FC<Props> = ({ id, title, color, selected, onSelect, onDelete, onBlur }) => {
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    onDelete && onDelete();
    setOpen(false);
  };

  return (
    <Text>
      <Chip onClick={onSelect} color={color} selected={selected}>
        {title}
      </Chip>
      <SettingsDropdown
        open={open}
        setOpen={() => setOpen(false)}
        offset={[-200, -150]}
        label={
          <SettingsBtn onClick={() => setOpen(true)}>
            <ThreePointsIcon />
          </SettingsBtn>
        }
      >
        <StatusBlock id={id} defaultColor={color} defaultValue={title} deleteBtn={handleDelete} onBlur={onBlur} />
      </SettingsDropdown>
    </Text>
  );
};
