import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';
import { IUser } from 'processes/store';

type Request = {
  password: string;
  fullName: string;
  email: string;
};

type Response = {
  result: IUser;
  errors: IError[];
};

export const signupUserApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.post('user/signup', {
    ...params,
  });
};
