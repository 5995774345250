import { AxiosPromise } from 'axios';
import { IError, apiInstance } from '../base';
import { IGroup } from 'processes/store';

type Request = {
  wsId: string;
  id: string;
};

type Response = {
  result: IGroup;
  error: IError[];
};

export const getGroupApi = ({ wsId, id }: Request): AxiosPromise<Response> => {
  return apiInstance.get(`ws/${wsId}/contacts/groups/${id}`);
};
