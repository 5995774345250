import React from 'react';
import { AvatarBlock, DefaultAvatar } from './styled';
import { AvatarIcon } from 'shared/lib/Icons';

type Props = {
  src: string;
  size?: number;
  className?: string;
};

export const Avatar: React.FC<Props> = ({ src, size, className }) => {
  const isDefaultAvatar = !src;
  return (
    <>
      {!isDefaultAvatar ? (
        <AvatarBlock size={size} className={className}>
          <img src={src} alt='' />
        </AvatarBlock>
      ) : (
        <DefaultAvatar size={size}>
          <AvatarIcon />
        </DefaultAvatar>
      )}
    </>
  );
};
