import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';
import { IUser } from 'processes/store';

type Response = {
  result: IUser;
  errors: IError[];
};

export const getUserInfoApi = (): AxiosPromise<Response> => {
  return apiInstance.get('user/me');
};
