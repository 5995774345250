import styled from 'styled-components';
import { BlueBlock, Button, WhiteBlock } from '../styled';

export const InputWrapper = styled.div`
  margin-top: 15px;
`;
export const DivBlock = styled.div`
  max-height: 641px;
  width: 100%;
  display: flex;
  position: relative;
`;

export const CustomWhiteBlock = styled(WhiteBlock)`
  padding: 40px 65px 50px;
  border-right: 2px solid #d9dfeb;
`;

export const CustomButton = styled(Button)`
  margin-top: 25px;
`;

export const CustomBlueBlock = styled(BlueBlock)`
  padding: 60px 0;
  overflow: hidden;
`;

export const Text = styled.div<{ fontSize?: number }>`
  margin-top: 15px;
  color: #6a7b9b;
  ${({ fontSize }) =>
    fontSize === 12
      ? `
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    `
      : `
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    `}
`;

export const SkipButton = styled.button`
  background: none;
  border: none;
  margin: 25px 0 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #225ffc;
  cursor: pointer;
`;

export const GoBack = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: 0;
  top: -72px;
  padding: 10px;
  cursor: pointer;

  background: #ffffff;
  border-radius: 6px;
`;

export const Title = styled.div`
  margin-top: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;
