import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNeedUpdateGroup } from 'processes/store';
import { updateGroupApi } from 'shared/api/contacts/updateGroupApi';

type PayloadType = {
  wsId: string;
  title: string;
  groupId: string;
  successCallback?: (groupId?: string) => void;
  errorCallback?: (err: unknown) => void;
};

export const updateGroupAction = createAsyncThunk<object, PayloadType>('add/group', async (payload, { dispatch }) => {
  const { wsId, title, groupId, successCallback, errorCallback } = payload;
  try {
    const response = await updateGroupApi({ wsId, title, groupId });
    if (response.status === 200) {
      dispatch(setNeedUpdateGroup(true));
      successCallback && successCallback(response.data.result._id);
    }
  } catch (err) {
    errorCallback && errorCallback(err);
  }
});
