import axios from 'axios';

export const API_URL = process.env.REACT_APP_API_URL;
// TODO THINK HOW DO WITHOUT withCredentials
axios.defaults.withCredentials = true;

export const apiInstance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export interface IError {
  message: string;
  status: number;
  name: string;
}
