import { Select } from 'shared/ui';
import styled from 'styled-components';

export const FieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-dark);
  padding: 0 20px;
`;

export const DivWrapper = styled.div`
  display: flex;
  column-gap: 15px;
  padding: 15px 0;
`;

export const FieldSelect = styled(Select)`
  width: 190px;
  height: 44px;
`;

export const Input = styled.input`
  padding: 15px 15px 13px;
  width: 220px;
  height: 38px;
  border: 1px solid var(--color-gray-dark);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
`;
