import styled from 'styled-components';
import { DefaultButton, Divider, Switcher } from 'shared/ui';

export const DropdownList = styled.div`
  background: var(--color-white);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  min-width: 283px;
`;

export const Label = styled.div`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-gray-label);
  height: 41px;
  padding: 5px 20px;
  display: flex;
  align-items: end;
`;

export const FilterTouchesButton = styled(DefaultButton)`
  width: 165px;
  height: 36px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;

export const DividerCustom = styled(Divider)`
  border: none;
  background-color: var(--color-gray-dark);
  height: 3px;
`;

export const CustomSwitcher = styled(Switcher)`
  margin: 0;
  width: 100%;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  border-bottom: 1px solid var(--color-gray-dark);

  &:hover {
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }

  div:first-child {
    display: flex;
    column-gap: 10px;
  }
`;
