import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ModalType, selectGroup, selectWs, useAppDispatch } from 'processes/store';
import { BadgeAmount, IconButton } from 'shared/ui';
import { useModal } from 'shared/hooks';
import logoSrc from 'shared/img/logo.svg';
import {
  ToggleSidebar,
  SwapUser,
  TouchesNav,
  ContactsNav,
  MessagesNav,
  TasksNav,
  SettingsNav,
  ArrowDown,
  AddBtn,
  DottedBtn,
  SettingsExit,
  CoverDefault,
  DeleteBasket,
} from 'shared/lib/Icons';
import { ROUTE_WS } from 'shared/lib/routes';
import { deleteGroupAction, getContactsAction, getGroupsAction, getCountByGroupsAction } from './model';
import { Dropdown } from '../Dropdown';
import { WsList } from './ui/WsList';
import {
  Wrapper,
  Header,
  MenuSection,
  TitleWrapper,
  SectionTitle,
  SectionTitleWrapper,
  Navigation,
  NavItem,
  NavIcon,
  NavContent,
  Amount,
  GroupsSection,
  GroupsTop,
  GroupsTitle,
  AddGroup,
  GroupsMenu,
  GroupItem,
  GroupItemContent,
  BarToggleButton,
  Content,
  Exit,
  ExitNav,
  LogoPreview,
  GroupToggleButton,
  AccessText,
  DropdownList,
  Text,
  DeleteButton,
} from './styled';

export const Sidebar: React.FC = () => {
  const [isHideBar, setIsHideBar] = useState(false);
  const [showPop, setShowPop] = useState(false);
  const [open, setOpen] = useState(false);
  const [showGroupMenu, setShowGroupMenu] = useState(true);
  const [total, setTotal] = useState(0);
  const [countByGroups, setCountByGroups] = useState<{ [key: string]: number }>({});

  const { needUpdateGroup } = useSelector(selectGroup);
  const { groupList } = useSelector(selectGroup);
  const { ws } = useSelector(selectWs);

  const dispatch = useAppDispatch();
  const { showModal } = useModal();
  const navigate = useNavigate();

  const deps = useMemo(() => [showPop], [showPop]);

  useEffect(() => {
    ws._id && dispatch(getGroupsAction({ wsId: ws._id, page: 1, successCallback: () => {} }));
  }, [ws._id, dispatch, needUpdateGroup]);

  useEffect(() => {
    ws._id &&
      dispatch(
        getCountByGroupsAction({
          wsId: ws._id,
          successCallback: e => {
            setCountByGroups(e);
          },
        }),
      );
  }, [ws._id, dispatch]);

  useEffect(() => {
    ws._id &&
      dispatch(
        getContactsAction({
          wsId: ws._id,
          page: 1,
          isCount: true,
          successCallback: (total?: number) => {
            total && setTotal(total);
          },
        }),
      );
  }, [ws._id, dispatch]);

  const handleToggleHideBar = () => {
    setIsHideBar(!isHideBar);
  };

  const handleDeleteGroup = useCallback(
    (id: string, title: string) => {
      showModal(ModalType.DeleteElement, {
        id,
        title,
        onClick: () => dispatch(deleteGroupAction({ wsId: ws._id, id })),
      });
    },
    [showModal, ws._id, dispatch],
  );

  const handleRenameGroup = useCallback(
    (id: string, title: string) => {
      showModal(ModalType.UpdateGroup, {
        id,
        title,
        onClick: () => dispatch(deleteGroupAction({ wsId: ws._id, id })),
      });
    },
    [showModal, ws._id, dispatch],
  );

  const handleToggleGroupMenu = () => {
    setShowGroupMenu(!showGroupMenu);
  };

  const handleWs = useCallback(() => {
    setShowPop(true);
  }, []);

  const showModalGroup = useCallback(() => {
    showModal(ModalType.CreateGroup);
  }, [showModal]);

  return (
    <Wrapper toggleView={isHideBar}>
      <Header $isActive>
        {!isHideBar && (
          <TitleWrapper>
            <img src={logoSrc} alt='' />
            <span>mybase</span>
          </TitleWrapper>
        )}
        <BarToggleButton onClick={handleToggleHideBar} icon={<ToggleSidebar />} />
      </Header>
      <Content>
        <MenuSection>
          <SectionTitle>
            <SectionTitleWrapper onClick={handleWs}>
              <LogoPreview>
                <CoverDefault width={12} height={10} />
              </LogoPreview>
              {!isHideBar && ws.title}
            </SectionTitleWrapper>
            <Dropdown
              offset={10}
              placement='bottom-end'
              deps={deps}
              delay={300}
              label={!isHideBar && <IconButton icon={<SwapUser />} />}
            >
              <WsList />
            </Dropdown>
          </SectionTitle>
          <Navigation>
            <NavItem to={`${ROUTE_WS}/${ws._id}/touches`}>
              <NavContent>
                <NavIcon>
                  <TouchesNav />
                </NavIcon>
                {!isHideBar && 'Касания'}
              </NavContent>
            </NavItem>
            <NavItem to={`${ROUTE_WS}/${ws._id}/contacts`}>
              <NavContent>
                <NavIcon>
                  <ContactsNav />
                </NavIcon>
                {!isHideBar && 'Контакты'}
              </NavContent>
              {!isHideBar && <Amount>{total}</Amount>}
            </NavItem>
            <NavItem to=''>
              <NavContent>
                <NavIcon>
                  <MessagesNav />
                </NavIcon>
                {!isHideBar && 'Сообщения'}
              </NavContent>
              {!isHideBar && <BadgeAmount amount={6} />}
            </NavItem>
            <NavItem to=''>
              <NavContent>
                <NavIcon>
                  <TasksNav />
                </NavIcon>
                {!isHideBar && 'Задачи'}
              </NavContent>
              {!isHideBar && <Amount>30</Amount>}
            </NavItem>
            {/* <NavItem to={`${ROUTE_WS}/${ws._id}/email`}>
              <NavContent>
                <NavIcon>
                  <SmsIcon />
                </NavIcon>
                {!isHideBar && 'Емаил'}
              </NavContent>
            </NavItem> */}
            <NavItem to={`${ROUTE_WS}/${ws._id}/settings`}>
              <NavContent>
                <NavIcon>
                  <SettingsNav />
                </NavIcon>
                {!isHideBar && 'Настройки'}
              </NavContent>
            </NavItem>
          </Navigation>
        </MenuSection>
        <GroupsSection>
          <GroupsTop>
            {!isHideBar && (
              <GroupsTitle>
                <GroupToggleButton $isActive={showGroupMenu} onClick={handleToggleGroupMenu}>
                  <ArrowDown />
                </GroupToggleButton>
                Группы
              </GroupsTitle>
            )}
            <AddGroup onClick={showModalGroup} icon={<AddBtn />} />
          </GroupsTop>
          {showGroupMenu && (
            <GroupsMenu>
              {groupList.map(el => (
                <GroupItem key={el._id} onClick={() => navigate(`${ROUTE_WS}/${ws._id}/groups/${el._id}#list`)}>
                  {!isHideBar && (
                    <>
                      <GroupItemContent>{el.title}</GroupItemContent>
                      <Amount>{countByGroups[el._id]}</Amount>
                      <Dropdown
                        onChange={setOpen}
                        label={
                          <AccessText>
                            <IconButton active={open} icon={<DottedBtn />} />
                          </AccessText>
                        }
                      >
                        <DropdownList>
                          <Text onClick={() => handleDeleteGroup(el._id, el.title)} color='#FF0000'>
                            <DeleteButton icon={<DeleteBasket />} />
                            Удалить
                          </Text>
                          <Text onClick={() => handleRenameGroup(el._id, el.title)}>
                            {/* <DeleteButton icon={<DeleteBasket />} /> */}
                            Переименовать
                          </Text>
                        </DropdownList>
                      </Dropdown>
                    </>
                  )}
                </GroupItem>
              ))}
            </GroupsMenu>
          )}
        </GroupsSection>
      </Content>
      <Exit>
        <ExitNav to='/logout'>
          <NavIcon>
            <SettingsExit />
          </NavIcon>
          {!isHideBar && 'Выйти'}
        </ExitNav>
      </Exit>
    </Wrapper>
  );
};
