import styled from 'styled-components';
import { BlueBlock, Button, WhiteBlock } from '../styled';

export const DivBlock = styled.div`
  max-height: 641px;
  width: 100%;
  display: flex;
  position: relative;
`;

export const CustomWhiteBlock = styled(WhiteBlock)`
  padding: 40px 65px 50px;

  .text-1 {
    margin: 15px 0;
  }
  .text-3 {
    margin: 10px 0;
  }
`;

export const CustomBlueBlock = styled(BlueBlock)`
  padding: 0;
  overflow: hidden;
`;

export const Text = styled.div`
  margin-top: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #6a7b9b;
  display: flex;

  svg {
    margin-right: 10px;
  }
`;

export const CustomButton = styled(Button)`
  margin-bottom: 20px;
`;

export const SetUpLater = styled.button`
  background: none;
  border: none;
  margin: 25px 0 0;
  padding: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #225ffc;
  cursor: pointer;
`;

export const GoBack = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: 0;
  top: -72px;
  padding: 10px;
  cursor: pointer;

  background: #ffffff;
  border-radius: 6px;
`;

export const Title = styled.div`
  margin: 17px 0 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
  width: 300px;
`;
