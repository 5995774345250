import { IContact } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
};

type Response = {
  result: IContact;
  errors: IError[];
};
export const importContactsApi = ({ wsId }: Request): AxiosPromise<Response> => {
  return apiInstance.post(`ws/${wsId}/contacts/import/google`);
};
