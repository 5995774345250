import { createAsyncThunk } from '@reduxjs/toolkit';
import { addGoogleAccountApi } from 'shared/api';

type PayloadType = {
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const addGoogleAccountAction = createAsyncThunk<object, PayloadType>(
  'add/GoogleAccount',
  async (payload, { dispatch }) => {
    const { errorCallback, successCallback } = payload;

    try {
      const response = await addGoogleAccountApi();
      if (response.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
