import { createAsyncThunk } from '@reduxjs/toolkit';
import { addContactsStatusApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  color: string;
  title: string;
  description?: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const addStatusAction = createAsyncThunk<object, PayloadType>(
  'add/contactStatus',
  async (payload, { dispatch }) => {
    const { wsId, color, title, description, errorCallback, successCallback } = payload;

    try {
      const response = await addContactsStatusApi({ wsId, color, title, description });
      if (response.status === 200) {
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback();
    }
  },
);
