import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { ChooseIcon, DeleteBasket, PlusIcon } from 'shared/lib/Icons';
import {
  AddButton,
  Button,
  ColorsWrapper,
  DividerCustom,
  Input,
  InputBlock,
  Pin,
  Text,
  Title,
  Wrapper,
} from './styled';

const colorsList = [
  { id: 1, color: '#CFE9DA', title: 'Зеленый', isActive: true },
  { id: 2, color: '#D9CFE9', title: 'Фиолетовый', isActive: false },
  { id: 3, color: '#FCC9C9', title: 'Красный', isActive: false },
  { id: 4, color: '#F9F3BC', title: 'Желтый', isActive: false },
  { id: 5, color: '#E7E7E7', title: 'Серый', isActive: false },
  { id: 6, color: '#FECAE3', title: 'Розовый', isActive: false },
];

type Props = {
  id?: string;
  defaultValue?: string;
  defaultColor?: string;
  deleteBtn?: () => void;
  addBtn?: (e: { color: string; title: string }) => void;
  onBlur?: (e: { id: string; color?: string; title?: string }) => void;
};

export const StatusBlock: React.FC<Props> = ({ id, defaultValue, defaultColor, deleteBtn, addBtn, onBlur }) => {
  const [list, setList] = useState(colorsList);
  const [value, setValue] = useState(defaultValue);
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    setList(prev =>
      prev.map(el => ({
        ...el,
        isActive: el.color === defaultColor,
      })),
    );
  }, [defaultColor]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleClick = useCallback(
    (color: string) => {
      setColor(color);
      id && onBlur && onBlur({ id, color });
    },
    [id, onBlur],
  );

  const handleBlur = useCallback(() => {
    id && onBlur && onBlur({ id, title: value });
  }, [id, value, onBlur]);

  return (
    <Wrapper>
      <InputBlock>
        <Input value={value} onChange={handleChange} onBlur={handleBlur} placeholder='Введите статус' />
      </InputBlock>
      <DividerCustom margin='15px 0 0' />
      <ColorsWrapper>
        <Title>Цвет</Title>
        {list.map(el => (
          <Text key={el.id} onClick={() => handleClick(el.color)}>
            <div>
              <Pin color={el.color}>А</Pin>
              {el.title}
            </div>
            {el.color === color && <ChooseIcon />}
          </Text>
        ))}
      </ColorsWrapper>
      <DividerCustom margin='0' />
      {deleteBtn && (
        <Button onClick={deleteBtn}>
          <DeleteBasket />
          Удалить
        </Button>
      )}
      {addBtn && (
        <AddButton onClick={() => addBtn({ color: color || '', title: value || '' })}>
          <PlusIcon />
          Добавить
        </AddButton>
      )}
    </Wrapper>
  );
};
