import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px 0;
  height: 600px;
  overflow-y: scroll;
`;

export const ActionBlock = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
`;

export const ListWrapper = styled.div`
  margin-top: 20px;
`;

export const Status = styled.div`
  width: 74px;
  height: 36px;
  padding: 10px 16px;
  border-radius: 6px;
  background: #f9f2bb;
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-classic-black);
`;
