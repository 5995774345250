import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IContact, IGroup, ModalType, selectGroup, selectWs } from 'processes/store';
import { useModal } from 'shared/hooks';
import { AddButton, GroupCard, GroupsList, GroupsWrapper, ShowMoreGroups } from '../styled';

type Props = {
  contact?: IContact;
};
export const GroupsBlock: React.FC<Props> = ({ contact }) => {
  const [initList, setInitList] = useState<IGroup[]>([]);

  const { showModal } = useModal();

  const { groupList } = useSelector(selectGroup);
  const { ws } = useSelector(selectWs);

  useEffect(() => {
    setInitList(groupList.filter(el => contact?._groupId.includes(el._id)));
  }, [contact, groupList]);

  const handleCLick = useCallback(() => {
    showModal(ModalType.AddContactInGroup, { wsId: ws._id, contact, groups: groupList });
  }, [showModal, ws._id, contact, groupList]);

  return (
    <GroupsWrapper>
      <GroupsList>
        {!!initList.length && initList?.slice(0, 2).map(el => <GroupCard key={el._id}>{el.title}</GroupCard>)}
        {initList.length > 2 && <ShowMoreGroups>+ {initList.length - 2}</ShowMoreGroups>}
        <AddButton onClick={handleCLick}>+</AddButton>
      </GroupsList>
    </GroupsWrapper>
  );
};
