import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNeedUpdateGroup } from 'processes/store';
import { deleteGroupsApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  successCallback?: () => void;
  errorCallback?: (err?: unknown) => void;
};

export const deleteGroupAction = createAsyncThunk<object, PayloadType>(
  'delete/group',
  async (payload, { dispatch }) => {
    const { wsId, id, successCallback, errorCallback } = payload;

    try {
      const response = await deleteGroupsApi({ wsId, id });
      if (response.status === 200) {
        dispatch(setNeedUpdateGroup(true));
        successCallback && successCallback();
      }
    } catch (err) {
      errorCallback && errorCallback(err);
    }
  },
);
