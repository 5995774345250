import styled from 'styled-components';
import { DefaultButton } from 'shared/ui';

export const Chip = styled.div<{ color?: string; selected?: boolean }>`
  min-width: max-content;
  display: flex;
  column-gap: 7px;
  padding: 7px 10px 7px 7px;
  border: ${({ color }) => (color ? 'none' : '1px solid var(--color-gray-dark)')};
  background: ${({ color }) => (color ? color : 'white')};
  border-radius: 6px;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  opacity: ${({ selected }) => (selected ? '50%' : '100%')};
  height: 30px;
`;

export const Text = styled.div`
  font-family: Geometria;
  font-size: 14px;
  font-weight: 400;
  line-height: 36px;
  padding: 6px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-dark);

  &:hover {
    background-color: var(--color-gray-btn-hv);
    &:first-child {
      border-radius: 6px 6px 0 0;
    }
    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }
`;

export const SettingsBtn = styled(DefaultButton)`
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 6px;

  &:hover {
    background-color: var(--color-gray-btn-hv);
  }
`;
