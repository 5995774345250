import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import bg from 'shared/img/Lazy 1.png';
import { Divider, Step } from 'shared/ui';
import { AvatarIcon, BackArrow } from 'shared/lib/Icons';
import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { createWsAction, useAppDispatch } from 'processes/store';
import { createSocialAccountAction } from './model/createSocialAccountAction';
import { RegistrationRoutes } from '../model';
import { Button } from '../styled';
import {
  Avatar,
  AvatarBlock,
  BlockInfo,
  DivBlock,
  CustomWhiteBlock,
  CustomBlueBlock,
  GoBack,
  Title,
  InputWrapper,
} from './styled';

type Inputs = {
  title: string;
  addresses: string;
  country: string;
};

type Props = {
  handleRedirect: (route: RegistrationRoutes) => void;
};

export const RegistrationSpace: FC<Props> = ({ handleRedirect }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<File>();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const hiddenFileInput = useRef(null);

  const email = decodeURIComponent(new URL(window.location.href).searchParams.get('email') || '');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      title: '',
      addresses: '',
      country: '',
    },
  });

  useEffect(() => {
    email &&
      dispatch(
        createSocialAccountAction({
          email,
          successCallback: () => setIsAuthorized(true),
          errorCallback: () => setIsAuthorized(false),
        }),
      );
  }, [dispatch, email]);

  const handleClick = () => {
    // @ts-ignore
    hiddenFileInput.current.click();
  };

  const handleFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    data => {
      setIsLoading(true);
      dispatch(
        createWsAction({
          ws: { title: data.title },
          successCallback: () => {
            setIsLoading(false);
            handleRedirect(RegistrationRoutes[RegistrationRoutes.synchronization]);
          },
          errorCallback: () => {
            return setIsLoading(false);
          },
        }),
      );
    },
    [dispatch, handleRedirect],
  );

  if (!isAuthorized && email) {
    return <></>;
  }

  return (
    <DivBlock>
      {location.hash !== '#space' && (
        <GoBack onClick={() => handleRedirect(RegistrationRoutes[RegistrationRoutes.meetUser])}>
          <BackArrow />
        </GoBack>
      )}
      <CustomWhiteBlock>
        <Step count={2} finishStep={5} />
        <Title>
          Создайте <br /> пространство
        </Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AvatarBlock>
            <Avatar onClick={handleClick}>
              <AvatarIcon />
              <input ref={hiddenFileInput} onChange={handleFile} type='file' style={{ display: 'none' }} />
            </Avatar>

            <BlockInfo>
              <div>Картинка пространства</div>
              <div>jpg или png, не меньше 400px на 400px</div>
            </BlockInfo>
          </AvatarBlock>
          <Divider />
          <InputWrapper>
            <AuthInput
              register={register}
              name='title'
              label='Название пространства'
              placeholder='Название'
              rules={{ required: true, maxLength: 250 }}
              isFocus={!!watch('title')}
              hasError={!!errors.title}
              textError={
                errors.title && (errors.title?.type === 'required' ? 'Введите название' : 'Превышен лимит символов')
              }
            />
          </InputWrapper>
          {/*<Input register={register} name='addresses' label='Адрес' placeholder='Название' />
          <Input register={register} name='country' label='Страна' placeholder='Выберите' />*/}
          <Button label='Продолжить' isLoading={isLoading} />
        </form>
      </CustomWhiteBlock>
      <CustomBlueBlock>
        <img src={bg} alt='' />
      </CustomBlueBlock>
    </DivBlock>
  );
};
