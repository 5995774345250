import { LineWithText } from 'shared/ui';
import styled from 'styled-components';
import { AuthButton } from 'pages/Auth/ui/AuthButton';

export const EmailWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  background: var(--color-auth-form);
  display: flex;
  width: 900px;
  border-radius: 20px;
  position: relative;
`;

export const DivBlock = styled.div`
  max-height: 600px;
  display: flex;
  width: 100%;
`;

export const ContinueButton = styled(AuthButton)`
  width: 100%;
  margin: 25px 0;
`;

export const TextStepOne = styled.div`
  margin-top: 35px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #adb8cd;
`;

export const TitleBlockWhite = styled.div`
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`;

export const TitleBlockBlue = styled.div`
  color: white;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
`;

export const Text = styled.div`
  margin-top: 25px;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
`;

export const CustomLineWithText = styled(LineWithText)`
  position: absolute;
  width: 50%;
`;

export const GoBack = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  left: 0;
  top: -72px;
  padding: 10px;
  cursor: pointer;

  background: #ffffff;
  border-radius: 6px;
`;

export const Title = styled.div`
  margin-top: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;
