import { IEmail } from 'processes/store';
import { IError, apiInstance } from '../base';
import { AxiosPromise } from 'axios';

type Request = {
  wsId: string;
  id: string;
  subject?: string;
  content?: string;
};

type Response = {
  result: IEmail;
  errors: IError[];
};
export const changeMessageApi = ({ wsId, id, subject, content }: Request): AxiosPromise<Response> => {
  return apiInstance.patch(`ws/${wsId}/emails/${id}`, {
    subject,
    content,
  });
};
