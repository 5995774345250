import styled from 'styled-components';
import { flexRow, alignMiddle } from 'shared/lib/mixins';

export const Containter = styled.div`
  width: 360px;
  border-radius: 16px;
`;

export const Header = styled.div`
  height: 220px;
  ${flexRow};
  ${alignMiddle};
  border-radius: 16px 16px 0 0;
  background: linear-gradient(135deg, #002fa4 0%, #295dff 45.83%, #babff8 100%);
`;

export const Content = styled.div`
  padding: 35px;
`;
