import { createAsyncThunk } from '@reduxjs/toolkit';
import { setGroup } from 'processes/store';
import { getGroupApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  id: string;
  successCallback?: () => void;
  errorCallback?: (err: unknown) => void;
};

export const getGroupAction = createAsyncThunk<object, PayloadType>('get/group', async (payload, { dispatch }) => {
  const { wsId, id, successCallback, errorCallback } = payload;
  try {
    const response = await getGroupApi({ wsId, id });
    if (response.status === 200) {
      dispatch(setGroup({ group: response.data.result }));
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback(err);
  }
});
