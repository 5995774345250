import { API_URL } from 'shared/api';

type generateAvatarLinkType = {
  src: string;
  wsId: string;
  id: string;
  size: string;
};

export const generateAvatarLink = ({ src, wsId, id, size }: generateAvatarLinkType) => {
  return `${API_URL}avatar/ws/${wsId}/contacts/${id}/${size}?imageSrc=${src}`;
};
