import { DefaultButton } from 'shared/ui';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  padding: 23px 25px;
  border-bottom: 1px solid var(--color-gray-dark);
  font-weight: 400;
  color: var(--color-mybase-gray-text);
`;

export const Label = styled.label`
  padding-right: 15px;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  div:first-child {
    display: flex;
    column-gap: 10px;
  }
`;

export const AddInputBtn = styled(DefaultButton)`
  cursor: pointer;
  max-height: 16px;
`;

export const GroupsList = styled.div`
  width: 100%;
`;

export const Group = styled.div`
  display: flex;
  column-gap: 8px;
  padding: 9px 10px;
  height: 36px;
  position: relative;
  border-radius: 4px;
  color: var(--color-black);

  &:hover {
    background: var(--color-gray-light);
    input {
      background: var(--color-gray-light);
    }
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  column-gap: 8px;
  position: absolute;
  right: 15px;
`;

export const ActionButton = styled(DefaultButton)`
  cursor: pointer;
`;
