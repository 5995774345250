import { IContact, IGroup, useAppDispatch } from 'processes/store';
import React, { useCallback, useEffect, useState } from 'react';
import { CloseIcon } from 'shared/lib/Icons';
import { addContactInGroupAction } from './model/addContactInGroupAction';
import {
  CancelButton,
  Chip,
  ChipBlock,
  CloseButton,
  ContentBlock,
  DivBlock,
  Footer,
  Group,
  Header,
  ModalWrapper,
  SaveButton,
  TitleBlock,
} from './styled';

type Props = {
  onCloseModal: () => void;
  params?: {
    groups?: IGroup[];
    wsId?: string;
    contact?: IContact;
    successCallback?: () => void;
  };
};

export const AddContactInGroupModal: React.FC<Props> = ({ onCloseModal, params }) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    params?.contact?._groupId.length && setSelectedIds([...params?.contact?._groupId]);
  }, [params]);

  const handleClick = (id: string) => {
    setSelectedIds(prev => (!prev.includes(id) ? [...prev, id] : prev));
  };

  const handleDeleteSelected = (id: string) => {
    setSelectedIds(prev => prev.filter(el => !el.includes(id)));
  };

  const save = useCallback(() => {
    params?.contact?._id &&
      dispatch(
        addContactInGroupAction({
          wsId: params.wsId || '',
          id: params?.contact?._id,
          groupId: selectedIds,
          successCallback: params.successCallback,
        }),
      );
    onCloseModal();
  }, [params, dispatch, selectedIds, onCloseModal]);

  return (
    <ModalWrapper>
      <DivBlock>
        <Header>
          <TitleBlock>Добавить в группу</TitleBlock>
          <CloseButton onClick={onCloseModal}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <ChipBlock>
          {params?.groups &&
            params.groups
              .filter(item => selectedIds.includes(item._id))
              .map(el => (
                <Chip key={el._id} onClick={() => handleDeleteSelected(el._id)}>
                  {el.title}
                </Chip>
              ))}
        </ChipBlock>
        <ContentBlock>
          {params?.groups &&
            params.groups
              .filter(({ _id }) => !selectedIds.includes(_id))
              .map(el => (
                <Group key={el._id} onClick={() => handleClick(el._id)}>
                  {el.title}
                </Group>
              ))}
        </ContentBlock>
      </DivBlock>
      <Footer>
        <CancelButton onClick={onCloseModal} label='Отмена' />
        <SaveButton onClick={save} label='Добавить' />
      </Footer>
    </ModalWrapper>
  );
};
