import styled from 'styled-components';
import { DefaultButton } from 'shared/ui';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  input {
    width: 120px;
    text-overflow: ellipsis;
  }
`;

export const ShowButton = styled(DefaultButton)`
  position: absolute;
  cursor: pointer;
  right: 0;
`;
