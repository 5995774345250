import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITouches, IWs, setNeedUpdateTouch, setTouchesList } from 'processes/store';
import { getTouchesApi } from 'shared/api';

type PayloadType = {
  wsId: IWs['_id'];
  limit?: number;
  sort?: 'asc' | 'desc' | null;
  order_by?: string | null;
  search?: string;
  page: number;
  _groupId?: string;
  successCallback?: (list?: ITouches[], total?: number) => void;
  errorCallback?: () => void;
  filters?: any;
};

export const getTouchesAction = createAsyncThunk<object, PayloadType>('get/touches', async (payload, { dispatch }) => {
  const { wsId, page, limit, search, sort, order_by, _groupId, filters, errorCallback } = payload;
  try {
    const response = await getTouchesApi({ wsId, page, limit, search, sort, order_by, _groupId, filters });

    if (response.status === 200) {
      const { list, total } = response.data.result;
      dispatch(setTouchesList({ touchesList: list, total }));
      dispatch(setNeedUpdateTouch(false));
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
