import { createAsyncThunk } from '@reduxjs/toolkit';
import { logoutUser, setUser } from 'processes/store';
import { getUserInfoApi } from 'shared/api';

type PayloadType = {
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const authMainAction = createAsyncThunk<object, PayloadType>('user/mainauth', async (payload, { dispatch }) => {
  try {
    const userResponse = await getUserInfoApi();
    if (userResponse.status === 200) {
      if (
        userResponse.data.result.emailConfirmedStatus === 'not_confirmed' &&
        userResponse.data.result.lastEmail !== ''
      ) {
        dispatch(
          setUser({
            user: { ...userResponse.data.result, finishRegistration: { email: userResponse.data.result.lastEmail } },
          }),
        );
        window.location.href = '/registration';
      } else if (
        userResponse.data.result.emailConfirmedStatus === 'not_confirmed' &&
        userResponse.data.result.lastEmail === ''
      ) {
        if (!userResponse.data.result.socialAuth?.google?.accessToken) {
          window.open('/logout');
        }
      } else {
        dispatch(
          setUser({
            user: userResponse.data.result,
          }),
        );
        payload.successCallback && payload.successCallback();
      }
    }
  } catch (err) {
    payload.errorCallback && payload.errorCallback();
    dispatch(logoutUser());
  }
});
