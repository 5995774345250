import React, { useState } from 'react';
import { SortAscendIcon, SortDescendIcon, ThreePointsIcon } from 'shared/lib/Icons';
import { Dropdown, IconButton } from 'shared/ui';
import { AccessText, DropdownList, Text } from '../styled';

type Props = {
  sort?: 'asc' | 'desc' | null;
  order_by: string;
  onClick: (sort?: 'asc' | 'desc', order_by?: string) => void;
};
export const SortBlock: React.FC<Props> = ({ sort, order_by, onClick }) => {
  const [open, setOpen] = useState(false);
  return (
    <Dropdown
      offset={10}
      placement='bottom-end'
      delay={300}
      onChange={setOpen}
      label={
        <AccessText>
          <IconButton active={open} icon={<ThreePointsIcon />} />
        </AccessText>
      }
    >
      <DropdownList>
        <Text active={!sort} border color='#6A7B9B' onClick={() => onClick()}>
          По дате создания
        </Text>
        <Text active={sort === 'asc'} border color='#6A7B9B' onClick={() => onClick('asc', order_by)}>
          <SortAscendIcon />
          От А до Я
        </Text>
        <Text active={sort === 'desc'} color='#6A7B9B' onClick={() => onClick('desc', order_by)}>
          <SortDescendIcon />
          От Я до А
        </Text>
      </DropdownList>
    </Dropdown>
  );
};
