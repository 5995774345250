import { InitialModalState } from 'processes/store';
import { DeleteModal } from 'shared/lib/Icons';
import { ModalCommon } from 'shared/ui';
import { Title, Element, Desc, Actions, DeleteButton, CancelButton } from './styled';
import { useCallback } from 'react';

type Props = {
  onCloseModal: () => void;
  isShowModal: boolean;
  params?: InitialModalState['params'];
};

export const DeleteElementModal: React.FC<Props> = ({ onCloseModal, params }) => {
  const handleClick = useCallback(() => {
    params?.onClick && params.onClick();
    onCloseModal();
  }, [onCloseModal, params]);

  return (
    <ModalCommon header={<DeleteModal />}>
      <Title>
        Удалить
        <Element>{`${params?.title || ''}`}?</Element>
      </Title>
      <Desc>
        Это будет навсегда – <br /> обратного пути нет!
      </Desc>
      <Actions>
        <CancelButton onClick={onCloseModal} label='Отмена' $isEmpty />
        <DeleteButton onClick={handleClick} label='Удалить' />
      </Actions>
    </ModalCommon>
  );
};
