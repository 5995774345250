import { RootState } from 'processes/store';

export const selectUser = (state: RootState) => state.user;
export const selectProfile = (state: RootState) => state.profile;
export const selectView = (state: RootState) => state.view;
export const selectModal = (state: RootState) => state.modal;
export const selectWs = (state: RootState) => state.ws;
export const selectContact = (state: RootState) => state.contact;
export const selectGroup = (state: RootState) => state.group;
export const selectTouch = (state: RootState) => state.touch;
