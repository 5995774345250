import React, { useCallback, useState } from 'react';
import { CloseIcon } from 'shared/lib/Icons';
import { ButtonsWrapper, CloseButton, ContentBlock, ModalWrapper, Title, TitleBlock, Wrapper } from './styled';
import { InitialModalState, selectWs, useAppDispatch } from 'processes/store';
import { Button } from 'shared/ui';
import { Crop } from './ui/Crop';
import { avatarAction } from './model/avatarAction';
import { useSelector } from 'react-redux';

type Props = {
  onCloseModal: () => void;
  params?: InitialModalState['params'];
};

export const AvatarModal: React.FC<Props> = ({ onCloseModal, params }) => {
  const [cropParams, setCropParams] = useState<{ height: number; left: number; top: number; width: number }>();

  const { ws } = useSelector(selectWs);

  const dispatch = useAppDispatch();

  const handleCropper = useCallback((values: any) => {
    setCropParams(values);
  }, []);

  // const handleClickDefault = useCallback(() => {
  // if (fileList.length > 0 && !params?.defaultAvatar) {
  // setDefaultSrcState('');
  // setSrc('');
  // return setFileList([]);
  // }

  // dispatch(
  //   avatarDeleteAction({
  //     successCallback: () => {
  //       setSrc('');
  //     },
  //   }),
  // );
  // }, [fileList, params?.defaultAvatar]);

  const onFinish = useCallback(() => {
    ws._id &&
      params?.id &&
      params.file &&
      dispatch(
        avatarAction({
          values: {
            file: params?.file,
            x: cropParams?.left || 0,
            y: cropParams?.top || 0,
            width: cropParams?.width || 0,
            height: cropParams?.height || 0,
            preview_height: 240,
            preview_width: 240,
            id: params?.id,
            wsId: ws._id,
          },
        }),
      );
  }, [ws._id, params, cropParams, dispatch]);

  return (
    <ModalWrapper>
      <TitleBlock>
        <div>Выбрать фото</div>
        <CloseButton onClick={onCloseModal}>
          <CloseIcon />
        </CloseButton>
      </TitleBlock>
      <ContentBlock>
        <Wrapper>
          <Title>Чтобы кадрировать изображение, выберите нужную область и нажмите на кнопку “Сохранить”</Title>
          <Crop src={params?.defaultAvatar || ''} onCropper={handleCropper} />
          <ButtonsWrapper>
            <Button label='Удалить фото' />
            <Button label='Cохранить' onClick={onFinish} />
          </ButtonsWrapper>
        </Wrapper>
      </ContentBlock>
    </ModalWrapper>
  );
};
