import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserInfoApi } from 'shared/api';
import { IUser } from 'processes/store';
import { authMainAction } from 'processes/Authorized/model';
import { RequestState } from 'shared/lib/types';

const initialState = {
  isLogged: false,
  user: {} as IUser,
  status: RequestState.PENDING,
};

export const getUserInfo = createAsyncThunk<object>('user/me', async (payload, { dispatch }) => {
  try {
    const userResponse = await getUserInfoApi();

    if (userResponse.status === 200) {
      dispatch(
        setUser({
          user: userResponse.data.result,
        }),
      );
      dispatch(setLoggedUser({ isLogged: true }));
    }
  } catch (err) {
    console.log('Rejected', err);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: Partial<IUser> }>) => {
      state.user = { ...state.user, ...action.payload.user };
    },
    setLoggedUser: (state, action: PayloadAction<{ isLogged: boolean }>) => {
      state.isLogged = action.payload.isLogged;
    },
    logoutUser: state => {
      state.isLogged = false;
      state.user = {} as IUser;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authMainAction.pending, state => {
        state.status = RequestState.LOADING;
      })
      .addCase(authMainAction.fulfilled, (state, action) => {
        state.isLogged = true;
        state.status = RequestState.SUCCESS;
      })
      .addCase(authMainAction.rejected, state => {
        state.isLogged = false;
        state.status = RequestState.ERROR;
      });
  },
});

export const { setUser, logoutUser, setLoggedUser } = userSlice.actions;

export default userSlice.reducer;
