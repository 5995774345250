import { AxiosPromise } from 'axios';
import { IContact } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  id: string;
};

type Response = {
  result: IContact;
  errors: IError[];
};

export const getContactApi = ({ wsId, id }: Request): AxiosPromise<Response> => {
  return apiInstance.get(`ws/${wsId}/contacts/${id}`);
};
