import { Select } from 'shared/ui';
import styled from 'styled-components';

export const FieldsWrapper = styled.div`
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: #0000ff;
  }
`;

export const FieldWrapper = styled.div`
  padding: 23px 25px;
  border-bottom: 1px solid var(--color-gray-dark);
  font-weight: 400;
  color: var(--color-mybase-gray-text);
`;

export const Label = styled.label`
  padding-right: 15px;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  div:first-child {
    display: flex;
    column-gap: 10px;
  }
`;

export const InputBlock = styled.div`
  display: flex;
  column-gap: 8px;
  padding: 9px 10px;
  height: 36px;
  position: relative;
  border-radius: 4px;

  input {
    height: 17px;
    width: 100%;
  }

  input:focus {
    border-bottom: 2px solid var(--color-gray-dark);
  }

  &:hover {
    background: var(--color-gray-light);
    input {
      background: var(--color-gray-light);
    }
  }
`;

export const SelectWrapper = styled(InputBlock)`
  padding: 0;
  &:hover {
    background: none;
  }
  div {
    border: none;
  }
`;

export const SelectGender = styled(Select)`
  border: none;
  &:hover {
    padding: 0;
  }
`;
