import { createAsyncThunk } from '@reduxjs/toolkit';
import { setNeedUpdateGroup } from 'processes/store';
import { createGroupApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  title: string;
  collectionName: string;
  successCallback?: (groupId?: string) => void;
  errorCallback?: (err: unknown) => void;
};

export const createGroupAction = createAsyncThunk<object, PayloadType>('add/group', async (payload, { dispatch }) => {
  const { wsId, title, collectionName, successCallback, errorCallback } = payload;
  try {
    const response = await createGroupApi({ wsId, title, collectionName });
    if (response.status === 200) {
      dispatch(setNeedUpdateGroup(true));
      successCallback && successCallback(response.data.result._id);
    }
  } catch (err) {
    errorCallback && errorCallback(err);
  }
});
