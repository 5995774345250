import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

export const PaginationStyled = styled(ReactPaginate)`
  display: flex;
  column-gap: 10px;
  li {
    width: 36px;
    height: 36px;
    border: 1px solid #d9dfeb;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  li:hover {
    background: #efefef;
  }
  li {
    a {
      width: 100%;
      height: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }

  .break {
    border: none;
  }
  .selected {
    background: var(--color-bg-auth-button);
    color: white;
  }
`;
