import styled from 'styled-components';
import { Button } from 'shared/ui';
import { flexRow, flexCol, spaceBetween } from 'shared/lib/mixins';

export const Title = styled.div`
  margin-bottom: 15px;
  text-align: center;
  ${flexCol};
  font-size: 19px;
  line-height: 26px;
  font-family: var(--font-medium);
`;

export const Element = styled.span``;

export const Desc = styled.div`
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--color-gray-label);
`;

export const Actions = styled.div`
  ${flexRow};
  ${spaceBetween};
`;

export const CancelButton = styled(Button)`
  width: 135px;
`;

export const DeleteButton = styled(Button)`
  width: 135px;
`;
