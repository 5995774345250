import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeWs, getWsListAction, selectUser, selectWs, setUser, useAppDispatch } from 'processes/store';
import { ROUTE_WS } from 'shared/lib/routes';
import { RequestState } from 'shared/lib/types';

export const useWs = () => {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { isLogged } = useSelector(selectUser);
  const { ws, wsList, status } = useSelector(selectWs);

  const wId = location.pathname.split('/')[2];

  const handlerOnline = useCallback(() => setIsNeedUpdate(true), []);

  useEffect(() => {
    window.addEventListener('online', handlerOnline);

    return () => {
      window.removeEventListener('online', handlerOnline);
    };
  });

  useEffect(() => {
    if (wsList.length === 0 && status === RequestState.SUCCESS) {
      dispatch(setUser({ user: { needWs: true } }));
    }
  }, [isLogged, wsList, status, dispatch, navigate]);

  useEffect(() => {
    isLogged &&
      !ws._id &&
      dispatch(getWsListAction({ wId, successCallback: (id?: string) => navigate(`${ROUTE_WS}/${id}`) }));

    if (isLogged && ws._id !== wId) {
      dispatch(changeWs({ wId }));
    }
  }, [dispatch, navigate, isLogged, isNeedUpdate, wId, ws]);
};
