import { alignCenter, flexCol, flexFull, flexRow } from 'shared/lib/mixins';
import { Button, DefaultButton, Divider, IconButton } from 'shared/ui';
import styled from 'styled-components';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
  position: relative;
  width: calc(100% - 300px);
`;

export const Header = styled.header`
  padding: 0 35px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
  justify-content: space-between;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: var(--color-black);
`;

export const BackButton = styled(DefaultButton)`
  cursor: pointer;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const ShareButton = styled(DefaultButton)`
  padding: 10px;
  border: 1px solid rgb(217, 223, 235);
  border-radius: 6px;
  width: max-content;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 10px;
  &:hover {
    background-color: var(--color-gray-btn-hv);
    svg {
      fill: var(--color-gray-icon-btn-hv);
    }
  }
`;

export const SettingsButton = styled(ShareButton)`
  border: none;
`;

export const ActionButtons = styled.div`
  max-height: 90px;
  padding: 25px 35px;
  display: flex;
  justify-content: space-between;
`;

export const Text = styled.div<{ color?: string; border?: boolean; active?: boolean; disabled?: boolean }>`
  display: flex;
  column-gap: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 36px;
  line-height: 16px;
  padding: 0 20px;
  color: ${({ color }) => (color ? color : 'var(--color-black)')};
  border-bottom: ${({ border }) => (!border ? 'none' : '1px solid var(--color-gray-dark)')};
  align-items: center;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 50%;
      cursor: not-allowed;
  `}

  ${({ active }) =>
    active &&
    `
    background-color: var(--color-gray-btn-hv);
    svg {
      fill: var(--color-gray-icon-btn-hv);
    }
    `}

  ${({ border, disabled }) =>
    border &&
    !disabled &&
    `
    &:hover {
      background-color: var(--color-gray-btn-hv);
      svg {
        fill: var(--color-gray-icon-btn-hv);
      }
    }
  
  `}

  div:first-child {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  div:last-child {
    color: var(--color-mybase-gray-text);
  }
`;

export const DeleteText = styled(Text)`
  column-gap: inherit;
`;

export const DropdownList = styled.div`
  background: var(--color-white);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
`;

export const DividerCustom = styled(Divider)`
  border: none;
  background-color: var(--color-gray-dark);
  height: 3px;
`;

export const AccessText = styled.div`
  max-height: 36px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 10px 15px 10px 12px;
  gap: 10px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;

  button {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: var(--color-gray-btn-hv);
    svg {
      fill: var(--color-gray-icon-btn-hv);
    }
  }

  span {
    color: var(--color-mybase-gray-text);
  }
`;

export const AddButton = styled(DefaultButton)`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 10px 15px 10px 22px;
  color: var(--color-blue);

  &:hover {
    background-color: var(--color-gray-btn-hv);
    svg {
      fill: var(--color-gray-icon-btn-hv);
    }
  }

  svg {
    path {
      fill: var(--color-blue);
    }
  }
`;

export const LeftButtons = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const RightButtons = styled(LeftButtons)``;

export const MessageButton = styled(DefaultButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px 10px 12px;
  gap: 10px;
  border: 1px solid var(--color-gray-dark);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: var(--color-gray-btn-hv);
    svg {
      fill: var(--color-gray-icon-btn-hv);
    }
  }
`;

export const CallButton = styled(MessageButton)``;

export const FooterBlock = styled.footer<{ justify?: string }>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'space-between')};
  width: 100%;
  bottom: 0;
  padding: 12px 20px 12px 33px;
  max-height: 60px;
  border: 1px solid rgb(217, 223, 235);
  background: white;
`;

export const AddContactButton = styled(Button)`
  background: none;
  padding: 0;
  div {
    display: flex;
    column-gap: 10px;
    color: black;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const EmptyData = styled.div`
  color: var(--color-mybase-gray-text);
  margin: auto;
  font-size: 20px;
  line-height: 24px;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const DeleteButton = styled(IconButton)`
  max-width: max-content;
  max-height: max-content;
  margin-right: 10px;
  svg {
    fill: #ff0000;
  }
`;
