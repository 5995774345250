import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IView } from 'processes/store';

const initialState: IView = {
  isPhoneView: false,
  isLaptopView: false,
  isTabletView: false,
  isDesktopView: false,
};

const viewSlice = createSlice({
  name: 'view',
  initialState,
  reducers: {
    updateView: (state, action: PayloadAction<IView>) => {
      state.isLaptopView = action.payload.isLaptopView;
      state.isPhoneView = action.payload.isPhoneView;
      state.isTabletView = action.payload.isTabletView;
      state.isDesktopView = action.payload.isDesktopView;
    },
  },
});

export const { updateView } = viewSlice.actions;

export default viewSlice.reducer;
