import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';
import { IUser } from 'processes/store';

type Request = {
  password: string;
  login: string;
  lang?: string;
  timeZone?: string;
  opId?: string;
};

type Response = {
  result: IUser;
  errors: IError[];
};

export const loginUserApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.post('user/login', {
    ...params,
  });
};
