import React, { PropsWithChildren } from 'react';
import { Spinner } from 'shared/ui';
import { ButtonStyled, Text } from './styled';

type Props = {
  label: string | JSX.Element;
  isLoading?: boolean;
  $isEmpty?: boolean;
  isWarning?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  type?: 'submit' | 'button' | 'reset';
};

export const Button: React.FC<PropsWithChildren<Props>> = ({
  label,
  isLoading,
  $isEmpty,
  isWarning,
  className,
  disabled,
  onClick,
  type,
  ...rest
}) => (
  <ButtonStyled
    className={className}
    onClick={isLoading ? () => {} : onClick}
    isWarning={isWarning}
    $isEmpty={$isEmpty}
    type={type}
    disabled={disabled}
    {...rest}
  >
    {isLoading ? <Spinner /> : <Text $isEmpty={$isEmpty}>{label}</Text>}
  </ButtonStyled>
);
