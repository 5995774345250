import styled from 'styled-components';
import { Button, DefaultButton } from 'shared/ui';

export const AccessText = styled.div<{ active?: boolean }>`
  max-height: 36px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 10px 15px 10px 12px;
  gap: 10px;
  border-radius: 6px;

  button {
    height: 16px;
    width: 16px;
    transform: none;
  }

  ${({ active }) =>
    active &&
    `
    background: var(--color-bg-auth-button);
    svg {
      path {
        fill: var(--color-white);
      }
    }
    `}

  &:hover {
    background-color: ${({ active }) => !active && 'var(--color-gray-btn-hv)'};
  }
`;

export const DropdownList = styled.div`
  background: var(--color-white);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  padding: 20px 0;
  /* width: 570px; */
`;

export const AddButton = styled(DefaultButton)`
  width: max-content;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  margin: 20px 0 20px 20px;
  color: var(--color-blue);

  &:disabled {
    opacity: 50%;
    background-color: none;
    cursor: default;
  }

  &:hover {
    background-color: var(--color-gray-btn-hv);
    svg {
      fill: var(--color-gray-icon-btn-hv);
    }
  }

  svg {
    path {
      fill: var(--color-blue);
    }
  }
`;

export const Text = styled.div<{ color?: string; border?: boolean }>`
  display: flex;
  column-gap: 40px;
  font-style: normal;
  padding: 0 20px;
  font-weight: 400;
  font-size: 14px;
  height: 36px;
  line-height: 16px;
  color: ${({ color }) => (color ? color : 'var(--color-black)')};
  border-bottom: ${({ border }) => (!border ? 'none' : '1px solid var(--color-gray-dark)')};
  align-items: center;
  cursor: pointer;

  ${({ border }) =>
    border &&
    `
    &:hover {
      background-color: var(--color-gray-btn-hv);
      svg {
        fill: var(--color-gray-icon-btn-hv);
      }
    }
  
  `}

  div:first-child {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  div:last-child {
    color: var(--color-mybase-gray-text);
  }
`;

export const SaveButton = styled(Button)`
  margin: 0 20px;
`;
