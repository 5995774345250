import styled from 'styled-components';

export const SwitcherBlock = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: var(--color-gray-label);
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
`;

export const SwitcherContainer = styled.div<{ $on?: boolean }>`
  width: 22px;
  height: 16px;
  border-radius: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  background: ${({ $on }) => ($on ? 'var(--color-blue)' : 'var(--color-gray-dark)')};
`;

export const SwitcherKnob = styled.div<{ $on?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  background-color: white;
  transform: ${({ $on }) => ($on ? 'translateX(8px)' : 'translateX(2px)')};
`;
