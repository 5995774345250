import { AxiosPromise } from 'axios';
import { IContact } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  id: string;
};

type Response = {
  result: string[];
  errors: IError[];
};

export const deleteContactApi = (params: Request): AxiosPromise<Response> => {
  return apiInstance.delete(`ws/${params.wsId}/contacts/${params.id}`);
};
