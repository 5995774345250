import { AuthInput } from 'pages/Auth/ui/AuthInput';
import { BlueBlock, WhiteBlock } from '../styled';
import styled from 'styled-components';

export const DivBlock = styled.div`
  max-height: 600px;
  width: 100%;
  display: flex;
  position: relative;
`;

export const CustomWhiteBlock = styled(WhiteBlock)`
  padding: 40px 65px 50px;
`;

export const FirstNameInput = styled(AuthInput)`
  padding: 0 0 16px;
  margin-bottom: 25px;
`;

export const LastNameInput = styled(FirstNameInput)`
  margin-bottom: 35px;
`;

export const CustomBlueBlock = styled(BlueBlock)`
  padding: 60px;
  position: relative;
  overflow: hidden;
`;

export const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const Avatar = styled.div`
  width: 58px;
  height: 58px;
  margin-right: 20px;
  padding: 19px;
  background: #ffffff;
  border: 1px solid #d9dfeb;
  border-radius: 29px;
`;

export const BlockInfo = styled.div`
  width: 143px;
  div:first-child {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #0b0b0a;
    margin-bottom: 9px;
  }
  div:last-child {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #6a7b9b;
  }
`;

export const UserInfoBlock = styled.div`
  position: absolute;
  width: 203px;
  height: 190px;
  right: 30px;
  bottom: 30px;
  background: linear-gradient(135deg, #002fa4 0%, #295dff 45.83%, #babff8 100%);
  border-radius: 10px;
  padding: 40px;
`;

export const AvatarEmpty = styled.div`
  width: 58px;
  height: 58px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 56px;
  margin: 0 auto 15px;
`;

export const NameDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
  color: #ffffff;
  margin-bottom: 5px;
  text-align: center;
`;

export const Title = styled.div`
  margin-top: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
`;
