import React from 'react';
import { Authorized } from 'processes/Authorized';
import { MainTemplate } from 'shared/ui';
import { Containter, Header, Title } from './styled';

export const MainPage: React.FC = () => {
  return (
    <Authorized>
      <MainTemplate>
        <Containter>
          <Header>
            <Title>Дашбоард</Title>
          </Header>
        </Containter>
      </MainTemplate>
    </Authorized>
  );
};
