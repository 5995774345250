import { createAsyncThunk } from '@reduxjs/toolkit';
import { setContact, setStatusContact, setNeedUpdateContact } from 'processes/store';
import { getContactApi } from 'shared/api/contacts';
import { RequestState } from 'shared/lib/types';

type PayloadType = {
  wsId: string;
  id: string;
  successCallback?: () => void;
  errorCallback?: () => void;
};

export const getContactAction = createAsyncThunk<object, PayloadType>('get/contact', async (payload, { dispatch }) => {
  const { wsId, id, errorCallback, successCallback } = payload;
  try {
    dispatch(setStatusContact(RequestState.LOADING));
    const response = await getContactApi({ wsId, id });
    if (response.status === 200) {
      dispatch(setContact({ contact: response.data.result }));
      dispatch(setNeedUpdateContact(false));
      successCallback && successCallback();
    }
  } catch (err) {
    errorCallback && errorCallback();
  }
});
