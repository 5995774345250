import { FC, useState } from 'react';
import { Dropdown, IconButton } from 'shared/ui';
import { DeleteBasket, DropArrow } from 'shared/lib/Icons';
import {
  AccessAction,
  AccessText,
  DeleteButton,
  DividerCustom,
  DropdownList,
  Text,
  UserAvatarBlock,
  UserItem,
  UserItemActions,
  UserItemData,
  UserItemTitle,
} from '../../styled';

type Props = {
  avatar: string;
  fullName: string;
  action: string;
  deleteAction?: () => void;
};

export const User: FC<Props> = ({ avatar, fullName, action, deleteAction }) => {
  const [access, setAccess] = useState('Редактор');
  const [open, setOpen] = useState(false);

  const handleClick = (e: number) => {
    setAccess(e == 1 ? 'Редактор' : e == 2 ? 'Полный доступ' : 'Заблокирован');
  };
  return (
    <UserItem>
      <UserItemData>
        <UserAvatarBlock>
          <img src={avatar} alt='' />
        </UserAvatarBlock>
        <UserItemTitle>{fullName}</UserItemTitle>
      </UserItemData>
      <UserItemActions>
        <AccessAction>
          <Dropdown
            offset={10}
            placement='bottom-end'
            delay={300}
            onChange={setOpen}
            label={
              <AccessText>
                {access} <IconButton active={open} icon={<DropArrow />} />
              </AccessText>
            }
          >
            <DropdownList>
              <Text color='#6A7B9B'>Доступ</Text>
              <Text onClick={() => handleClick(1)} border>
                Редактор
              </Text>
              <Text onClick={() => handleClick(2)}>Полный доступ</Text>
              <DividerCustom margin='0' />
              <Text onClick={() => handleClick(3)} color='#FF0000'>
                <DeleteButton icon={<DeleteBasket />} />
                Заблокировать
              </Text>
            </DropdownList>
          </Dropdown>
          <IconButton icon={<DeleteBasket />} onClick={deleteAction} />
        </AccessAction>
      </UserItemActions>
    </UserItem>
  );
};
