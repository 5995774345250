import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { InputLightWrapper, Error } from './styled';

type InputProps = {
  name: string;
  placeholder?: string;
  hasError?: boolean;
  textError?: string;
  rules?: RegisterOptions<any, string> | undefined;
  isFocus?: boolean;
  register: UseFormRegister<any>;
  className?: string;
  onBlur?: (e: any) => void;
};

export const InputLight: React.FC<InputProps> = ({
  name,
  hasError,
  placeholder,
  isFocus,
  textError,
  rules,
  className,
  register,
  onBlur,
}) => (
  <InputLightWrapper className={className}>
    <input
      placeholder={placeholder}
      type={'text'}
      {...register(name, { ...rules })}
      onBlur={e => onBlur && onBlur(e)}
    />
    {textError && <Error>{textError}</Error>}
  </InputLightWrapper>
);
