import React, { useEffect, useMemo, PropsWithChildren } from 'react';

type Props = {
  update: () => void;
  deps?: any[];
  delay?: number;
};

export const PopperUpdater: React.FC<PropsWithChildren<Props>> = ({ deps, update, children, delay = 0 }) => {
  const dependencies = useMemo(() => deps || [], [deps]);

  useEffect(() => {
    if (!delay) {
      update();
      return;
    }
    const timer = setTimeout(() => update(), delay);
    return () => clearTimeout(timer);
  }, [delay, dependencies, update]);

  return <>{children}</>;
};
