import styled from 'styled-components';

export const ModalWrapper = styled.div`
  max-width: 620px;
  padding: 40px;
`;

export const TitleBlock = styled.div`
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: #000000;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const CloseButton = styled.div`
  cursor: pointer;
`;

export const ContentBlock = styled.div`
  display: flex;
  column-gap: 25px;
`;

export const Wrapper = styled.div``;

export const Title = styled.div`
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
