import styled from 'styled-components';
import { alignCenter, flexCol, flexFull, flexRow } from 'shared/lib/mixins';

export const Containter = styled.section`
  ${flexCol};
  ${flexFull};
  position: relative;
`;

export const Header = styled.header`
  padding: 0 35px;
  ${flexRow};
  ${alignCenter};
  flex: 0 0 70px;
  border-bottom: 1px solid var(--color-gray-dark);
  justify-content: space-between;
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: var(--color-black);
`;
