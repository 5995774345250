import { AxiosPromise } from 'axios';
import { IContact } from 'processes/store';
import { apiInstance, IError } from 'shared/api';

type Request = {
  wsId: string;
  ids?: string[];
  groupId?: string;
};

type Response = {
  result: IContact;
  errors: IError[];
};

export const addContactsInGroupApi = ({ wsId, ids, groupId }: Request): AxiosPromise<Response> => {
  return apiInstance.patch(`ws/${wsId}/contacts`, { ids: ids, _groupId: groupId });
};
