import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, RegisterOptions, UseFormRegister, useFieldArray } from 'react-hook-form';
import { PlusIcon } from 'shared/lib/Icons';
import { AddInputBtn, DivWrapper, Input, Error } from '../styled';
import { MaskedInput } from 'shared/ui';
import { RemoveType } from '..';

type Props = {
  label: string;
  name: string;
  $isEmpty?: boolean;
  placeholder?: string;
  icon: JSX.Element;
  isAddBtn?: boolean | undefined;
  isNoArray?: boolean;
  register: UseFormRegister<any>;
  control?: Control<FieldValues> | undefined;
  delCode?: (e: any) => void;
  handleTelephone?: (e: string, index: number) => void;
  onBlur?: (e: any) => void;
  hasError?: boolean;
  textError?: string;
  rules?: RegisterOptions<any, string> | undefined;
  isFocus?: boolean;
  needRemove?: RemoveType;
  setNeedRemove?: (obj: RemoveType) => void;
};

export const Field: React.FC<Props> = ({
  label,
  name,
  $isEmpty = true,
  placeholder = '',
  icon,
  isAddBtn = true,
  isNoArray = false,
  register,
  control,
  delCode,
  handleTelephone,
  onBlur,
  hasError,
  textError,
  rules,
  isFocus,
  needRemove,
  setNeedRemove,
}) => {
  //эта фигня оборачивает строку в массив TODO изменить
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    if (!isNoArray && fields.length === 0) {
      append('');
    }
  }, [isNoArray, fields, append]);

  useEffect(() => {
    if (needRemove?.needRemove && needRemove.name === name && !isNoArray && fields.length > 1) {
      remove(needRemove.index);
      setNeedRemove && setNeedRemove({ name: name, index: 0, needRemove: false });
    }
  }, [fields.length, isNoArray, name, needRemove, remove, setNeedRemove]);

  const getInput = (field?: Record<'id', string>, index?: number) =>
    name === 'phones' ? (
      <>
        <MaskedInput
          key={field?.id}
          mask={'+{7}(000)000 00 00'}
          onBlur={delCode}
          onAccept={(e: string) => handleTelephone && handleTelephone(e, index ? index : 0)}
          lazy={false}
          // {...register(`${name}.${index}.value`, { ...rules, onBlur: delCode })}
          //@ts-ignore
          // value={field.value}
          // $isEmpty={$isEmpty}
        />
        {textError && <Error>{textError}</Error>}
      </>
    ) : (
      <Input
        key={field?.id}
        placeholder={placeholder}
        name={isNoArray ? name : `${name}.${index}.value`}
        onBlur={onBlur}
        register={register}
        hasError={hasError}
        textError={textError}
        rules={rules}
        isFocus={isFocus}
      />
    );

  return (
    <DivWrapper>
      <label onClick={e => e.preventDefault()}>
        <div>{icon}</div>
        <div>{label}</div>
        {isAddBtn && (
          <AddInputBtn type='button' onClick={() => append('')}>
            <PlusIcon />
          </AddInputBtn>
        )}
      </label>
      {!isNoArray ? (
        fields.map((field, index) => getInput(field, index))
      ) : (
        <Input placeholder={placeholder} name={name} rules={rules} register={register} />
      )}
    </DivWrapper>
  );
};
