import { DefaultButton, InputLight } from 'shared/ui';
import styled from 'styled-components';

export const FieldWrapper = styled.div`
  padding: 23px 25px;
  border-bottom: 1px solid var(--color-gray-dark);
  font-weight: 400;
  color: var(--color-mybase-gray-text);
`;

export const Label = styled.label`
  padding-right: 15px;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  div:first-child {
    display: flex;
    column-gap: 10px;
  }
`;

export const AddInputBtn = styled(DefaultButton)`
  cursor: pointer;
  max-height: 16px;
`;

export const DropdownList = styled.div`
  background: var(--color-white);
  box-shadow: 0px 0px 40px rgba(10, 30, 64, 0.1);
  border-radius: 10px;
  min-width: 200px;
`;

export const AccessText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
