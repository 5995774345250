import React, { ReactNode } from 'react';
import { PaginationStyled } from './styled';

type Props = {
  pageCount: number;
  onChange: (page: number) => void;
  previousLabel?: ReactNode;
  nextLabel?: ReactNode;
};

export const Pagination: React.FC<Props> = ({ pageCount, previousLabel, nextLabel, onChange }) => {
  return (
    <PaginationStyled
      previousLabel={previousLabel}
      nextLabel={nextLabel}
      pageCount={pageCount}
      onPageChange={e => onChange(e.selected + 1)}
      // containerClassName={styles.pagination}
      // previousLinkClassName={styles.paginationLink}
      // nextLinkClassName={styles.paginationLink}
      // disabledClassName={styles.paginationDisabled}
      // activeClassName={styles.paginationActive}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
    />
  );
};
