import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ITags } from 'processes/store';
import { SettingsDropdown } from 'shared/ui';
import { PlusIcon, BlackCloseIcon } from 'shared/lib/Icons';
import { StatusBlock } from '../StatusBlock';
import { AddButton, Chip, EmptyBlock, FieldBlock, Input, Label, ShowButton, StatusesBlock, Wrapper } from './styled';
import { StatusElement } from '../StatusElement';

type Props = {
  list?: ITags[];
  isBtn?: boolean;
  sufixIcon?: ReactNode;
  addBtnText?: string;
  initValues?: ITags[];
  addBtn?: (e: { color: string; title: string }) => void;
  onSelect?: (id: string) => void;
  onRemove?: (id: string) => void;
  onDelete?: (id: string) => void;
  onBlur?: (e: { id: string; color?: string; title?: string }) => void;
};

export const TagsFieldDropdown: React.FC<Props> = ({
  list = [],
  isBtn,
  sufixIcon,
  initValues,
  addBtnText,
  addBtn,
  onSelect,
  onRemove,
  onDelete,
  onBlur,
}) => {
  const [values, setValues] = useState<ITags[] | undefined>(initValues);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setValues(initValues);
  }, [initValues]);

  const handleClick = () => {
    setOpen(true);
    inputRef.current?.focus();
  };

  const handleSelect = (value: ITags) => {
    onSelect && !values?.filter(el => el._id === value._id).length && onSelect(value._id);
    // setValue(value);
    setOpen(false);
  };

  const handleUnselect = (value: ITags) => {
    onRemove && onRemove(value._id);
    // setValue(value);
  };

  const handleAdd = (e: { color: string; title: string }) => {
    addBtn && addBtn(e);
    setOpenAdd(false);
  };

  const handleDelete = (id: string) => {
    onDelete && onDelete(id);
    // setValue(prev => (prev?._id === id ? undefined : prev));
  };

  return (
    <Wrapper onClick={handleClick}>
      <SettingsDropdown
        open={open}
        width='100%'
        setOpen={() => setOpen(false)}
        label={
          values && values.length > 0 ? (
            values?.map(value => (
              <FieldBlock>
                {value && (
                  <Chip color={value.color}>
                    <div>{value.title}</div>
                    <div onClick={() => handleUnselect(value)}>
                      <BlackCloseIcon />
                    </div>
                  </Chip>
                )}
                {!value && <EmptyBlock>—</EmptyBlock>}
                {/* <Input type='text' value='' onChange={() => {}} ref={inputRef} /> */}
                {isBtn && <ShowButton>{sufixIcon}</ShowButton>}
              </FieldBlock>
            ))
          ) : (
            <FieldBlock>
              <EmptyBlock>—</EmptyBlock>
              {/* <Input type='text' value='' onChange={() => {}} ref={inputRef} /> */}
              {isBtn && <ShowButton>{sufixIcon}</ShowButton>}
            </FieldBlock>
          )
        }
      >
        <StatusesBlock>
          <Label>Выберите несколько</Label>
          {list.map(el => (
            <StatusElement
              id={el._id}
              key={el._id}
              title={el.title}
              color={el.color}
              selected={!!values?.find(value => value._id === el._id)}
              onSelect={() => handleSelect(el)}
              onDelete={() => handleDelete(el._id)}
              onBlur={onBlur}
            />
          ))}
          {addBtnText && (
            <SettingsDropdown
              open={openAdd}
              setOpen={() => setOpenAdd(false)}
              offset={[20, -200]}
              label={
                <AddButton onClick={() => setOpenAdd(true)}>
                  <PlusIcon /> Новый
                </AddButton>
              }
            >
              <StatusBlock defaultValue='' addBtn={handleAdd} />
            </SettingsDropdown>
          )}
        </StatusesBlock>
      </SettingsDropdown>
    </Wrapper>
  );
};
