import { createAsyncThunk } from '@reduxjs/toolkit';
import { IContact, setNeedUpdateContact } from 'processes/store';
import { addContactApi } from 'shared/api';

type PayloadType = {
  wsId: string;
  params: Partial<IContact>;
  successCallback?: (id?: string) => void;
  errorCallback?: (err: {
    name: string;
    message: string;
    response: { data: { error: { misc: { [key: string]: string } } } };
  }) => void;
};
export const addManuallyContactAction = createAsyncThunk<object, PayloadType>(
  'add/contact',
  async (payload, { dispatch }) => {
    const { wsId, params, successCallback, errorCallback } = payload;
    try {
      const response = await addContactApi({ wsId, params });
      if (response.status === 200) {
        successCallback && successCallback(response.data.result._id);
        dispatch(setNeedUpdateContact(true));
      }
    } catch (err: any) {
      errorCallback && errorCallback(err);
    }
  },
);
