import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSocialAccountApi } from 'shared/api';

type PayloadType = {
  email: string;
  successCallback?: () => void;
  errorCallback?: (msg: { name: string; message: string }) => void;
};

export const createSocialAccountAction = createAsyncThunk<object, PayloadType>('social/create', async payload => {
  const { email, successCallback } = payload;
  try {
    const response = await createSocialAccountApi({ email });
    if (response.status === 200) {
      successCallback && successCallback();
    }
  } catch (err) {
    console.log('error', err);
  }
});
