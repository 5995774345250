import React, { ReactNode } from 'react';
import { Content, Header, ModalWrapper } from './styled';
import { CloseIcon } from 'shared/lib/Icons';
import { IconButton } from 'shared/ui';

type Props = {
  onCloseModal: () => void;
  children?: ReactNode;
};

export const PreviewEmailModal: React.FC<Props> = ({ onCloseModal, children }) => {
  return (
    <ModalWrapper>
      <Header>
        <div>Превью</div>
        <IconButton icon={<CloseIcon />} onClick={onCloseModal} />
      </Header>
      <Content>{children}</Content>
    </ModalWrapper>
  );
};
