import { AxiosPromise } from 'axios';
import { apiInstance, IError } from 'shared/api';

type Response = {
  result: null;
  errors: IError[];
};

export const logoutApi = (): AxiosPromise<Response> => {
  return apiInstance.get('user/logout');
};
