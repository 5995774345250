import React, { useEffect, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
registerLocale('ru', ru);

type Props = {
  placeholder?: string;
  initDate?: Date | null;
  dateFormat?: string;
  onSelect?: (e: Date) => void;
  onChange?: (e: Date | null) => void;
};

export const DatePicker: React.FC<Props> = ({ placeholder, initDate, onSelect, onChange, dateFormat }) => {
  const [date, setDate] = useState<Date | null | undefined>(initDate);

  useEffect(() => {
    initDate && setDate(initDate);
  }, [initDate]);

  const handleChange = (e: Date | null) => {
    setDate(e);
    onChange && onChange(e);
  };

  const handleSelect = (e: Date) => {
    onSelect && onSelect(e);
  };
  return (
    <ReactDatePicker
      locale='ru'
      placeholderText={placeholder}
      selected={date}
      onSelect={handleSelect}
      onChange={handleChange}
      dateFormat={dateFormat}
    />
  );
};
