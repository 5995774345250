import styled from 'styled-components';

export const Tabs = styled.div`
  width: max-content;
  height: max-content;
  padding: 4px;
  background: rgba(0, 42, 125, 0.08);
  display: flex;
  border-radius: 10px;
`;

export const Tab = styled.div<{ active?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 20px 8px;
  background: ${({ active }) => (active ? 'white' : 'none')};
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
`;
